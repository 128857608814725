//
//  (C) Copyright 2024 by Homewood Health Inc.
//
//  $File: _published-content.scss
//  $Purpose: Imports all the necessary .scss files for the published content pages
//

.container-page-dynamic {
  margin-top: 0;
  margin-bottom: 0;

  // Photo snippet width
  .width-50 {
    @media(min-width: 575px) {
      width: 50%;
    }
  }

  .width-80 {
    @media(min-width: 575px) {
      width: 80%;
    }
  }

  .section-row,
  .row {
    h1 {
      margin: $space-xs 0;
    }

    h2, h3 {
      margin: $space-sm 0;
    }

    p {
      margin: $space 0;
    }

    a {
      margin-top: $space-xs;
    }

    img,
    picture {
      max-width: 100%;
      margin: calc(1.25 * $space) 0 $space;
    }

    ul,
    ol {
      li:not(:last-child) {
        margin-bottom: $space-xs;
      }

      margin-bottom: 0;
    }

    .img-circular {
      display: inline-block;
      width: 200px;
      height: 200px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
    }

    .img-circular img {
      display: inline;
      margin: 0 auto;
      height: 100%;
      width: auto;
      max-width: none;
      min-width: 100%;
      min-height: 100%;
    }

    .quote {
      margin: 1.5em 0;

      i {
        font-size: $font-size-xl;

        @media (max-width: 768px) {
          left: calc($space / 2);
        }
      }

      small {
        margin-left: calc($space * 2.7);
        opacity: 0.7;
        font-size: $space;
      }

      p {
        margin-left: calc($space * 2.7);
        font-size: calc(1.5 * $space);
      }
    }

    .is-card-circle {
      width: 70px;
      height: 70px;
      padding: calc($space-xs * 3);
    }

    .icon-process {
      padding-top: 12%;
    }
  }

  .row {
    .col-md-12:has(.section-wrapper, .banner-hero) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  & > .row {
    margin-left: auto;
    margin-right: auto;
  }

  //======================================================================//
  //=================== <<<<< Icon Overrides >>>>> =======================//
  //======================================================================//

  .bi {
    @extend .fa;
    font-family: "Font Awesome 5 Brands";
  }

  .bi-twitter-x, .bi-facebook, .bi-instagram {
    &::before {
      font-family: inherit;
    }
  }

  .bi-twitter-x {
    &::before {
      content: "\f099";
    }
  }

  .bi-facebook {
    &::before {
      content: "\f39e";
    }
  }

  .bi-instagram {
    &::before {
      content: "\f16d";
    }
  }

  .ion-quote {
    &::before {
      content: "\f10d";
    }
  }

  //======================================================================//
  //===================== <<<<< Social media icons >>>>> =================//
  //======================================================================//

  .is-social {
    a > i {
      text-decoration: none;
      color: $black;
      font-size: $font-size-lg;
      margin: 0 calc($space-xs * 3);
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
    }

    a {
      &:first-child > i {
        margin: 0 calc($space-xs * 3) 0 0;
      }

      &:last-child > i {
        margin: 0 0 0 calc($space-xs * 3);
      }
    }
  }

  .is-social a:hover > i {
    color: $mulberry;
  }

  //======================================================================//
  //====================== <<<<< Embedded Video >>>>> ====================//
  //======================================================================//

  .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    margin-top: 1.4em;
    margin-bottom: 1em;
  }

  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%;
  }

  .embed-responsive iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  //======================================================================//
  //==================== <<<<< Section Wrapper >>>>> =====================//
  //======================================================================//

  .section-wrapper {
    margin: $space-lg auto;

    @media (min-width: 768px) {
      margin: $space-xl auto;
    }
  }

  .section-wrapper-lg {
    margin: $space-xxl auto;
  }

  .section-wrapper-xl {
    margin: $space-lg 0;

    @media (min-width: 768px) {
      margin: $space-xxxl auto;
    }
  }

  .section-content-yellow,
  .section-content-gray,
  .section-content-bg-image {
    padding-left: $space-xs*2;
    padding-right: $space-xs*2;
  }

  .section-text-content,
  .section-content-map {
    padding-top: $space-xl;
    padding-bottom: $space-xl;

    @media (max-width: 768px) {
      padding-top: $space;
      padding-bottom: $space;
    }
  }

  .section-rounded {
    overflow: hidden;
    border-radius: $space;
    padding: $space;
  }

  .section-bg-rounded {
    overflow: hidden;
    border-radius: $space;
    padding: $space calc($space * 1.3);

    @media (min-width: 575px) {
      padding: $space-xl;
    }
  }

  .section-audio-player {
    .flex-column {
      margin: $space 0;
    }
  }

  .section-sliver-image {
    display: flex;
    flex-direction: column;
  }

  .section-gradient-background {
    position: relative;

    &::before {
      background-image: url('#{$url-cdn-images}/backgrounds/background-dashboard-abstract-mulberry.png');
      transform: scaleX(-1) scaleY(-1);
      -moz-transform: scaleX(-1) scaleY(-1);
      -webkit-transform: scaleX(-1) scaleY(-1);
      -ms-transform: scaleX(-1) scaleY(-1);
      background-position: 1192px -451px;
      background-repeat: no-repeat;
      opacity: 0.3;
      background-size: 50%;
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      @media (max-width: 576px) {
        background-position: 0 0;
        background-size: cover;
      }
    }
  }

  .section-gradient-homeweb {
    &::before {
      background-position: 968px -50px;
      @media (min-width: 576px) and (max-width: 992px) {
        background-position: -105px -50px;
        background-size: 150%;
      }

      @media (max-width: 576px) {
        background-position: 34px 1180px;
        background-size: 165%;
      }
    }
  }

  .section-bg-image,
  .section-content-bg-image-full {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .overlay {
      z-index: 2;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(313deg, rgba(83, 30, 82, 0.7) 0%, rgba(46, 6, 58, 0.7) 100%);
    }

    .content {
      color: $white;
      position: relative;
      z-index: 5;
    }

    .bg-image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .section-content-bg-image-full {
    //padding: $space-lg 0;
    .container {
      position: relative;
      z-index: 5;
    }
  }

  .section-content-bg-image-full,
  .section-content-gray-full,
  .section-content-blue-full {
    padding: $space-lg 0;
  }

  //Pre-footer

  .section-pre-footer {
    .title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .title,
    p {
      color: $white;
    }

    img {
      margin-bottom: 0;
    }

    .store-image {
      max-width: 200px;
      height: auto;
    }
  }

  // Profile cards

  .section-content-profiles {
    .card-profile {
      .card-label {
        font-size: $font-size-xs;
        letter-spacing: 2.8px;
        color: $grey-dark;
        text-transform: uppercase;
      }

      .card-label-secondary {
        font-size: $font-size-xs;
        letter-spacing: 2.8px;
        color: $grey-dark;
        text-transform: uppercase;
      }

      img {
        margin: 0;
      }
    }
  }

  .section-content-grey-contained {
    .column-text {
      padding: $space-xl $space;
      display: flex;
      align-items: center;

      .content-text {
        p {
          font-size: $font-size-xl;
          margin-top: 0;
        }
      }

      @media (min-width: 768px) {
        padding-left: $space-xl;
      }
    }

    .column-image {
      padding: 0;
      display: flex;

      img {
        object-fit: cover;
      }
    }
  }

  .section-accreditation {
    img:first-child {
      margin-right: $space-sm;
    }

    img:last-child {
      margin-left: $space-sm;
    }
  }

  .section-stat-large-tiles {
    .card-container {
      width: calc(100%);
      height: 100%;
      box-shadow: 0 3px 6px 0 $grey-light;
      padding-top: 0;
      background-color: $white;

      .card-inner {
        padding: calc($space * 1.33) calc($space * 1.9) calc($space * 1.7) calc($space * 2.3);
        width: 100%;
        box-sizing: border-box;

        @media (max-width: 768px) {
          padding: $space-md;
        }

        .card-title {
          font-size: calc($font-size-std * 2);
          font-family: $font-foco;
          @media (max-width: 768px) {
            font-size: calc($font-size-std * 1.6);
          }
        }

        .large-stat {
          font-size: calc($font-size-std * 3.83);
          line-height: calc($space * 4.3);
          font-family: $font-foco;

          @media (max-width: 768px) {
            font-size: $font-size-xxl;
            line-height: calc($space * 2.3);
          }
        }

        p {
          font-size: $font-size-std;
        }

        h3,
        span,
        p {
          color: $purple-light;
        }

        @media (max-width: 768px) {
          h3 {
            font-size: calc($font-size-std * 1.7);
          }
          span {
            font-size: $font-size-xxl;
          }
          h3,
          p,
          span {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    .homewood-health {
      font-size: calc($font-size-std * 2);
      line-height: calc($space * 2.22);
      font-family: $font-foco;
      max-width: 558px;
    }
  }

  .section-content-map {
    .logo-map {
      width: 375px;
    }

    .brochure-map {
      width: 613px;
    }

    .content-block:not(:last-child) {
      margin-bottom: $space-xl;
    }
  }

  .section-alumni-cards {
    .alumni-icon {
      width: 52px;
      margin-bottom: 0;
    }

    .column-cta {
      background-color: $mulberry-light;
      padding: $space-xl;

      @media (max-width: 992px) {
        padding: $space-md $space-xl $space-xl;
      }

      .column-icon {
        text-align: center;

        i {
          color: $white;
          font-size: $font-size-xxl;
          transform: scaleX(-1);
          -moz-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          -ms-transform: scaleX(-1);
        }
      }

      .column-text {
        color: $white;
        @media (max-width: 992px) {
          padding-top: $space;
        }
      }
    }
  }

  // Assessments

  .section-assessment {

    .assessment-header {
      margin-bottom: 8px;
      margin-top: 0;
    }

    .col-assessment-image {
      width: 100%;

      @media (min-width: 1200px) {
        flex: 1 1 auto;
        width: auto;
        margin-left: $space-xxl;
      }

      .helper-image {
        width: 100%;
        min-height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: $space-md;

        @media (min-width: 1200px) {
          margin-top: 0;
        }
      }
    }

    .col-assessment-content {
      width: 100%;

      @media (min-width: 1200px) {
        width: calc(100% * 7 / 12);
      }
    }
  }

  //======================================================================//
  //========================= <<<<< Hero >>>>> ===========================//
  //======================================================================//

  .banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    // Banner Hero and Carousel Content

    .banner-background,
    .banner-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .banner-background {
      z-index: 10;
    }

    .banner-overlay {
      background-color: transparentize($mulberry, 0.27);
      z-index: 12;
      pointer-events: none;
    }

    .banner-inner {
      color: $white;
      position: relative;
      z-index: 15;
    }

    &.banner-hero {

      min-height: 300px;
      padding: $space-xl 0;

      @media (min-width: 768px) {
        padding: 0;
      }

      h1 {
        font-size: $font-size-xxxl;

        @media (min-width: 768px) {
          font-size: calc($font-size-xl * 2);
        }
      }

      .sub-heading {
        display: block;
        margin-bottom: $space;
      }

      .btn {
        padding-left: $space-lg;
        padding-right: $space-lg;
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: 576px) {
          margin: 0 $space-sm;
          width: auto;
        }

        &.btn-icon-spaced {
          display: flex;
          align-items: center;
          i,
          .icon,
          img {
            margin-right: $space-xs;
            margin-left: $space-xs;
          }
        }
      }
    }

    &.banner-equitable,
    &.banner-alumni,
    &.banner-manulife-efap,
    &.banner-homeweb-hero,
    &.banner-homeweb-app,
    &.banner-enbridge,
    &.banner-suncor {
      padding-top: 166px;
      padding-bottom: 122px;
    }

    &.banner-map {
      padding-top: 122px;
      padding-bottom: 122px;
    }

    &.banner-equitable,
    &.banner-alumni,
    &.banner-map,
    &.banner-manulife-efap,
    &.banner-homeweb-hero,
    &.banner-homeweb-app,
    &.banner-enbridge,
    &.banner-suncor {

      .banner-background {
        background-position: top left 10%;
      }

      .column-text {
        background-color: transparentize($mulberry-darkest, 0.2);
        padding: calc($space * 2.8);
        display: flex;
        flex-direction: column;

        h1 {
          margin-top: 0;
          margin-bottom: 0;
        }

        p {
          margin-bottom: $space-sm;
          margin-top: 0;
        }

        p:first-of-type {
          margin-top: $space-sm;
        }
      }

      .btn {
        margin-left: 0;
        margin-top: $space-sm;
        width: fit-content;

        &:last-child {
          margin-top: $space;
        }
      }

      .btn-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: calc($space-sm * 1.5);
        gap: $space-sm;

        @media (max-width: 576px) {
          gap: $space-sm;
        }
        .btn {
          margin-top:0;
        }

        .btn-outline-yellow {
          border-color: $headings-color-dark;
          color: $headings-color-dark;
        }
      }

      @media (max-width: 768px) {
        display: block;
        padding-top: 0;
        padding-bottom: 0;

        .banner-background {
          width: 100%;
          height: 350px;
          background-position: center;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: $mulberry-dark;
            opacity: .3;
          }
        }

        .banner-inner {
          .container {
            h1 {
              font-size: calc($font-size-std * 2);
            }
            margin-left: auto;
            margin-right: auto;
            max-width: initial;
            width: 100%;

            .column-text {
              padding-bottom: $space-xxl;
              background-color: $mulberry-darkest;
            }

            .column-empty {
              display: none;
            }
          }
        }
      }
    }

    &.banner-enbridge {
      .banner-inner {
        .container {
          .column-text {
            background-color: $grey-darkest;
          }
        }
      }

      .banner-background {
        background-position: bottom right;
        &::before {
          background-color: $grey-darkest;;
        }
      }

      @media (min-width: 768px) {
        .banner-background {
          background-position: top left 45%;
        }
      }

      @media (min-width: 1400px) {
        .banner-background {
          background-position: top left;
        } 
      }
    }

    &.banner-suncor {
      .banner-inner {
        .container {
          .column-text {
            background-color: $sc-blue;

            .banner-logo {
              margin-top: $space-sm;
            }
          }
        }
      }

      .banner-background {
        background-position: top right 17%;
        &::before {
          background-color: transparent;
        }

        @media (min-width: 768px) {
          background-position: top center;
        }

        @media (min-width: 1400px) {
          background-position: top left 25%;
        }
      }
    }

    &.banner-alumni {
      @media (min-width: 768px) and (max-width: 992px),
      (min-width: 1200px) and (max-width: 1400px) {
        max-height: 850px;
      }

      @media (min-width: 992px) and (max-width: 1200px) {
        max-height: 865px;
        .column-text {
          .banner-logo,
          picture,
          img {
            margin-top: $space-xs;
            margin-bottom: $space-sm;
          }
        }
      }
    }

    &.banner-pathfinder {
      padding-top: $space;
      padding-bottom: $space;
      min-height: 80vh;
      overflow: hidden;

      .banner-background {
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 992px) {
          background-position-x: 71%;
        }
      }

      .banner-overlay {
        background-color: transparent;
        z-index: 12;
        opacity: 0.7;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

        @media (max-width: 768px) {
          background: linear-gradient(270deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 0.2) 53%);
          opacity: 0.85;
        }

        @media (min-width: 768px) and (max-width: 992px) {
          opacity: 1.7;
        }
      }

      .banner-inner {
        color: $black;

        .pathfinder-logo {
          width: 100%;
          max-width: 350px;
          margin-bottom: $space-md;
        }

        .row {
          .btn {
            margin-left: 0;

            @media screen and (max-width: 576px) {
              width: fit-content;
            }
          }
        }
      }

      .card-container {
        width: calc(100%);
        height: 100%;
        display: flex;
        box-shadow: 0 3px 6px 0 $grey-light;
        background-color: $white;

        .card-inner {
          padding: $space-lg;
          width: 100%;
          box-sizing: border-box;

          .card-icon {
            height: 50px;
            width: 50px;
            margin-top: $space-md;
          }

          .card-title {
            font-size: calc($font-size-std * 1.5);
          }
        }
      }

      &.section-gradient-homeweb {
        min-height: inherit;
      }
    }

    &.banner-pathfinder-tall {
      min-height: 1150px;
      @media (max-width: 768px) {
        min-height: 500px;
      }
    }

    &.banner-tall {
      height: fit-content;
      @media (max-width: 768px) {
        min-height: 500px;
      }
    }

    &.banner-2-columns {
      padding-top: calc($space * 3.4);
      padding-bottom: calc($space/1.8);

      .banner-background {
        background-position: 30% 75%;
        background-size: 135%;
        background-repeat: no-repeat;
      }

      .banner-overlay {
        background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, $white 36%);
      }

      .column-text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        a {
          text-decoration: none;
        }
      }

      .column-image {
        padding: 0;

        img {
          object-fit: cover;
          margin: 0;
          height: auto;
          max-width: 100%;

          @media (max-width: 576px) {
            padding-right: $space-md;
            padding-left: $space-md;
          }
        }
      }

      @media (max-width: 768px) {
        padding-top: $space-xxl;
        padding-bottom: $space-xxl;
      }
    }

    &.banner-pathfinder-gradient {
      padding-top: $space-xxxl;
      padding-bottom: $space-xxl;
      min-height: fit-content;

      .banner-overlay {
        background: none;
      }

      @media (min-width: 768px ){
        padding-bottom: 100px;
        padding-top: 120px;
      }

      .banner-inner {
        .row {
          @media (min-width: 768px ) {
            position: relative;
          }
        }

        .row-pathfinder-hero {
          max-width: 1483px;
        }

        .column-text {
          z-index: 1;
          .card-container {
            .card-inner {
              padding: $space-xl;
            }
          }
        }

        .comprehensive-en {
          @media (min-width: 1400px) {
            height: calc(100% - 10px);
          }
        }

        .column-image {
          @media (min-width: 768px ){
            width: 50%;
            min-height: 117%;
            position: absolute;
              top: 50%;
              right: 12px;
              transform: translateY(-50%);
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 0;
          }
          @media (min-width: 992px ) {
            right: 2%;
            width: 51%;
          }
        }

        .column-video {
          video {
            width: 100%;
          }
          @media (max-width: 768px) {
            margin-top: $space-xl;
          }
        }

        .mental-health,
        .employee-assistance {
          img {
            @media (max-width: 768px) {
              margin-top: 0;
            }
          }
        }

        .mental-health {
          @media (min-width: 768px ) {
            background-image: url('#{$url-cdn-common}/uploads/images/pathfinder-image-woman-breathing.jpg');
            background-position: bottom;
          }
        }

        .employee-assistance {
          @media (min-width: 768px ){
            background-image: url('#{$url-cdn-common}/uploads/images/pathfinder-image-group.jpg');
          }
        }

        .broker {
          @media (min-width: 768px ){
            background-image: url('#{$url-cdn-common}/uploads/images/pathfinder-image-woman-laptop-couch.jpeg');
            background-position: center;
          }
        }

        .library {
          @media (min-width: 768px ){
            background-image: url('#{$url-cdn-common}/uploads/images/pathfinder-image-office-woman-laptop.jpg');
          }
        }
      }
    }

    &.banner-pathfinder-packages,
    &.banner-pathfinder-video {
      .column-text {
        margin-top: auto;
        margin-bottom: auto;
        padding: $space-xxxl $space-xxl;
        @media (max-width: 992px) {
          padding-bottom: $space-xxl;
          padding-top: $space-xxl;
        }
        @media (min-width: 1200px) and (max-width: 1600px) {
          padding-left: calc($space * 5.3);
          padding-right: calc($space * 4);
        }
        @media (min-width: 1600px) {
          padding-right: calc($space * 6.6667);
          padding-left: calc(($space * 6.6667) + $space-xxxl);
          padding-top: calc($space * 6.6667);
          padding-bottom: calc(($space * 6.6667));
        }
      }
    }

    &.banner-pathfinder-packages {
      .banner-background {
        background-size: cover;
      }
      .banner-inner {
        .column-image {
          padding-right: $space-sm;
          padding-left: calc($space * 2);
          img {
            margin-top: 0;
            margin-bottom: 0;
          }
          @media (max-width: 768px) {
            padding: $space-xxxl $space-md $space-md;
          }
          @media (min-width: 768px) {
            margin-top: $space-sm;
            margin-bottom: $space-sm;
          }
          @media (min-width: 1600px) {
            padding-right: 5%;
          }
        }
      }
    }

    &.banner-pathfinder-video {
      .banner-background {
        background-size: cover;
      }

      .banner-inner {
        width: 100%;

        ul {
          list-style: none;
          padding-left: 0;
        }

        .column-text {
          padding-top: $space-xxxl;
          padding-bottom: $space-md;

          @media (min-width: 1600px) {
            padding-right: calc($space * 6.6667);
            padding-left: calc(($space * 6.6667) + $space-xxxl);
            padding-top: calc($space * 6.6667);
            padding-bottom: calc(($space * 6.6667));
          }
          @media (min-width: 1200px) and (max-width: 1600px) {
            padding-left: calc($space * 5.3);
            padding-right: calc($space * 4);
          }
          @media (max-width: 992px) {
            padding-bottom: $space-xxl;
            padding-top: $space-xxl;
          }
        }

        .column-video {
          padding-right: $space-sm;
          padding-left: 0;

          @media (min-width: 1600px) {
            padding-left: calc($space * 2);
            padding-right: calc(($space * 6.6667) + $space-xxxl);
            padding-top: calc($space * 6.6667);
            padding-bottom: calc($space * 6.6667);
          }
          @media (max-width: 992px) {
            padding-bottom: $space-xxl;
            padding-top: $space-xxl;
          }

          .embed-responsive {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    &.banner-pathfinder-services {
      .banner-background {
        background-position: bottom left;
      }
      .banner-overlay {
        background: none;
      }
      .banner-inner {
        padding-top: calc($space * 5);
        h2 {
          line-height: $space-xl;
        }
        .list-packages {
          list-style: none;
          padding-left: 0;
          padding-top: $space-md;

          li {
            padding-top: $space-sm;
            padding-bottom: $space-sm;
            line-height: $space-md;
            margin: 0;

            .col {
              align-self: center;
            }
          }
          .fa-check {
            padding-top: 2px;
            color: $yellow-dark;
            font-size: calc($font-size-std * 1.4);
          }
        }
        .column-image {
          flex-direction: column;
          display: flex;
          align-items: center;

          @media (max-width: 768px) {
            padding-top: $space-xl;
          }

          .pathfinder-logo {
            max-width: 395px;
            margin-top: 0;
          }
          .sentio-devices {
            max-width: 350px;
          }
        }
        .column-benefits {
          padding-top: 70px;
          color: black;

          ul {
            list-style: none;
            font-size: $font-size-md;
            padding-left: 0;

            li {
              padding: $space-xs;
            }

            .fa-check {
              padding-top: $space-xs;
              padding-right: $space;
              color: $yellow-dark;
              font-size: calc($font-size-std * 1.3);
            }
          }
        }
      }
    }

    &.banner-pathfinder-resources {
      .card-container {
        display: flex;
        flex-direction: column;
        transition: all 0.25s;

        .card-media {
          position: relative;
          height: 250px;
          margin-top: 0;
          transition: all .3s ease-out;

          &::after {
            display: block;
            content: "";
            background-image: linear-gradient(0deg, rgba(0, 0, 0, .35), transparent 75%);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            opacity: 1;
          }

          .card-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            margin: 0;
          }
        }
        .card-inner {
          display: flex;
          flex-direction: column;
          height: calc(100%  - 250px);

          .card-title {
            margin: 0;
            color: $purple;
            font-size: $font-size-lg;
          }

          .card-summary {
            flex: 1;
          }

          a {
            text-decoration: none;
          }
        }

        &:hover {
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
          .card-media::after {
            opacity: 0.5;
          }
        }
      }
    }

    &.banner-pathfinder-2-col {
      .banner-background {
        background-size: cover;
      }
      .banner-inner {
        .column-text {
          h3 {
            margin-bottom: $space;
          }
          padding: 5%;
          @media (max-width: 768px) {
            padding: 10%;
          }
        }
        .column-image {
          padding: $space-sm;
          img {
            margin: 0;
          }
        }
      }
    }

    &.banner-pathfinder-library {
      padding-top: calc($space * 5.5556);
      padding-bottom: calc($space * 5.5556);
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: 1500px) {
        padding-left: calc($space * 5.5556);
        padding-right: calc($space * 5.5556);
      }
      @media (max-width: 768px ) {
        padding-top: $space-lg;
        padding-bottom: 0;
      }
      .column-resources {
        @media (max-width: 768px ) {
          padding-right: $space-lg;
          padding-left: $space-lg;
        }
        ul {
          list-style: none;
          padding-left: 0;
        }
      }
      .column-image {
        @media (max-width: 768px) {
          padding: $space-lg 0 0;
        }
      }

      @media (min-width: 768px) and (max-width: 992px) {
        .column-resources {
          padding-left: $space;
        }
        .column-image {
          padding-right: $space;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      @media (min-width: 992px) and (max-width: 1500px) {
        .column-resources {
          padding-left: $space-xl;
        }
        .column-image {
          padding-right: $space;
        }
      }
    }

    &.banner-purple {
      padding-top: calc($space-xs * 12);
      padding-bottom: calc($space-xs * 15.5);

      h2 {
        font-size: $font-size-xxl;

        @media (max-width: 768px) {
          font-size: calc($font-size-sm * 2);
        }
      }
    }

    &.banner-homeweb {
      padding-top: $space-xl;
      padding-bottom: $space-xl;

      .banner-overlay {
        background-color: $mulberry-white;
      }
      .column-text {
        display: flex;
        align-items: center;
        color: $black;

        @media (max-width: 768px) {
          padding-right: $space;
          padding-left: $space;
        }
      }

      .homeweb-logo {
        max-width: 360px;

        @media (max-width: 1200px) {
          max-width: 300px;
        }
      }

      picture,
      img {
        @media (max-width: 768px) {
          margin-top: 0;
          margin-bottom: $space-xs;
        }
      }
    }

    &.banner-140 {
      picture,
      img {
        margin-top: 0;
        margin-bottom: 0;
      }

      .banner-inner {
        max-width: 1150px;

        &.banner-content-narrow {
          .container {
            @media screen and (min-width: 768px) {
              max-width: 940px;
            }
          }
        }
      }

      .col-logos {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .column-text {
        display: flex;
        flex-direction: column;
        align-items: start;

        h3 {
          font-weight: 600;
        }

        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      @media screen and (min-width: 992px) {
        .column-text {
          align-items: start;
        }
      }
    }

    &.banner-cards {
      .banner-background {
        background-color: $white-off;
      }
      padding-bottom: $space;
      .card-container {
        .card-inner {
          img {
            margin-top: $space-xs;
            margin-bottom: $space-xs;
          }
          p {
            margin-top: $space-xs;
          }
        }
      }
    }

    &.banner-grey {
      padding-top: calc($space * 6.1111);
      padding-bottom: calc($space * 6.1111);
      height: fit-content;
    }

    &.banner-text-card-overlap {
      overflow: hidden;

      .banner-overlay {
        background-color: transparent;
        background-image: linear-gradient(180deg, #FDF3E500 0%, #FDF3E557 100%);
      }

      .banner-inner {
        z-index: 16;
      }

      .row {
        @media (min-width: 1200px) {
          position: relative;
        }

        .column-image {
          img {
            @media (min-width: 1200px) {
              position: absolute;
              top: -18px;
              left: -375px;
              z-index: -1;
            }
          }
        }

        .card-container {
          box-shadow: 0 3px 6px 0 $grey-light;
          margin-top: calc($space-xs * 14);
          margin-bottom: calc($space-xs * 14);
          color: $black;
          background-color: $white;

          @media (max-width: 992px) {
            margin-top: 0;
          }
        }

        .column-stat {
          font-size: $font-size-xxxl;

          @media (max-width: 768px) {
            font-size: $font-size-xl;
          }
        }
      }

      @media (max-width: 992px) {
        img {
          display: block;
        }

        .banner-background {
          background-image: none;
        }
      }
    }

    &.banner-homeweb-hero {

      .banner-background {
        background-position: right;
      }

      @media (min-width: 768px) {
        .banner-background {
          background-position: top right 45%;
        }
      }

      @media (min-width: 1400px) {
        .banner-background {
          background-position: top left;
        }
      }
    }

    &.banner-homeweb-app {
      .banner-background {
        background-position: center;
      }

      @media (min-width: 768px) {
        .banner-background {
          background-position: top right 45%;
        }
      }

      @media (min-width: 1400px) {
        .banner-background {
          background-position: top left;
        }
      }
    }

  }

  .banner-manulife-green {
    background-color: #01a758;
  }

  .container-homeweb-content {
    .row-title {
      display: flex;
      justify-content: center;
      margin-top: $space-lg;

      @media screen and (min-width: 768px) {
        margin-top: calc($space * 3.2);
        margin-bottom: $space-md;
      }
      .col-title {
        max-width: 1000px;
      }
      p{
        margin-top: calc($space * 0.75);
      }
    }

    .row-cards {
      .card-container {
        width: calc(100%);
        height: 100%;
        display: flex;
        box-shadow: 0 3px 6px 0 $grey-light;
        background-color: $white;

        .card-inner {
          display: flex;
          flex-direction: row-reverse;
          padding: $space-md;
          width: 100%;
          box-sizing: border-box;

          img {
            margin-top: 0;
            width: 52px;
            height: 52px;
            margin-bottom: $space-xs;
          }

          .content {
            margin-right: $space-sm;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;

            .card-title {
              font-size: calc($font-size-std * 1.33333333333);
              color: $grey-darkest;
              font-family: $font-foco;
              margin-top: $space-xs;
              margin-bottom: 0;
            }
            p, a {
              font-size: $font-size-sm;
            }
            i {
              font-size: calc($font-size-xs * 0.8);
              margin-left: $space-xs;
            }
            p {
              flex: 1;
              line-height: 1.3;
              margin-top: $space-xs;
              margin-bottom: $space-xs;
            }
            a {
              margin-top: 0;
              text-decoration: none;
            }
          }

          @media screen and (min-width: 768px) {
            flex-direction: column;
            padding: $space-lg;

            .content {
              margin-right: 0;
              .card-title {
                font-size: calc($font-size-std * 1.5555);
                margin-bottom: $space-xs;
              }

              p, a {
                font-size: $font-size-std;
              }
              i {
                font-size: $font-size-sm;
              }
              p {
                margin-bottom: $space;
              }
            }
          }

        }
      }

    }
  }

  // Homeweb Welcome page
  .banner-homeweb-gradient {
    height: 300px;

    @media (max-width: 768px) {
      height: 200px;
    }

    .banner-background {
      background-image: url('#{$url-cdn-common}/uploads/images/pathfinder-background-hero-purple.png');
      background-position: center bottom;
      height: 325px;

      @media (max-width: 768px) {
        height: 200px;
      }
    }
  }

  .banner-homeweb-cards {
    .card-icon {
      margin-bottom: $space-xs;
    }
  }

  .section-about-homeweb {
    ul {
      list-style: none;

      .list-image {
        margin: $space-sm 0;
      }
    }
  }

  .section-card-simple {
    .column-card {
      padding: calc($space/2);

      .card-simple {
        height: 100%;
        border: 1px solid $grey-light;
        padding: $space;

        @media (min-width: 992px ){
          padding: $space-xl;
        }
      }
    }

    .row-button {
      margin-top: $space;
      margin-bottom: $space;
      @media (min-width: 1200px) {
        margin-top: $space-lg;
        margin-bottom: $space-lg;
      }
    }
  }

  .section-pathfinder-benefits {
    .container {
      @media (min-width: 576px) and (max-width: 768px) {
        max-width: 100%;
      }
    }
    .column-employees,
    .column-business {
      background-size: cover;
      background-repeat: no-repeat;

      ul {
        padding-left: $space;
      }
    }

    .column-employees {
      background-image: url('#{$url-cdn-common}/uploads/images/pathfinder-background-gradient-tan.jpg');
      padding: $space-xxxl $space-xxl;
      color: $black;
      @media (min-width: 1600px) {
        padding-right: calc($space * 6.6667);
        padding-left: calc(($space * 6.6667) + $space-xxxl);
        padding-top: calc($space * 6.6667);
        padding-bottom: calc(($space * 6.6667));
      }
      @media (min-width: 1200px) and (max-width: 1600px) {
        padding-left: calc($space * 5.3);
        padding-right: calc($space * 4);
      }
      @media (max-width: 992px) {
        padding-bottom: $space-xxl;
        padding-top: $space-xxl;
      }
    }
    .column-business {
      background-image: url('#{$url-cdn-common}/uploads/images/pathfinder-background-gradient-purple.jpeg');
      color: $yellow-light;
      padding: $space-xxxl $space-xxl;

      @media (min-width: 1600px) {
        padding-left: calc($space * 6.6667);
        padding-right: calc(($space * 6.6667) + $space-xxxl);
        padding-top: calc($space * 6.6667);
        padding-bottom: calc($space * 6.6667);
      }
      @media (min-width: 1200px) and (max-width: 1600px) {
        padding-left: calc($space * 4);
        padding-right: calc($space * 5.3);
      }
      @media (max-width: 992px) {
        padding-bottom: $space-xxl;
        padding-top: $space-xxl;
      }
    }
  }

  .section-service-table {
    .table {
      border: 1px solid $black;
      td,
      th {
        border: 1px solid $black;
      }
      .fa-circle {
        font-size: $font-size-xs;
      }
      .row-head {
        font-weight: normal;
        font-size: $font-size-md;
      }

      .column-mulberry-header,
      .column-pro-header,
      .column-plus-header,
      .column-comprehensive-header,
      .column-mulberry,
      .column-pro,
      .column-plus,
      .column-comprehensive {
        font-weight: bold;
      }

      .column-mulberry,
      .column-mulberry-header {
        background: $mulberry;
        color: $white;
        @media (max-width: 768px) {
          align-content: start;
          padding-top: $space-sm;
          padding-bottom: $space-sm;
        }
      }

      .column-pro-header,
      .column-plus-header,
      .column-comprehensive-header {
        @media (max-width: 768px) {
          writing-mode: vertical-rl;
          text-orientation: mixed;
          padding-top: $space-lg;
          padding-bottom: $space-lg;
        }
        @media (min-width: 768px) {
          text-align: center;
        }
      }
      .column-pro,
      .column-plus {
        @media (min-width: 992px) {
          padding-left: $space;
          padding-right: $space;
        }
      }
      .column-pro,
      .column-pro-header {
        background: #026E9B;
        color: $white;
      }
      .column-plus,
      .column-plus-header {
        color: $white;
        background: #b31166;
      }
      .column-comprehensive,
      .column-comprehensive-header {
        background: #40367A;
        color: $white;
      }
      .text-pro {
        color: #026E9B;
        background: #DFE7ED;
      }
      .text-plus {
        color: #b31166;
        background-color: #F3E3E7;
      }
      .text-comprehensive {
        color:  #40367A;
        background-color: #E1DFED;
      }
    }
  }

  .section-pathfinder-featured {
    .column-detail {
      padding: $space-xl;

      .card-pre-title {
        font-size: $font-size-md;
        margin: 0
      }
    }

    .column-image {
      background-size: cover;
      background-position: center;
    }
  }

  .section-pathfinder-description {
    ul {
      list-style: none;
      padding-left: 0;
    }
  }

  .section-pathfinder-ready {
    .container {
      @media (max-width: 768px) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;

        .row {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .column-text {
      @media (max-width: 768px) {
          padding: $space-xxl;
      }
      @media (min-width: 1200px) {
        padding: $space-xxxl;
      }
      .btn {
        margin-top: $space;
      }
    }
    .column-image {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .section-pathfinder-demo {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    padding-top: calc($space-xxxl + $space-xs);
    padding-bottom: 185px;
    color: $white;

    .column-content {
      display: flex;
      flex-direction: column;
      .post-title {
        line-height: $space-lg;
      }

      hr {
        color: $purple-light;
        opacity: 1;
        margin-top: $space-xl;
        margin-bottom: $space-xl;
      }

      ul {
        list-style: none;
        padding-left: $space-xs;

        li {
          padding: $space-xs;
        }

        .fa-check {
          padding-top: $space-xs;
          color: $yellow;
          font-size: calc($font-size-std * 1.3);
        }
      }

      img {
        align-self: center;
      }
    }

    .column-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) and (max-width: 1400px) {
        padding-left: $space-md;
      }
      @media (min-width: 1400px) {
        padding-left: $space-xl;
      }

      .logo-pathfinder {
        width: 100%;
        max-width: 450px;
        margin-top: 0;
      }

      .form-demo {
        margin-top: $space;
        padding: $space-lg;
        color: $black;
        background: white;
        border-top: 4px solid $yellow;
        box-shadow: $shadow-dropdown;

        legend {
          font-size: $font-size-md;
        }

        .container-sign-up {
          border: 1px solid #D2D2D2;
          border-radius: $border-radius;
          padding: $space $space-sm;
        }
      }
    }
  }

  .section-pathfinder-packages-background {

    ul {
      padding-left: $space;
    }
    .column-pro,
    .column-plus,
    .column-comprehensive {
      padding: $space-xxxl $space-xxxl calc($space * 4.7);
      @media (min-width: 768px) and (max-width: 992px) {
        padding: $space-lg $space;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        padding: $space-xxl $space-xl $space-xxxl;
      }
      @media (min-width: 1200px) and (max-width: 1500px) {
        padding: $space-xxxl $space-xxl;
      }
    }

    .column-pro {
      background-color: #F4EFF3;
    }

    .column-plus {
      background: #E4D6E1;;
      color: $mulberry;
    }

    .column-comprehensive {
      background: $mulberry;
      color: $white;
      p {
        line-height: $space-lg;
      }
    }
  }

  .section-pathfinder-article-header {
    margin-top: calc($space * 4.7);
    @media (max-width: 768px) {
      margin-top: $space-xxxl;
    }

    .column-content {
      padding-left: $space-xxl;

      @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }

      @media (min-width: 768px ) and (max-width: 1000px) {
        padding-left: $space;
        padding-right: 0;
      }

      @media (min-width: 1600px) {
        padding-left: calc(($space * 6.6667) + $space-xxxl);
      }

      .article-header {
        color: $mulberry;
        @media (min-width: 768px) {
          margin-top: $space;
        }
      }

      .article-post-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .datetime {
          color: $purple;
          font-size: $font-size-xs;
        }

        .social-media {
          a {
            text-decoration: none;
            &:not(:last-child) {
              margin-right: $space-xs;
            }
          }
          i {
            padding: calc($space-xs + 1px) $space-xs $space-xs;
            border-radius: 50%;
            background: $grey;
            color: $white;
            font-size: $font-size-xs;
          }
        }
      }
    }

    .column-image {
      padding-right: $space-xxl;
      padding-left: $space-xxl;

      @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }
      @media (min-width: 768px ) and (max-width: 1000px) {
        padding-right: $space;
      }

      @media (min-width: 768px) and (max-width: 992px) {
        padding-left: $space-md;
      }

      @media (min-width: 1600px) {
        padding-left: 5%;
        padding-right: calc(($space * 6.6667) + $space-xxxl);
      }

      .article-image {
        margin: 0;
      }
    }
  }

  .section-homeweb-apps {
    &::before {
      background-position: 136% 17%;
      background-size: 61%;
      @media (max-width: 768px) {
        background-position: 28% -12%;
        background-size: 300%;
      }
      @media (min-width: 768px) and (max-width: 1200px) {
        background-position: 4% 17%;
        background-size: 161%;
      }
    }
    .row-badges {
      [class^="col-"] {
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          @media (min-width: 768px) {
            justify-content: flex-end;
          }
        }
        &:last-child {
          @media (min-width: 768px) {
            justify-content: flex-start;
          }
        }
      }
      .app-store-en,
      .app-store-fr,
      .google-play {
        width: 100%;
        @media (max-width: 768px) {
          max-width: 250px;
          margin: 0;
        }
        @media (min-width: 768px) {
          max-width: 447px;
        }
      }
      .app-store-fr {
        @media (min-width: 768px) and (max-width: 992px) {
          max-height: 102px;
        }
        @media (min-width: 992px) {
          max-height: 107px;
        }
      }
    }
  }

  // Region Modal for Enbridge
  .modal-enbridge-region {
    max-width: 660px;

    .modal-header {
      min-height: 70px;

      .btn-close {
        width: 25px;
        height: 25px;
      }
    }

    .modal-title {
      font-size: calc($font-size-std * 1.7);
      font-weight: 400;
    }

    .modal-body {
      padding: $space-lg $space-md $space-xl $space-md;

      p {
        margin-top: 0;
        font-size: $font-size-md;
      }

      .btn-block {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          width: 120px;
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 75px;
            margin-top: 0;
            margin-bottom: $space-xs;
          }

          span {
            font-weight: 600;
            font-size: $font-size-sm;
          }
        }

        .middle {
          padding-left: $space-xs;
          padding-right: $space-xs;
          font-size: $font-size-md;
        }
      }

      @media screen and (min-width: 576px) {
        padding: $space-xl $space-xxxl $space-xxxl $space-xxxl;

        .btn-block {
          flex-direction: row;

          .middle {
            padding-left: $space;
            padding-right: $space;
            font-size: $font-size-lg;
          }

          .btn {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}
