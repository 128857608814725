//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _media-library.scss
// $Purpose: Styles relating to media library elements
//
//

.section-media-library {
  margin-top: $space-lg;

  .collection-assets {
    .item-content {
      display: flex;
      padding: $space;

      .item-thumb {
        width: 6em;
        height: 6em;

        &:hover {
          border-color: $primary;
        }

        .text-primary {
          font-size: $font-size-std * 1.9;

        }

        .label {
          display: block;
          text-align: center;
          font-size: $font-size-xs * 0.8;
          text-transform: uppercase;
        }

        .image-preview {
          min-height: 120%;
          min-width: 120%;
          object-fit: cover;
        }
      }

      .item-text {
        padding-left: $space;
        min-width: 100%;

        .stat-block {
          display: flex;

          .stat {
            .label {
              color: $grey-dark;
              font-size: $font-size-sm;
            }

            p {
              margin-bottom: 0;
            }

            @include media-breakpoint-down(lg) {
              margin-right: $space-xs;
            }

            &:first-child {
              margin-right: $space-md;
            }
          }
        }

        .item-permalink {
          width: 100%;
          font-size: $font-size-xxs;
          background-color: transparent;
          padding: 0;
          border-style: none;
          color: $grey-darkest;
        }

        @include media-breakpoint-down(lg) {
          padding-left: 0;
          padding-top: $space-xs * 3;
        }
      }

      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        padding: $space;
      }
    }

    .item-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .btn-icon-stack {
        .text {
          width: $space-xs * 15.5;
        }

        &:not(.btn-copy-link) {
          .text {
            width: $space-xxxl;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        justify-content: center;
      }
    }
  }

  .item-grid {
    .item-content {
      .stat {
        margin-right: $space;
        margin-top: $space-xs;
        font-size: $font-size-sm;
      }

      .item-permalink {
        padding-bottom: $space-xs;
      }
    }

    .item-actions {
      justify-content: center;
    }
  }
}

.form {
  .stat-wrapper {
    overflow: hidden;

    .stat {
      margin-right: $space-md;
      margin-top: $space-xs;
      font-size: $font-size-sm;

      .label {
        color: $grey-dark;
        font-size: $font-size-sm;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

