//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _style.scss
// $Purpose: Styles relating to page elements
//

//======================================================================//
//======================== <<<<< Manager >>>>> =========================//
//======================================================================//

.container-manager {
    margin: $space-xxl 0;

    &:has(.section-dsg-navbar-mobile),
    &:has(.section-progress-tracker.bottom) {
        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }
    }
}

.page-manager {
    &:has(.section-breadcrumbs){
        .container-homeweb-dashboard {
            margin-top: 0;
        }
    }
}

// MANAGER HEADER
.manager-header {
    align-items: center;

    h1 {
        margin-bottom: 0;
        font-family: $font-foco;
    }
}

.header-detail {
    border-bottom: $space-xs solid $grey-lightest;
    padding-bottom: $space;

    @include media-breakpoint-down (lg) {
        padding-bottom: $space * 2;
    }
}

.manager-actions {
    margin-top: $space;
    padding-bottom: $space;
    border-bottom: 2px solid $grey-lightest;

    @include media-breakpoint-up(sm) {
        border-bottom: $space-xs solid $grey-lightest;
    }
}

.manager-actions-form {

    .form-select {
        font-size: $font-size-sm;
        color: $grey-darkest;
    }

    .form-control:not(.multiselect),
    .form-select,
    .btn {
        height: $space*1.94444444;
    }

    .form-select {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .search-group {
        display: flex;
        margin-bottom: $space;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            padding-right: $space;
            border-right: 1px solid $grey-light;
        }

        .form-control {
            padding-left: $space;
            border-right: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .input-search {
        position: relative;
        width: 100%;
        border-right: none;

        input {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .btn-search {
        padding-left: calc($space-sm);
        padding-right: calc($space-sm);
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;

        .text {
            display: none;
        }

        .icon {
            margin-right: 0;
        }
    }

    .reset-search {
        color: $grey-medium;
        position: absolute;
        top: 52%;
        right: $space-sm;
        transform: translateY(-50%);

        &:hover {
            color: $link-hover-color;
        }
    }

    .col-filters {
        border: none;

        .dropdown {
            .btn {
                width: 100%;
            }
        }

        .dropdown-menu {
            min-width: 475px;
            padding: $space;
            box-shadow: 0px 0px 6px #00000029;
        }

        .placeholder-vue-component {
            width: 100%;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $space;
            background-color: $grey-dark;
            color: $white;
            border-radius: 9px;
            margin-bottom: $space;
            text-align: center;
        }

        .title {
            font-weight: 600;
        }

        p {
            font-size: $font-size-xxs;
        }

        @include media-breakpoint-up(md) {
            padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
            padding-right: $space;
            border-right: 1px solid $grey-light;
        }
    }

    .dropdown,
    .col-grid-view {
        .btn {
            font-size: $font-size-sm;
        }

        i {
            margin-right: $space-xs;
        }
    }
}


.manager-body-actions {
    margin: $space 0;

    .manager-add {
        padding: $space-sm;
        display: flex;
        align-items: center;
        background-color: $mulberry-white;

        .item-thumb {
            .label {
                color: $black;
            }

            &:hover {
                border: 1px solid $primary;
            }
        }

        .fa-plus {
            border-radius: 50%;
            border: 1px solid $primary;
            padding: $space-xs;
        }

        .content {
            margin-left: $space;

            p {
                margin-bottom: 0;
            }
        }

        .btn-link {
            padding: 0;
            display: inline-block;
            text-decoration: none;
            align-items: flex-start;
            line-height: 1rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

// MANAGER FOOTER
.manager-footer-pagination {
    margin-top: $space-md;

    .pagination {
        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }
}

//======================================================================//
//==================== <<<<< Manager Detail>>>>> =======================//
//======================================================================//


//======================================================================//
//======================= <<<<< Dashboard >>>>> ========================//
//======================================================================//

.container-dashboard {
    .controller-content {
        & > section {
            margin-bottom: $space-xxl;
        }
    }

    .section-quick-actions {
        .container-collection {
            margin-bottom: $space-lg;
            align-items: flex-start;
        }

        .row-group {
            display: flex;
        }

        .group-0 {
            .item-quick-link {
                flex: 1 1 150px;
                max-width: 45%;
                margin-bottom: $space;
            }
        }

        .group-1,
        .group-2 {
            flex: 0 0 50%;

            .item-quick-link {
                flex: 1 1 50%;
                margin-right: 0;
            }
        }

        @include media-breakpoint-up(lg){
            margin-bottom: 0;

            .row-group {
                flex-wrap: nowrap;
            }

            .group-0 {
                flex: 0 0 55%;
                padding-right: $space-xl;
            }

            .group-1,
            .group-2 {
                flex: 0 1 280px;
                padding: 0 $space-xl 0 $space-xl;
            }

            .group-0 {
                .item-quick-link {
                    flex: 1 1 150px;
                }
            }

            .group-0,
            .group-1,
            .group-2 {
                .item-quick-link {
                    flex: 1 0 130px;
                    max-width: 130px;
                }
            }
        }

        @include media-breakpoint-up(xl){
            .group-0 {
                flex: 0 0 50%;
                padding-right: $space-xxl;
            }

            .group-1,
            .group-2 {
                flex: 0 1 280px;
                padding: 0 $space-xl 0 $space-xxl;
            }

            .group-0 {
                .item-quick-link {
                    flex: 1 1 150px;
                }
            }

            .group-0,
            .group-1,
            .group-2 {
                .item-quick-link {
                    flex: 1 0 150px;
                    max-width: 150px;
                }
            }
        }

        @include media-breakpoint-up(xxl){
            .group-0 {
                flex: 0 0 45%;
            }
        }
    }

    .section-recent-updates{
        padding: $space-xxl 0;

        .container-header {
            display: flex;
                flex-direction: row;
                align-items: center;

            p {
                margin-bottom: 0;
            }
        }

        &.bg-grey-gradient {
            border: solid $border-light;
            border-width: 1px 0px;
            background: transparent linear-gradient(142deg, $white-off 0%, $white 100%) 0 0 no-repeat padding-box;
        }
    }
}

.container-collection-recent-updates {
    margin-bottom: $space-xxl;

    .container-header {
        display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            .icon {
                font-size: $font-size-sm;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

//======================================================================//
//====================== <<<<< Multiselect >>>>> =======================//
//======================================================================//
.multiselect {
    border-radius: $border-radius;
    margin-bottom: $space;

    .multiselect-search {
        padding-left: 0;
    }

    &.is-disabled {
        &:focus {
            box-shadow: none;
            border-color: $grey;
        }
    }

    .exercise-single-label,
    .exercise-option {
        display: flex;
        margin-right: auto;
    }

    .exercise-single-label {
        overflow: hidden;

        .title {
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    #multiselect-previous-exercise-multiselect-options {
        .is-selected,
        .is-pointed,
        .multiselect-option &:hover {
            color: $black;
            background-color: $white-off;
        }
    }

    &#multiselect-previous-exercise {
        .multiselect-caret {
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
            background-color: $white;
            mask-image: unset;
            -webkit-mask-image: unset;
            background-repeat: no-repeat;
            background-position: center;
            width: calc($space*0.6666666667);
            height: calc($space*0.6666666667);
            margin-right: $space-xs;
        }
    }
}

.multiselect[canClear="false"] {
    .multiselect-clear {
        display: none;
    }
}

.form-control.multiselect {
    padding-top: 0;
    padding-bottom: 0;
    min-height: $input-height;

    .multiselect-wrapper {
        min-height: initial;
    }

    .multiselect-placeholder,
    .multiselect-single-label,
    .multiselect-tags {
        padding-left: 0em;
    }
}

//======================================================================//
//==================== <<<<< Content Editor >>>>> ======================//
//======================================================================//

.container-editor-content {
    background: $white-off;
    padding: 0 $space $space;
    min-height: 100vh;
    margin: 0;

    .page-wrapper {
        box-shadow: 3px 3px 12px 3px transparentize($black, 0.85);
        background-color: $white;
        margin: $space-lg $space-sm;
        border: 1px solid $grey-light;

        @include media-breakpoint-up(lg) {
            margin: $space-lg $space-lg;
        }
    }

    .placeholder-nav {
        background: $white-off;
        color: $grey-medium;
        line-height: 1.2;
        text-align: center;
        padding: $space $space-sm;

        p {
            margin-bottom: 0;
        }

        .disclaimer {
            font-size: $font-size-xs;
            color: $grey-medium;
        }
    }

    .section-container-header {
        display: flex;
            justify-content: center;
            align-items: center;

        .nav {
            background: $white;
            box-shadow: 0px 0px 10px 0px transparentize($black, 0.9);
            margin: 0 auto;
        }

        .btn {
            .icon {
                margin-right: 0;
            }

            .text {
                margin-top: 0;
            }
        }
    }

    .alertPage {
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 0;
        position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        margin-bottom: 0;
    }

    div[data-html] {
        min-height: unset;
    }
}

//======================================================================//
//================== <<<<< Exercise Builder >>>>> ======================//
//======================================================================//

.container-editor-exercise {
    margin-top: $space;

    // Step Navigation
    .step-navigation {
        .wrapper {
            border-bottom: 1px solid $border-light;
            padding-bottom: $space-sm;
            margin-bottom: $space-xl;
        }
    }

    .nav-steps {
        flex: 1 1 auto;
        padding: $space-xs calc($grid-gutter-width/2);
        margin-right: $space-md;
        margin-bottom: $space-md;
        overflow-y: scroll;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
        }

        li {
            min-width: 160px;
            margin-right: $space-md;

            &:last-child {
                margin-right: 0;
            }
        }

        .step-link {
            display: flex;
                justify-content: start;
                align-items: center;
            text-decoration: none;
            font-size: $font-size-xs;
            line-height: 1.2;
            color: $grey-dark;

            &[href]:hover,
            &[href]:focus {
                color: $primary;
                outline: 0;

                .step-marker {
                    box-shadow: $input-btn-focus-box-shadow
                }

                .step-title {
                    text-decoration: underline;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        .step-marker {
            flex: 0 0 auto;
            display: flex;
                justify-content: center;
                align-items: center;
            border: 2px solid transparent;
            border-radius: 999px;
            background: $white-off;
            margin-right: $space-sm;
            width: 36px;
            height: 36px;

            .icon:not(.fa-lock) {
                display: none;
            }

            &.valid.validated {
                color: $green-dark;
                border-color: $green-dark;

                .icon {
                    display: block;
                    font-size: $font-size-sm;
                }

                .number {
                    display: none;
                }
            }

            &.invalid.validated {
                border-color: $danger;
            }
        }

        .active {
            .step-link {
                font-weight: 600;
                color: $black;
            }

            .step-marker {
                background: $yellow-light;
                border-color: transparent!important;
            }
        }

        .locked {
            .step-marker {
                border-color: $success;
                color: $primary;
            }
        }
    }

    .nav-step-actions {
        display: flex;
            justify-content: center;

        .btn {
            flex: 1 1 auto;
            margin-left: $space-sm;
        }
    }

    .step-navigation-footer {
        .wrapper {
            border-top: 1px solid $border-light;
            padding-top: $space-sm;
            margin-top: $space-xl;
        }
    }

    @include media-breakpoint-down(lg){
        .step-navigation {
            width: 100%;
            max-width: unset;
        }

        .nav-steps {
            /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
            -ms-overflow-style: none;  /* Internet Explorer and Edge */
            scrollbar-width: none;  /* Firefox */

            /* Hide the scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar { display: none; }
        }
    }

    @include media-breakpoint-up(md){
        .nav-step-actions {
            .btn {
                flex: 0 0 auto;
                display: inline-block;
            }
        }
    }

    @include media-breakpoint-up(xl){
        margin-top: $space-sm;

        .step-navigation {
            .wrapper {
                display: flex;
                    flex-wrap: nowrap;
                border-bottom: 1px solid $border-light;
                padding-bottom: 0 0 $space-sm;
                margin: 0 auto $space-xl;
            }
        }

        .nav-steps {
            flex: 1 1 auto;
            display: flex;
                align-items: center;
            overflow: initial;
            padding-left: 0;
            margin-bottom: 0;

            li {
                max-width: 160px;
            }
        }

        .nav-step-actions {
            display: flex;
                justify-content: end;

            .btn {
                display: inline-block;
            }
        }

        .step-navigation-footer {
            .nav-step-actions {
                justify-content: flex-end;
            }
        }
    }

    // Step Header
    .step-header {
        margin-bottom: $space-xl;

        &.underlined {
            border-bottom: 4px solid $border-light;
            padding-bottom: $space-md;
        }

        .tooltip-pill + .header {
            margin-top: $space;
        }
    }

    // Step Container 
    .container {
        &.step {
            label {
                font-weight: 300;
            }
        }

        // Step 1 Overrides
        &.step-1 {
            margin-top: $space-xl;

            .step-header {
                position: relative;

                .tooltip-pill {
                    position: absolute;
                        right: 0;
                }
            }
        }
    }

    .btn-ask-question {
        @include button-variant($white-off, $grey-lightest, $primary, $mulberry-white, $mulberry-dark, $mulberry-dark, $mulberry-dark );
        display: block;
        width: 100%;
        text-align: left;

        i {
            top: 1px;
        }
    }

    // Customize Exercise Block (Step 4)
    .container-rounded-grey,
    .container-customize-exercise {
        background: $white-off;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        padding: $space-lg;
        margin-top: $space-md;
        margin-bottom: $space-xxl;

        @include media-breakpoint-up(md){
            padding: $space-xl;
        }

        img {
            margin: $space-sm auto;
        }
    }
}