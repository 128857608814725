//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _forms.scss
// $Purpose: Styles relating to form elements
//

.form-group {
	margin-bottom: $space;
}

.form-group-lg {
	margin-bottom: $space-xl;
}

.form-label,
label {
	&.required {
		&:after {
			content: '*';
			color: $danger;
			display: inline-block;
			padding-left: $space-xs - 1;
			position: relative;
			top: -1px;
		}
	}
}

label {
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
	margin: $space-xs 0;
}

.form-check-label {
	margin: 0;
}

.form-check-input {
	&.attention {
		border-color: $grey-dark!important;
	}
}

.tip {
	font-size: $font-size-xs;
	color: $grey-dark;
}

.form-check-inline {
	&:hover {
		.form-check-input,
		.form-check-label {
			cursor: pointer;
		}
	}
}

// Container Search
.container-search {
	display: flex;

	form {
		display: flex;
		flex: 1 1 100%;
	}

	// Input Container
	.input-with-reset {
		flex: 1;
		position: relative;
		margin-right: -$input-border-width;

		// Input
		#input-search {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			min-height: 100%;

			&:focus {
				box-shadow: none;
			}
		}

		// Reset Icon
		.reset-input {
			cursor: pointer;
			color: $grey-dark;
			position: absolute;
			top: 0.63rem;
			right: 14px;
			text-decoration: none;

			&:hover {
				color: $red;
			}
		}
	}

	// Search Button
	.btn {
		background: $white;
		border-color: $gray-400;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding-left: $space-md;
		padding-right: $space-md;

		&:hover {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}

		&.disabled {
			background: $grey-light;
			border-color: $grey-light;
		}

		.text {
			display: none;
		}
	}

	@include media-breakpoint-up(md){
		.btn {
			.text {
				display: inline-block;
			}
		}
	}
}

// Manager File Upload
.file-upload-group {
	position: relative;

	.container-inputs {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin-bottom: $space-sm;

		.form-label {
			flex: 0 0 100%;
		}

		.input-group {
			position: absolute;
			top: 50%;
			opacity: 0;
			pointer-events: none;

			input {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.btn {
				@include button-outline-variant( $grey-dark, $mulberry-darkest, $white, $grey );
				text-transform: uppercase;
				border-radius: $border-radius-lg;
				border-color: $input-border-color;
				background-color: $white;

				&:focus {
					box-shadow: none;
				}
			}

			&.complete {
				top: auto;
				bottom: 0;
				opacity: 1;
				pointer-events: initial;
			}
		}
	}

	.container-progress {
		span {
			display: block;
			font-size: $font-size-sm;
			text-transform: capitalize;
		}
	}

	.container-message {
		font-size: $font-size-sm;
		&.success {
			color: $success;
		}
		&.error {
			color: $danger;
		}
	}
}

// Linkage Forms
.form-linkage {
	.table-container {
		max-height: 400px;
		overflow: auto;
	}

	.btn-action {
		width: 170px;
	}
}

//======================================================================//
//===================== <<<<< Third Party >>>>> ========================//
//======================================================================//

// Autocomplete
:root {
	--ms-spinner-color: #{$primary};

	--ms-border-color: #{$input-border-color};
	--ms-border-width: 1px;
	--ms-radius: #{$border-radius};
	--ms-dropdown-radius: #{$border-radius};

	--ms-ring-color: #{$input-btn-focus-color};
	--ms-ring-width: #{$input-btn-focus-width};

	--ms-tag-bg: #{$purple-dark};
	--ms-tag-bg-disabled: #{$grey-medium};
	--ms-tag-color: #{$white};
	--ms-tag-color-disabled: #{$white};

	--ms-placeholder-color: #{$input-placeholder-color};
	--ms-option-bg-selected: #{$purple-dark};
	--ms-option-bg-selected-pointed: #{$purple-dark};

	--ms-max-height: 8.5rem;
}

//======================================================================//
//==================== <<<<< WYSIWYG Editor >>>>> ======================//
//======================================================================//

.html-editor-container {
	.html-editor-input {
		min-height: 200px;
		font-size: $font-size-sm;
	}
}

.ql-snow {
	.ql-editor {
		.ql-size-small {
			font-size: $font-size-sm;
		}

		.ql-size-large {
			font-size: $font-size-md;
		}

		.ql-size-huge {
			font-size: $font-size-xl;
		}

		.ql-align-justify {
			text-align: justify;
		}

		.ql-align-center {
			text-align: center;
		}

		.ql-align-right {
			text-align: right;
		}

		h1 { font-size: 40px!important; }
		h2 { font-size: 32px!important; }
		h3 { font-size: 28px!important; }
		h4 { font-size: 24px!important; }
		h5 { font-size: 20px!important; }
		h6 { font-size: 16px!important; }

		p {
			margin-bottom: $space-sm;
		}
	}
}

.hide-editor-toolbar {
	.html-editor-input {
		border-top: 1px solid #ccc!important;
	}
	.ql-toolbar {
		display: none;
	}
}

.form-group-text-editor {
	.ql-toolbar {
		border-radius: $input-border-radius $input-border-radius 0 0;
	}

	.html-editor-input {
		border-radius: 0 0 $input-border-radius $input-border-radius;
	}
}


//======================================================================//
//====================== <<<<< Datepicker >>>>> ========================//
//======================================================================//

// Vue Datepicker
.dp__theme_light {
	--dp-background-color: #ffffff;
	--dp-text-color: #{$body-color};
	--dp-hover-color: #{transparentize($mulberry-dark, 0.75)};
	--dp-hover-text-color: #{$mulberry-dark};
	--dp-hover-icon-color: #{$mulberry-dark};
	--dp-primary-color: #{$primary};
	--dp-primary-text-color: #f8f5f5;
	--dp-secondary-color: #{$grey};
	--dp-border-color: #{$border-color};
	--dp-menu-border-color: #{$border-color};
	--dp-border-color-hover: #{$mulberry-dark};
	--dp-disabled-color: #f6f6f6;
	--dp-scroll-bar-background: #f3f3f3;
	--dp-scroll-bar-color: #959595;
	--dp-success-color: #{$success};
	--dp-success-color-disabled: #{transparentize($success, 0.8)};
	--dp-icon-color: #959595;
	--dp-danger-color: #{$danger};
	--dp-highlight-color: rgba(25, 118, 210, 0.1);
	--dp-font-size: $font-size-std;
	//--dp-menu-min-width: 320px;
	--dp-menu-padding: 18px;
}

body:not(.page-homeweb-booking-digest){
	.dp__theme-light {
		@include media-breakpoint-up(xl) {
			--dp-menu-min-width: 320px;
		}
	}
}

.dp__main {
	width: 100%;
	display: block;
}

.dp__menu {
	border-radius: $border-radius;
	//box-shadow: $shadow-element;
}

.dp__action_row {
	width: 100%!important;
}

.dp__input {
	border-radius: $border-radius;
}

.dp__input_focus{
	box-shadow: 0 0 0 0.25rem rgb(0 112 150 / 25%);
}

// .dp__action {
	// font-size: 0.9rem;
// }

.dp__cancel {
	color: $grey-dark;
}

.dp__select {
	@extend .btn-success;
	border: 1px solid;
	margin-left: $space-xs;
}

.dp__month_year_select {
	font-size: $font-size-lg;
}

.dp__calendar_header_item {
	font-size: $font-size-sm;
	font-weight: normal;
	text-transform: uppercase;
}

.dp__calendar_item {
	font-size: $font-size-sm;
}

.dp__cell_inner {
	border-radius: 50%;
}

.dp__cell_highlight {
	background-color: $white;
	border: 2px solid $mulberry-light;
}

.dp__today {
	color: $mulberry-dark;
	background-color: $mulberry-lightest;
	border-color: $mulberry-lightest;
}

.dp__active_date {
	border: 2px solid $mulberry-light;
}

// iOS specific refinement for the booking calendar widget
.page-mobile-safari {
	.dp__month_year_select {
		padding-left: $space-xs;
		padding-right: $space-xs;
	}

	.dp--arrow-btn-nav {
		padding-left: 0;
		padding-right: 0;
	}

	@include media-breakpoint-up(xl) {
		.dp--arrow-btn-nav,
		.dp__month_year_select {
			padding-left: unset;
			padding-right: unset;
		}
	}
}