//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _typeography.scss
// $Purpose: Styles related to text, type and helper classes that can be used when publishing text
//

/*
 * Font Families
 */
.font-family-source-sans { font-family: $font-source-sans!important }
.font-family-foco { font-family: $font-foco!important }
.font-family-standard { font-family: $font-standard!important }
.font-family-accent { font-family: $font-accent!important }
 
/*
 * Text Colours
 */
// Colours: Primary
.text-green-light           { color: $green-lightest!important }
.text-green                 { color: $green!important }
.text-green-dark            { color: $green-dark!important }
.text-green-darkest         { color: $green-darkest!important }
.text-yellow-lightest       { color: $yellow-lightest!important }
.text-yellow-light          { color: $yellow-light!important }
.text-yellow                { color: $yellow!important }
.text-yellow-dark           { color: $yellow-dark!important }
.text-red                   { color: $red!important }
.text-fuscia                { color: $fuscia!important }
.text-mulberry-white        { color: $mulberry-white!important }
.text-mulberry-lightest     { color: $mulberry-lightest!important }
.text-mulberry-light        { color: $mulberry-light!important }
.text-mulberry              { color: $mulberry!important }
.text-mulberry-dark         { color: $mulberry-dark!important }
.text-mulberry-darkest      { color: $mulberry-darkest!important }
.text-purple-light          { color: $purple-light!important }
.text-purple                { color: $purple!important }
.text-purple-dark           { color: $purple-dark!important }
.text-blue                  { color: $blue!important }

.text-error					{ color: $error!important }
.text-draft					{ color: darken($yellow-dark, 10%)!important }
.text-published				{ color: $green-dark!important }

// Colours: Neutrals
.text-black                 { color: $black!important }
.text-charcoal              { color: $charcoal!important }
.text-grey-darkest          { color: $grey-darkest!important }
.text-grey-dark             { color: $grey-dark!important }
.text-grey-medium           { color: $grey-medium!important }
.text-grey                  { color: $grey!important }
.text-grey-light            { color: $grey-light!important }
.text-grey-lightest         { color: $grey-lightest!important }
.text-grey-ultralight       { color: $grey-ultralight!important }
.text-white-off             { color: $white-off!important }
.text-white                 { color: $white!important }

/*
 * Background Colours
 */
// Colours: Primary
.bg-green-light             { background-color: $green-lightest!important }
.bg-green                   { background-color: $green!important }
.bg-green-dark              { background-color: $green-dark!important }
.bg-yellow-lightest         { background-color: $yellow-lightest!important }
.bg-yellow-light            { background-color: $yellow-light!important }
.bg-yellow                  { background-color: $yellow!important }
.bg-yellow-dark             { background-color: $yellow-dark!important }
.bg-red                     { background-color: $red!important }
.bg-fuscia                  { background-color: $fuscia!important }
.bg-mulberry-white          { background-color: $mulberry-white!important }
.bg-mulberry-lightest       { background-color: $mulberry-lightest!important }
.bg-mulberry-light          { background-color: $mulberry-light!important }
.bg-mulberry                { background-color: $mulberry!important }
.bg-mulberry-dark           { background-color: $mulberry-dark!important }
.bg-mulberry-darkest        { background-color: $mulberry-darkest!important }
.bg-purple-light            { background-color: $purple-light!important }
.bg-purple                  { background-color: $purple!important }
.bg-purple-dark             { background-color: $purple-dark!important }
.bg-blue                    { background-color: $blue!important }

// Colours: Neutrals
.bg-black                 { background-color: $black!important }
.bg-charcoal              { background-color: $charcoal!important }
.bg-grey-darkest          { background-color: $grey-darkest!important }
.bg-grey-dark             { background-color: $grey-dark!important }
.bg-grey-medium           { background-color: $grey-medium!important }
.bg-grey                  { background-color: $grey!important }
.bg-grey-light            { background-color: $grey-light!important }
.bg-grey-lightest         { background-color: $grey-lightest!important }
.bg-grey-ultralight       { background-color: $grey-ultralight!important }
.bg-white-off             { background-color: $white-off!important }
.bg-white                 { background-color: $white!important }

/*
 * Border Colours
 */
// Colours: Primary
.border-green                   { border-color: $green!important }
.border-green-dark              { border-color: $green-dark!important }
.border-yellow-lightest            { border-color: $yellow-lightest!important }
.border-yellow-light            { border-color: $yellow-light!important }
.border-yellow                  { border-color: $yellow!important }
.border-yellow-dark             { border-color: $yellow-dark!important }
.border-red                     { border-color: $red!important }
.border-fuscia                  { border-color: $fuscia!important }
.border-mulberry-white          { border-color: $mulberry-white!important }
.border-mulberry-lightest       { border-color: $mulberry-lightest!important }
.border-mulberry-light          { border-color: $mulberry-light!important }
.border-mulberry                { border-color: $mulberry!important }
.border-mulberry-dark           { border-color: $mulberry-dark!important }
.border-mulberry-darkest        { border-color: $mulberry-darkest!important }
.border-purple-light            { border-color: $purple-light!important }
.border-purple                  { border-color: $purple!important }
.border-purple-dark             { border-color: $purple-dark!important }
.border-blue                    { border-color: $blue!important }

.border-black                   { border-color: $black!important }
.border-charcoal                { border-color: $charcoal!important }
.border-grey-darkest            { border-color: $grey-darkest!important }
.border-grey-dark               { border-color: $grey-dark!important }
.border-grey-medium             { border-color: $grey-medium!important }
.border-grey                    { border-color: $grey!important }
.border-grey-light              { border-color: $grey-light!important }
.border-grey-lightest           { border-color: $grey-lightest!important }
.border-grey-ultralight         { border-color: $grey-ultralight!important }
.border-white-off               { border-color: $white-off!important }
.border-white                   { border-color: $white!important }

// Font Sizes
.font-size-std      { font-size: $font-size-std!important }
.font-size-xxs      { font-size: $font-size-xxs!important }
.font-size-xs       { font-size: $font-size-xs!important }
.font-size-sm       { font-size: $font-size-sm!important }
.font-size-md       { font-size: $font-size-md!important }
.font-size-lg       { font-size: $font-size-lg!important }
.font-size-xl       { font-size: $font-size-xl!important }
.font-size-xxl      { font-size: $font-size-xxl!important }
.font-size-xxxl     { font-size: $font-size-xxxl!important }
 
// Text Helpers
h1,
.h1 {
	font-family: $font-foco;
}

h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $font-family-base;
}

.text-underline {
	text-decoration: underline!important;
}

i,
em,
.italic,
.text-italic,
.font-style-italic {
	font-style: italic;
}

.link-tertiary {
	color: $fuscia;

	&:hover {
		color: darken($fuscia, 10%);
	}
}

.text-placeholder {
	color: $white-off;
	background: $white-off;
	display: inline-block;

	&.w-25 {
		width: 25%;
	}

	&.w-50 {
		width: 50%;
	}

	&.w-75 {
		width: 75%;
	}

	&.w-100 {
		width: 100%;
	}
}

.pre-title {
	font-size: $font-size-sm;
	color: $purple-light;
	margin-bottom: $space-xs;
}

.section-header {
.category {
	display: block;
	font-size: $font-size-sm;
	color: $purple-light;
	margin-bottom: $space-xs;
}

@include media-breakpoint-up(md){
	.category {
		margin-bottom: 0;
	}
}
}

// Use sparingly, elements with this class will not respoint to pointer events such as clicks
.pointer-events-none {
	pointer-events: none;
}