//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _layout.scss
// $Purpose: Styles relating to the overall layout of the site
//

html,
body {
	min-width: 375px;

	// When rendered in an iFrame on Publisher set a lower min width on the body content to prevent scroll bars
	&.in-frame {
		min-width: 360px;
	}

	// Layout class for Homeweb/Sentio templates to ensure footer is flush to the bottom of the viewport.
	&.page-homeweb-flex {
		min-height: 100vh;
		display: flex;
		flex-flow: column;

		#content {
			flex: 1 1 auto;
		}

		footer {
			margin-top: auto;
		}

		@include media-breakpoint-down(lg) {
			// Styles for pages that utilize the position sticky nav buttons on mobile. Styles are to ensure that the nav buttons are flush to the footer when they are not 'sticky'
			&.page-assessment,
			&.page-homeweb-pulsecheck,
			&.page-pulsecheck-welcome,
			&.page-homeweb-confirm-service,
			&.page-homeweb-end-service,
			&.page-homeweb-confirm-booking,
			&.page-homeweb-cancel-booking,
			&.page-homeweb-modify-booking,
			&.page-homeweb-case-creation {

				#content {
					display: flex;
				}

				.container-manager,
				.controller-content,
				.container-dsg {
					display: flex;
					flex-flow: column;
					flex: 1 1 auto;
				}

				&:has(.section-dsg-navbar-mobile) {
					footer {
						margin-top: unset;
					}
				}
			}
		}
	}
}

#content {
	overflow: hidden;
	min-height: 600px;

	&:has(#page-preview) {
		min-height: unset;
	}
}

//======================================================================//
//========================= <<<<< Pages >>>>> ==========================//
//======================================================================//

// Containers
 .container {
	 // TODO: Figure out if we want wider containers on tablets and mobile devices
	 @include media-breakpoint-down(md){
		 max-width: 95%;
	 }

	 @include media-breakpoint-down(sm){
		 max-width: 100%;
	 }

	 &.width-full {
		 // Normal for mobile
	 }

	 &.width-narrow {
		 // Normal for mobile
	 }

	 &.width-small {
		 // Normal for mobile
	 }

	 @include media-breakpoint-up(md) {
		 &.width-full {
			 max-width: 100%;
		 }

		 &.width-narrow {
			 max-width: $container-sm;
		 }

		 &.width-small {
			 max-width: $container-xs;
		 }
	 }
 }

// Section Wrapper
.section-wrapper {
	margin: $space-lg auto;

	@include media-breakpoint-up(md) {
		margin: $space-xl auto;
	}
}

//======================================================================//
//========================= <<<<< Tree view >>>>> ======================//
//======================================================================//
.container-treeview {
	overflow-y:auto;

	.content-frame {
		display: block;
		width: 100%;
		min-height: 100vh;
	}

	.container-page-sidebar {
		overflow-y: auto;
		height: 100%;
		max-height: 450px;
	}

	.container-page-dynamic {
		position: relative;

		&:has(.container-preview-footer) {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(md) {
		display: flex;
		flex-flow: row;
		height: 100vh;

		.content-frame {
			border-top: $space-xs solid $mulberry-white;
			height: 100vh;
		}

		.container-page-sidebar {
			flex:0 0 400px;
			max-height: unset;
		}

		.container-page-dynamic {
			flex: 1 1 auto;
		}
	}
}

//======================================================================//
//===================== <<<<< DSG TEMPLATES >>>>> ======================//
//======================================================================//
.container-dsg {
	display: flex;
	flex-flow: column;

	@include media-breakpoint-up(lg) {
		// min height styles are required in order for the watch tutorial/pathfinder section to display properly at the bottom above the footer
		// viewport height - header - margin top - margin-bottom -  footer
		min-height: calc(100vh - ($nav-height-desktop + 1px) -  $breadcrumb-height - calc($space-xxl * 2) - $footer-height-lg);
	}

	@include media-breakpoint-up(xxl) {
		// min height styles are required in order for the watch tutorial/pathfinder section to display properly at the bottom above the footer
		// viewport height - header - margin top - margin bottom - footer
		min-height: calc(100vh - ($nav-height-desktop + 1px) - $breadcrumb-height -  calc($space-xxl * 2) - $footer-height-xxl);
	}

	@include media-breakpoint-down(sm) {
		min-height: unset;
	}
}

.dsg-inner {
	width: 100%;
	margin-bottom: $space-xl;

	@include media-breakpoint-up(lg) {
		max-width: 80%;
	}
}

.page-dsg-dark {
	#content {
		background-image: url('#{$url-cdn-images}/backgrounds/bg-pathrinder-ring.png');
		// background-color: $mulberry-dark;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		color: $white;
		position: relative;

		&:before {
			background: $gradient-dark-mulberry;
			content: ' ';
			display: block;
			position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			z-index: -1;
			pointer-events: none;
		}

		.btn-outline-muted {
			color: $white;
		}

		.container-page {
			margin-top: calc($space-xxl + $breadcrumb-height);
		}
	}

	.homeweb-header-secondary {
		color: $white;

		a,
		.breadcrumbs ul li a{
			color: $white;

			&:hover {
				color: $white;
			}
		}
	}

	footer {
		box-shadow: -15px 0px 10px transparentize($black, 0.8);
	}
}