//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _collections.scss
// $Purpose: Styles related to tiles and collections
//

//======================================================================//
//====================== <<<<< Collections >>>>> =======================//
//======================================================================//

.container-collection {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: stretch;
}

.collection-list {
	width: 100%;
	overflow: hidden;

	//TODO: May need to uncomment below if all data is displayed in digest tiles. Otherwise all information will not fit in list view.
	//overflow-x: scroll;

	@include media-breakpoint-down(lg) {
		margin-top: $space;
	}
}

.collection-grid {
	overflow: unset;
	overflow-x: unset;
	margin-top: $space;
}

.collection-narrow {
	margin: $space auto;
	width: 90%;
}

.collection-detail {
	margin: $space auto;
}

.collection-onboarding {
	margin: $space-md 0;

	&.collection-notification {
		@include media-breakpoint-up(md) {
			max-width: calc(100% - 33.33333333% - $space-sm );
		}
	}
}

.collection-dashboard {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	margin-left: -$space-sm;
	margin-right: -$space-sm;
}

.collection-provider-matches {
	@include media-breakpoint-up(md){
		margin-right: -$space;
	}
}

//======================================================================//
//====================== <<<<< Items/Tiles >>>>> =======================//
//======================================================================//

.item {
	border-bottom: 1px solid $grey-lightest;
	padding: $space-md 0;
	align-self: stretch;

	.title {
		font-size: $font-size-md;
		line-height: $font-size-xxs*2;
		color: $mulberry-darkest;
		font-weight: 700;
		display: block;
	}

	.title-french {
		font-size: $font-size-std*0.9444444444;
		line-height: $font-size-std*1.05555555556;
		font-style: italic;
		color: $grey-dark;
	}

	.btn-outline-muted {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: calc($space-xs / 2) 0;
	}

	.content {
		margin-left: $space;
		padding-right: $space;
	}

	.item-date-meta,
	.separator {
		font-size: $font-size-xs;
		font-weight: 300;
		color: $grey-dark;
	}

	.item-date-meta {
		margin-top: $space-sm;
		margin-bottom: 0;
	}

	.separator {
		margin: 0 $space-xs;
	}

	// Icon Tile
	&.item-icon {
		@include make-col(6);
		padding: $space-sm;
		border-bottom: 0;
		text-decoration: none;


		.item-inner {
			&:hover {
				background-color: $mulberry;

				.label {
					color: $white;
				}
			}
		}

		&.completed {
			&:hover {
				.item-inner {
					background-color: $white;
				}

				.label {
					color: $mulberry;
				}
			}
		}

		.item-inner {
			text-decoration: none;
			transition-duration: 0.3s;
			height: 100%;
			padding: $space;
			border-radius: $border-radius;
			border: 1px solid $border-color;
			background-color: $white;
			flex-flow: column;
			justify-content: center;
			align-items: center;
		}

		img {
			margin-bottom: $space-sm;
		}

		.label {
			text-align: center;
			font-size: $font-size-md;
			line-height: $font-size-xl;
			color: $mulberry;
		}

		&.item-program,
		&.item-jurisdiction,
		&.item-notification {
			@include make-col(6);
			padding-left: 0;
			padding-top: 0;

			.item-inner {
				padding: $space;
				position: relative;
				overflow: hidden;
			}

			&.completed {
				.label {
					text-align: center;
					margin-top: auto;
					color: $grey-medium;
				}

				.item-inner {
					background-color: $grey-ultralight;
					padding-bottom: 0;
					padding-left: $space-xs;
					padding-right: $space-xs;

					@include media-breakpoint-up(sm) {
						padding-left: $space;
						padding-right: $space;
					}
				}

				.completion-label {
					text-align: center;
					font-size: $font-size-xxs;
					color: $success;
					margin: auto $space-xs $space-xs;
				}
			}

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}
		}

		&.item-notification {
			img {
				max-width: calc($space * 2.4444444444);
				width: 100%;
				height: auto;
			}

			@include media-breakpoint-up(md) {
				@include make-col(6);

				.item-inner {
					justify-content: flex-start;
					flex-flow: row;
				}

				img {
					margin-bottom: 0;
					margin-right: $space;
				}
			}
		}

		&.item-jurisdiction {
			.item-inner {
				min-height: 95px;

				@include media-breakpoint-up(md) {
					min-height: unset;
				}
			}
		}
	}
}

.item-inner {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.item-thumb {
	flex: 0 0 6em;
	height: 6em;
	display: flex;
		align-items:center;
		justify-content: center;
		flex-direction: column;
	background-color: $white;
	border: 1px solid $border-color;
	box-shadow: 0px 0px 4px transparentize($black, 0.9);
	text-decoration: none;
	overflow: hidden;

	.label {
		margin-top: $space-xs;
		font-weight: 600;
		font-size: $font-size-xs;
	}
}

// TODO:DAA20231215 - Refine scoping since all managers are using the same tile structure.
.item-digest {
	font-size: $font-size-xs;

	.item-header {
		display: flex;
		margin-right: auto;

		@include media-breakpoint-up(lg) {
			padding-right: $space;
		}

		img,
		svg {
			height: $space-md;
			margin-right: $space-sm;
			//padding-top: $space-xs;
		}

		.preview-icon {
			&:hover {
				i {
					color: $mulberry;
				}
			}
		}
	}

	.btn {
		padding-top: 0;
		padding-bottom: 0;
	}

	.btn-link,
	.btn-outline-muted {
		font-size: $font-size-xs;

		i {
			text-decoration: none;
		}
	}

	.btn-outline-muted {
		padding-top: 0;
		padding-bottom: 0;
		border-radius: $border-radius-md;
	}

	&.has-icon {
		.item-icon-text {
			display: flex;
			margin-bottom:auto;

			span {
				display: inline-block;
			}
			img {
				width: $space * 1.444444;
				height: auto;
			}
		}

		.title-french {
			padding-left: $space*2.05555;
		}
	}

	.item-inner {
		overflow: hidden;
	}

	.item-column-wrapper {
		display: flex;
		align-items: center;

		.col-language {
			font-size: $font-size-xxs;
			color: $grey-dark;
			margin-right: $space-sm;
		}

		.col-preview,
		.col-overview,
		.col-content,
		.col-status {
			.btn {
				i {
					text-decoration: none;
				}
			}
		}

		.col-overview {
			.btn-link {
				color: $mulberry;
			}
		}

		.col-status {
			.btn {
				font-size: $font-size-xxs;
				display: flex;
				align-items: center;
				padding-left: $space;

				img {
					margin-top: -1px;
				}
			}

			.btn-link {
				text-decoration: none;
			}
		}

		.col-content {
			.btn {
				width: 100%;

				i {
					font-size: $font-size-xxs;
				}
			}
		}
	}

	.item-linkages {
		display: flex;
		align-items: center;
		flex-flow: column;
		justify-content: center;
		padding-left: $space-xl;
	}

	.item-actions {
		display: flex;
		align-items: center;


		.btn-icon-stack {
			max-width: $space-xxxl;
		}
	}

	// LIST ITEM
	&.item-list {
		@include media-breakpoint-down(lg) {
			width: unset;
			min-width: unset;
			border-bottom: unset;
			padding: unset;

			.item-inner {
				width: unset;
				position: relative;
				overflow: hidden;
				margin: $space-sm $space-xs;
				padding: 0;
				border: 1px solid $grey-lightest;
				border-radius: $border-radius;
				flex-wrap: wrap;
				height: calc(100% - $space);

				& > div,
				.item-header,
				.item-actions {
					@include make-col(12);
				}
			}

			.item-actions {
				height: calc($space-xxxl + $space-sm);
				margin-top: auto;
				background-color: $white-off;
				border-top: 1px solid $grey-lightest;
				padding: $space-xs $space;
				justify-content: center;


				.btn-linkage {
					display: flex;
				}

				.btn-icon-stack {
					margin: 0 $space-sm;
				}
			}

			.col-language,
			.col-status {
				position: absolute;
				top: $space-sm;
			}

			.col-status {
				top: calc($space-lg/4);
			}

			.col-status,
			.col-overview {
				.btn-link {
					padding-left: 0;
				}
			}

			.item-column-wrapper {
				position: relative;
				border-bottom: 1px solid $grey-lightest;
				flex-wrap: wrap;
				margin: calc($space*1.5555555556);
				margin-bottom: $space;
				padding-bottom: $space;

				&.column-wrapper-french {
					margin-top: $space;
					margin-bottom: calc($space*1.5555555556);
				}
			}

			.item-header {
				display: block;
				margin-bottom: $space-sm;

				.item-icon-text {
					display: block;
					text-align: right;
					width: 100%;
					margin-bottom: $space-xs;

					span {
						margin-right: $space-xs;
						font-size: $font-size-xxs;
						color: $grey;
						position: relative;
					}
				}

				i {
					color: $grey-lightest;
					display: inline-block;
					font-size: $font-size-sm;
				}

				.title,
				.title-french {
					display: block;
					margin-top: $space-lg;
				}

				.title-french {
					padding-left: 0;
				}
			}

			.col-preview,
			.item-linkages {
				display: none;
			}

			.col-status {
				left: $space-xl;

				button {
					padding-left: $space-xs;
				}

				&.french {
					button {
						padding-left: $space-sm;
					}
				}
			}

			&.has-icon {
				.item-icon-text {
					position: absolute;
					top: -$space;
					right: -$space;

					img {
						margin-right: 0;

							width: 18px;
							height: auto;

					}

					span {
						text-transform: uppercase;
						display: inline-block;
					}
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.item-header {
				.title,
				.title-french {
					font-size: $font-size-xl;
					line-height: 1.2em;
				}
			}
		}

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}


		@include media-breakpoint-up(lg) {
			width: 100%;
			//min-width: 1290px; TODO: NZ2024-01-30: this min-width is needed is all buttons are present in the tile

			.item-header {
				i {
					display: none;
				}
			}

			.item-icon-text {
				span {
					display: none;
				}
			}

			.col-language {
				flex: 0 0 60px;
			}

			.col-preview,
			.col-overview,
			.col-content,
			.col-status {
				flex: 0 0 100px;
			}

			.col-overview {
				justify-content: flex-start;
				button {
					min-width: $space * 5.777778;
					text-align: left;
				}
			}

			.item-actions {
				.btn-linkage {
					display: none;
				}
			}
		}
	}

	// GRID ITEM
	&.item-grid {
		width: unset;
		min-width: unset;
		border-bottom: unset;
		padding: unset;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}

		.col-language,
		.col-status {
			position: absolute;
			top: $space-sm;
		}

		.col-status {
			top: calc($space-lg/4);
		}

		.col-status,
		.col-overview {
			.btn-link {
				padding-left: 0;
			}
		}

		.item-header {
			display: block;
			margin-bottom: $space-sm;

			.item-icon-text {
				display: block;
				text-align: right;
				width: 100%;
				margin-bottom: $space-xs;

				span {
					margin-right: $space-xs;
					font-size: $font-size-xxs;
					color: $grey;
				}
			}

			i {
				color: $grey-lightest;
				display: inline-block;
				font-size: $font-size-sm;
			}

			.title,
			.title-french {
				display: block;
				margin-top: $space-lg;
			}

			.title-french {
				padding-left: 0;
			}
		}

		.item-inner {
			width: unset;
			position: relative;
			overflow: hidden;
			margin: $space-sm $space-xs;
			padding: 0;
			border: 1px solid $grey-lightest;
			border-radius: $border-radius;
			flex-wrap: wrap;
			height: calc(100% - $space);

			& > div,
			.item-header,
			.item-actions {
				@include make-col(12);
			}
		}

		.item-column-wrapper {
			position: relative;
			border-bottom: 1px solid $grey-lightest;
			flex-wrap: wrap;
			margin: calc($space*1.5555555556);
			margin-bottom: $space;
			padding-bottom: $space;

			&.column-wrapper-french {
				margin-top: $space;
				margin-bottom: calc($space*1.5555555556);
			}
		}

		.col-preview,
		.item-linkages {
			display: none;
		}

		.col-status {
			left: $space-xl;

			button {
				padding-left: $space-xs;
			}

			&.french {
				button {
					padding-left: $space-sm;
				}
			}
		}

		.item-actions {
			height: calc($space-xxxl + $space-sm);
			margin-top: auto;
			background-color: $white-off;
			border-top: 1px solid $grey-lightest;
			padding: $space;
			justify-content: center;

			.btn-icon-stack {
				margin: 0 $space-sm;
			}

			.btn-linkage {
				display: flex;
			}
		}

		&.has-icon {
			.item-icon-text {
				position: absolute;
				top: -$space;
				right: -$space;

				img {
					margin-right: 0;

					@include media-breakpoint-down(lg) {
						width: 18px;
						height: auto;
					}
				}

				span {
					text-transform:uppercase;
					display: inline-block;
				}
			}
		}

		.col-overview {
			margin-right: $space-sm;
			flex: unset;

			a {
				text-decoration: underline;
			}
		}

		.btn {
			padding: 0;
		}

		@include media-breakpoint-down(sm) {
			.item-header {
				.title,
				.title-french {
					font-size: $font-size-xl;
					line-height: 1.2em;
				}
			}
		}
	}
}

.item-detail {
	position: relative;

	.item-actions {
		display: flex;
			justify-content: center;
		padding-top:  $space;


		@include media-breakpoint-up(md) {
			flex-direction: column;
			justify-content: center;
			align-items:flex-end;
			padding-top: 0;
		}

		.btn {
			min-width: 120px;
			margin-left: $space-sm;
			margin-right: $space-sm;

			@include media-breakpoint-up(md) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}

.item-sortable {
	transition-duration: 0.4s;

	.item-thumb {
		img {
			height: 27px
		}
	}

	.drag-handle {
		cursor: grab;
		padding-right: $space;
		font-weight: normal;
		color: $grey-medium;
	}

	&.sortable-chosen {
		background-color: $mulberry-lightest;
	}

	&.ghost {
		opacity: 0.5;
		background-color: $mulberry-lightest;
	}

	.item-actions {
		flex-flow: row;
		justify-content: center;
		padding-top: $space;

		@include media-breakpoint-up(md) {
			padding-top: 0;
			justify-content: flex-end;
		}

		.btn {
		min-width: $space*4.7222222222;
			margin: 0 $space-sm;
			padding: $space $space-sm;

			&:last-of-type {
				@include media-breakpoint-up(md) {
					margin-right: 0;
				}

			}
		}
	}

	.item-date-meta {
		display: none;
	}
}

.item-activity {
	@extend .item-sortable;
}

.item-translation {
	.col-icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding: $space-md;
		border: 2px solid $grey-lightest;

		span {
			color: $grey-dark;
			font-weight: $font-weight-bold;
		}

		i {
			font-size: $font-size-xxl;
			color: $green;
			margin-top: $space-xs;
		}
	}

	.col-article {
		color: $grey-darkest;

		.title {
			font-weight: $font-weight-bold;
		}

		// .description {}

		.date {
			font-weight: $font-weight-light;
			font-size: $font-size-sm;
		}
	}

	.col-buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		align-content: center;
		gap: $space;

		a {
			font-size: $font-size-xs;
			min-width: $space-xl;
			padding-right: $space;
			padding-left: $space;
		}
	}
}

.item-stat {
	border-bottom: none;
	padding: calc(var(--bs-gutter-x) * 0.5);

	.item-inner {
		background: $white-off;
		border-radius: $border-radius;
		display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
		//margin: $space;
		padding: $space $space-xs;
		text-align: center;
		line-height: 1.2;
		height: 100%;

		& > div {
			flex: 0 0 100%;
		}

		.text {
			font-size: $font-size-sm;
		}

		.value {
			color: $primary;
			font-size: $font-size-xxl;
		}
	}

	// Placeholder Version of this Tile
	&.item-placeholder {
		& .item-inner > div {
			visibility: hidden;
		}
	}
}

.item-quick-link {
	margin-right: $space;

	&:last-child {
		margin-right: 0;
	}

	.item-inner {
		display: flex;
			flex-direction: column;
			flex-wrap: wrap;
	}

	.content {
		font-size: $font-size-sm;
		margin-top: $space-xs;
		text-align: center;
	}

	.btn {
		padding-left: $space-sm;
		padding-right: $space-sm;
	}

	// Placeholder Version of this Tile
	&.item-placeholder {
		color: $white-off;

		.btn {
			background: $white-off;
			border-color: $white-off;
			color: inherit;

			& > * {
				visibility: hidden;
			}
		}

		.content {
			a {
				color: inherit;
				background: $white-off;
			}
		}
	}
}

.item-recent-updates-header {
	border-bottom: 4px solid $yellow-light;
	flex: 0 0 100%;
	padding: 0 0 $space-xs 0;
	display: none;

	.item-inner {
		color: $grey-dark;
		flex-wrap: wrap;
		font-weight: 300;
		text-transform: uppercase;
		font-size: $font-size-xs;
	}
	@include media-breakpoint-up(md){
		display: flex;
		.item-inner {
			flex-wrap: nowrap;
		}

		div[class*='col']:last-child {
			text-align: right;
		}
	}
}

.item-recent-updates {
	flex: 0 0 100%;
	padding: $space 0;

	.item-inner {
		font-weight: 400;
		flex-wrap: wrap;
	}

	.title {
		display: inline-block;
		color: $mulberry;
		margin-right: $space-xs;
	}

	.published-state {
		color: $grey-dark;
		font-weight: 300;
	}

	@include media-breakpoint-up(md){
		.item-inner {
			flex-wrap: nowrap;
		}

		div[class*='col']:last-child {
			text-align: right;
		}
	}

	&.item-placeholder {
		.text-placeholder {
			color: $grey-lightest;
			background: $grey-lightest;
		}
	}
}

.item-dialog {
	background: transparentize($white-off, 0.2);
	border-bottom: 2px solid $danger;
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	cursor: default;
	display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	text-align: center;

	div {
		flex: 0 0 100%;
	}

	.container-buttons {
		.btn-danger {
			margin-left: $space-sm;
		}
	}
}

.item-asset {
	&.item-list {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.item-step-large {
	display: flex;
		justify-content: center;
		align-items: center;
	padding: 0 $space-sm $space-sm 0;
	flex: 0 1 50%;

	.item-inner{
		background: $white-off;
		border-radius: $border-radius;
		height: 85px;
		text-align: center;
	}

	.content {
		margin: $space-sm 0 $space;
		padding: 0 $space-sm $space-sm;
		flex: 0 0 100%;
		font-size: $font-size-xs;

		br {
			display: none;
		}
	}

	.step-number,
	.step-title {
		flex: 1 1 100%;
		display: block;
	}

	// .step-number {}

	// .step-title {}

	&.active {
		.item-inner {
			background: $yellow-light;
		}

		.step-title {
			font-weight: 600;
		}
	}

	@include media-breakpoint-up(md){
		flex: 0 0 auto;
		padding: 0 $space $space;

		.item-inner {
			width: 170px;
			height: 170px;
			border-radius: 999px;
			text-align: center;
		}

		.content {
			margin: $space-lg*1.5 0 $space;
			font-size: $font-size-sm;
		}
	}

	@include media-breakpoint-up(lg){
		flex: 0 0 auto;
		padding: 0 $space $space;

		.item-inner {
			width: 150px;
			height: 150px;
			border-radius: 999px;
			text-align: center;
		}

		.content {
			margin: $space-lg*1.2 0 $space;
			font-size: $font-size-sm;
		}
	}

	@include media-breakpoint-up(xxl) {
		padding: 0 $space $space;

		.item-inner{
			width: 210px;
			height: 210px;
		}

		.content {
			font-size: $font-size-md;
			margin: $space-xxl*1.1 0 $space-xxl;
			padding: 0 $space $space-sm;
		}
	}
}

.item-question-answer {
	flex: 0 0 100%;

	.item-inner {
		display: block;
		position: relative;
		border-bottom: 1px solid $border-dark;
		margin-bottom: $space;
		padding-bottom: $space;
	}

	.row-question-type {
		display: flex;
			align-items: end;
	}

	.item-actions {
		display: flex;
		flex-wrap: wrap;
		max-width: 28px;
		position: absolute;
			top: calc($space-lg + $space-sm);
			right: -30px;

		.btn {
			margin-bottom: 1px;
		}
	}

	textarea {
		min-height: 120px;
	}

	.btn-question-sort,
	.btn-question-delete {
		border-radius: 0;

		i {
			font-size: $font-size-sm;
			top: 1px;
		}
	}

	.btn-question-sort {
		@include button-variant($grey-lightest, $grey-lightest, $primary, $mulberry-light, $mulberry-light, $white, $mulberry-dark );
	}

	.btn-question-delete {
		@include button-variant($white-off, $white-off, $charcoal, $danger, $danger, $white, $mulberry-dark );
	}

	.option-count {
		margin-top: -$space;
		input[type="number"] {
			background: #0fc;
			height: 0;
			padding: 0;
			opacity: 0;
		}
	}
}

.item-previous-exercise {
	border: none;
	padding: $space-sm 0 0;

	.item-inner {
		background: transparent linear-gradient(180deg, $yellow-light 0%, $white-off 100%) 0% 0% no-repeat padding-box;
		border-radius: $border-radius-xl;
		line-height: 1.2;
		padding: $space $space-md;
	}

	.pre-title {
		color: $grey-darkest;
		margin-bottom: $space-xs;
		font-size: $font-size-base;
		font-weight: 300;
	}

	.item-title {
		color: $body-color;
		font-family: $font-accent;
		font-size: $font-size-md;
		font-weight: 600;
	}
}

.item-dashboard-link {
	//@include make-col(6);
	flex: 0 0 100%;
	text-decoration: none;
	padding: $space-sm;
	border: none;

	.item-inner {
		display: flex;
			align-items: center;
			justify-content: center;
		background-color: $white;
		border-radius: $space-sm;
		box-shadow: 0 0 calc($space / 3) #0000001A;
		font-size: $font-size-md;
		padding: $space-lg;
		text-align: center;
		transition-duration: 0.3s;
		flex-wrap: nowrap;
		height: 100%;
	}

	@include media-breakpoint-up(md){
		flex: 0 0 50%;
	}

	@include media-breakpoint-up(lg) {
		//@include make-col(3);
		flex: 0 0 33%;
	}

	@include media-breakpoint-up(xl) {
		//@include make-col(3);
		flex: 0 0 25%;
	}

	&:only-child {
		flex: 0 0 auto;
	}

	.item-inner {
		padding: $space;
		line-height: 1.3;

		.icon {
			font-size: $font-size-std;
			margin-left: $space-sm;
		}
	}

	&:hover {
		.item-inner {
			color: $white;
			background-color: $mulberry;
		}
	}
}

.item-question-assessment {
	.item-inner {
		display: block;
	}
}

// Homeweb Dashboard

.item-dashboard {
	padding: 0 calc($grid-gutter-width / 2);
	border: none;

	.item-inner {
		border: 1px solid $grey-light;
		border-radius: $border-radius-lg;
		padding: $space-md;
		height: 100%;
		z-index: 1;
		flex: 1;
	}

	.btn {
		margin-top: auto;
		max-width: inherit;
	}

	&.item-book-now {
		.item-inner {
			flex-flow: column;
		}

		.btn {
			align-self: flex-start;
		}
	}

	.end-service-note {
		color: $grey-dark;
		font-size: $font-size-xs;
		margin-bottom: 0;
		padding-bottom: 0;

		button {
			padding: 0;
			line-height:1.5;
			font-size: $font-size-xs;
		}
	}
}

.item-pulsecheck-recommends,
.item-pathfinder-recommends,
.item-resource-list {
	ul {
		padding-left: 0;

		li {
			list-style: none;

			a {
				text-decoration: none;
			}
		}
	}
}

.item-pulsecheck-recommends,
.item-pathfinder,
.item-pathfinder-recommends {
	.item-inner {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.item-media {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: $space-md;

		img {
			max-width: 180px;
		}
	}
}

.item-resource-list {
	.item-inner {
		padding: 0;
	}

	.item-content {
		width: 100%;
	}

	ul {
		width: 100%;
		height: 100%;
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	li {
		flex: 1 1 auto;
		width: 100%;
		display: flex;

		.row {
			display: flex;
			justify-content: center;
			height: 100%;
			width: 100%;
			margin: 0;
			padding-left: $space;
			padding-right: $space;

			&:hover {
				p {
					text-decoration: underline;
				}
			}
		}

		&:not(:last-child) {
			border-bottom: 1px solid $grey-light;
		}

		@include media-breakpoint-down(md) {
			padding-bottom: $space;
			padding-top: $space;
		}
	}

	.column-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 64px;
	}

	.column-text {
		display: flex;
		flex-direction: column;
		justify-content: center;

		p {
			font-weight: 600;
			margin-bottom: 0;
			font-size: $font-size-std;
			line-height: 1.2
		}

		span {
			color: $black;
			font-size: $font-size-sm;
		}
	}

	img {
		padding-left: 0;
		width: 40px;
		align-self: center;
	}

	@include media-breakpoint-down(md) {
		align-items: start;
	}
}

.item-service-recommendation-list {
	li {
		padding: $space-sm 0;
	}
}

.item-pathfinder-recommends,
.item-pathfinder,
.item-single-resource {
	h2 {
		font-size: calc($font-size-xs * 2);
	}
}

.item-pulsecheck-recommends,
.item-pathfinder-recommends {
	h2 {
		font-size: $font-size-sm;
		font-weight: 600;
	}

	h3 {
		font-size: calc($font-size-xs * 2);
	}

	a {
		margin-bottom: 10px;
	}
}

.item-pulsecheck,
.item-browse,
.item-booking-v2,
.item-meetnow {
	.item-inner {
		border: none;
		display: flex;
		flex: 1;
		box-shadow: 0px 3px 6px 0px $grey-light;
		background-color: $white;

		.pre-title {
			font-size: $font-size-std;
			color: $grey-dark;
			margin-bottom: 0;
		}

		.h5 {
			font-weight: $font-weight-semibold;
		}
	}
}

.item-mulberry {
	.item-inner {
		background: $mulberry;
	}
}

.item-white {
	.item-inner {
		background: $white;
	}
}

.item-pulsecheck,
.item-pulsecheck-recommends {
	.item-inner {
		background-image: url('#{$url-cdn-images}/backgrounds/background-pulsecheck--colour.png');
		background-position: right -20px;
		background-size: 225px;
		background-repeat: no-repeat;
	}
}

.item-pulsecheck-recommends {
	.item-text {
		font-weight: 600;
		font-size: $font-size-lg;
		line-height: 1.2;
	}

	.resource:hover {
		text-decoration: underline;
	}
}

.item-browse {
	.item-inner {
		position: relative;
		flex-direction: column;

		@include media-breakpoint-up(sm) {
			background-size: 60%;
		}

		@include media-breakpoint-up(md) {
			background-size: cover;
		}

		@include media-breakpoint-up(lg) {
			background-size: 80%;
		}

		@include media-breakpoint-up(xl) {
			background-size: 60%;
		}
	}

	.btn {
		margin-top: auto;
		align-self: flex-start;
		margin-top: auto;
	}

	&.item-browse-dash {
		.item-inner {
			overflow: hidden;
			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-image: url('#{$url-cdn-images}/backgrounds/bg-dashboard-browse.png');
				background-size: 400px;
				background-repeat: no-repeat;
				background-position: top right;
				opacity: 0.65;
			}
		}



		@include media-breakpoint-up(lg) {
			.item-inner {
				&:before {
					opacity: 1;
				}
			}
		}

		p,
		a {

			@include media-breakpoint-up(lg) {
				max-width: 80%;
			}
		}

		&.item-cleveland-clinic {
			.item-inner {
				background-color: $yellow-lightest;
				&:before {
					opacity: 0.08;
					background-position: 30px top;
					background-image: url("#{$url-cdn-images}/backgrounds/ico-virtual-care.png");
				}
			}

			.list-content {
				align-self: flex-start;
			}

			ul {
				margin-bottom: 0;
				padding: 0;
				width: 50%;
				float:left;

				li {
					line-height: 1.2;
				}
			}

			.btn {
				margin-top: $space;
			}

			.end-service-note {
				color: $grey-dark;
				margin-top: $space-sm;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				span {
					display: inline-block;
					margin-right: $space-xs;
				}
			}

			.cleveland-clinic-logo {
				max-height: 19px;
				display: inline-block;
			}

			@media (min-width:430px) and (max-width: 575px) {
				.item-inner {
					&:before {
						background-position: 125% top;
					}
				}
			}

			@include media-breakpoint-up(sm) {
				.item-inner {
					&:before {
						background-position: 110% top;
					}
				}
			}

			@include media-breakpoint-up(md) {
				.item-inner {
					&:before {
						background-position: 110% top;
					}
				}
			}

			@include media-breakpoint-up(xl) {
				.item-inner {
					&:before {
						background-position: 60px 20px;
					}
				}
			}

			@include media-breakpoint-up(xxl) {
				.item-inner {
					&:before {
						background-position: 60px 20px;
					}
				}
			}
		}
	}
}

.item-pathfinder {
	.item-inner {
		padding: 0;
		flex-flow: row;
		border: none;
		border-radius: 0;
		background-image: unset;
		box-shadow: 0px 3px 6px 0px $grey-light;
	}

	.item-content {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column;

		.btn {
			margin-top: auto;
		}
	}

	.item-container {
		display: flex;
		flex: 1 1 auto;
		flex-flow: column;
		background-size: 300px;
		background-position: right top;
		background-repeat: no-repeat;
		padding: $space-md $space-lg;
	}

	.pathfinder-logo,
	.homewood-logo {
		order: 0;
		flex: 1 1 auto;
		margin-bottom: $space;

		img {
			display: block;
			max-width: 195px;
			margin: 0 auto;
		}

		@include media-breakpoint-up(sm) {
			img {
				max-width: unset;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.item-container {
			flex-flow: row;
		}

		.pathfinder-logo,
		.homewood-logo {
			order: 1;
			flex: 0 0 270px;
		}

		.item-content {
			order: 0;
		}
	}

	@include media-breakpoint-up(md) {
		.item-content {
			align-items: flex-start;

			h2 {
				text-align: left;
			}
		}

		.pathfinder-logo,
		.homewood-logo {
			order: 1;
			flex: 0 0 350px;
		}
	}

	@include media-breakpoint-up(lg) {
		.item-inner {
			flex-flow: row;
		}
	}

	&.full {
		color: $white;

		.item-inner {
			background: $gradient-dark-to-light-mulberry;
			flex-flow: column;
		}

		.item-media {
			display: none;
			flex: 1 1 auto;
			margin-bottom: 0;
			position: relative;

			picture {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
			}

			img {
				height: 100%;
				max-width: unset;
			}
		}

		.item-container {
			padding: $space-md;
			background-image: url('#{$url-cdn-images}/backgrounds/bg-pathrinder-ring.png');
			background-size: 420px;

			@include media-breakpoint-up(xxl) {
				padding: $space-xxl;
			}
		}

		.item-content {
			order: 1;
			margin-right: $space;

			.h4 {
				font-weight: $font-weight-semibold;
			}

			.btn {
				align-self: flex-start;
				margin-bottom: $space-sm;

				@include media-breakpoint-up(md) {
					margin-bottom: 0;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.item-inner {
				flex-flow: row;
			}
		}

		@include media-breakpoint-up(xl) {
			.item-media {
				display: block;
				flex: 1 0 380px;
			}
		}
	}

	&.half {
		.item-inner {
			flex-flow: column;
			background-color: $white;
			background-image: url("#{$url-cdn-images}/backgrounds/bg-pathfinder-booking-md.png");
			background-position: right center;
			background-size: contain;
		}

		.btn {
			align-self: flex-start;
		}

		.item-media {
			display: none;
		}

		.pathfinder-logo {
			order: 1;

			img {
				margin: 0;
				max-width: 195px;
			}
		}

		.item-content {
			order: 2;

			.h4 {
				@extend .h5;
				font-size: $font-size-md;
				font-weight: $font-weight-semibold;
			}
		}

		@include media-breakpoint-up(sm) {
			.pathfinder-logo {
				flex: 0 0 auto;
			}

			.item-container {
				flex-flow: column;
			}
		}

		@include media-breakpoint-up(md) {
			.item-inner {
				background-position: right center;
				background-size: contain;
			}

			.pathfinder-logo {
				flex: 0 0 auto;
				order: 1;

				img {
					max-width: unset;
				}
			}
		}

		@include media-breakpoint-between(md, lg) {
			.item-content {
				.h4 {
					font-size: $font-size-std;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.item-content {
				.h4 {
					font-size: $h5-font-size;
				}
			}

			.pathfinder-logo {
				img {
					max-width: 210px;
				}
			}
		}

		@media (min-width: 769px) and (max-width: 991px) {
			.item-inner {
				background-position: 75px 50%;
				background-size: cover;
			}
		}
	}

	&.sliver {
		.item-inner {
			border-radius: $border-radius-lg;
			overflow: hidden;
			flex-flow: row;
		}

		.item-media {
			flex:0 0 250px;
			width: unset;
			margin-bottom: 0;
			picture {
				height: 120px;
			}

			img {
				max-width: unset;
			}
		}

		.item-container {
			flex-flow: column;
			justify-content: center;
		}

		.item-content {
			order: 1;
			flex-flow: column;
			margin-right: 0;

			.h4 {
				margin-right: 0;
			}

			.btn {
				@extend .btn-outline-primary;
				flex:0 0 auto;
			}
		}

		.pathfinder-logo {
			order: 0;
			flex: 0 0 auto;

			img {
				max-width: 210px;
			}
		}

		@include media-breakpoint-up(md) {
			.item-inner {
				background-size: contain;
				background-position: right;
			}

			.item-container {
				flex-flow: row;
				align-items: flex-start;
			}

			.item-content {
				order: 0;
				margin-right: $space-lg;

				.h4 {
					margin-right: $space-sm;
				}
			}

			.pathfinder-logo {
				order: 1;
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-up(xl) {
			.item-media {
				display: block;
			}

			.item-container {
				align-items: center;
			}

			.item-content {
				flex-flow: row;
				margin-right: $space-xxl;

				.h4 {
					margin-bottom: 0!important;
					margin-right: $space-lg;
				}

				.btn {
					margin-top: 0;
					align-self: center;
				}
			}
		}
	}
}

.item-pulsecheck {
	.item-inner {
		flex-flow: column;
		background-position: right -30px;
		background-size: 180px;
	}

	.item-media {
		flex: 1 1 auto;
		order: 0;
		margin-bottom: $space;

		picture {
			margin: 0 auto;
			max-width: 195px;
		}

	}

	.item-container {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
	}

	.item-content {
		order:1;
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		flex-direction: column;
	}

	.item-text {
		font-size: $font-size-md;
	}

	.btn {
		align-self: flex-start;
	}

	.item-actions {
		flex: 0 0 auto;
		margin-top: auto;
		display: flex;
		flex-flow: row;
		align-items: center;

		.btn {
			margin-right: $space-md;
		}
	}

	@include media-breakpoint-up(sm) {
		.item-inner {
			flex-flow: column;
		}
	}

	@include media-breakpoint-up(md) {
		.item-container {
			flex-flow: column;
		}
	}

	@include media-breakpoint-up(lg) {
		.item-container {
			flex-flow: row wrap;
		}

		.item-media {
			margin-bottom: 0;
			order: 1;
			flex: 0 0 180px;
		}

		.item-content {
			order: 0;
		}
	}
}

.item-meetnow {
	.item-inner {
		flex-flow: column;
		background-image: url('#{$url-cdn-images}/backgrounds/background-meetnow--colour.png');
		background-repeat: no-repeat;
		background-position: 185% -30px;
		background-size: 220px;
		background-color: $white;
	}

	&.border-radius-sm {
		.item-inner {
			border-radius: $border-radius;
		}
	}

	&.compact {
		padding-left: 0;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.item-media {
		flex: 1 1 auto;
		order: 0;
		margin-bottom: $space;

		picture {
			margin: 0 auto;
			max-width: 195px;
		}

	}

	.item-container {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
	}

	.item-content {
		order:1;
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		flex-direction: column;
		margin-top: 0!important;
		padding-right :$space-xs;
	}

	.item-text {
		font-size: $font-size-md;
	}

	.btn {
		align-self: flex-start;
	}

	.item-actions {
		flex: 1 1 auto;
		margin-top: auto;
		display: flex;
		flex-flow: row;
		align-items: center;

		.btn-primary {
			margin-right: $space-md;
		}
	}

	&.link{
		cursor: pointer;

		.item-inner {
			border: 1px solid $border-light;
			box-shadow: 0px 0px 3px transparentize($black, 0.95);
			text-decoration: none!important;
			transition-duration: 0.2s;
		}

		h5 {
			color: $body-color
		}

		&:hover {
			cursor: pointer;

			.item-inner {
				border-color: $brand-primary;
				box-shadow: $shadow-dropdown;
				transition-duration: 0.2s;
			}

			h5 {
				color: $mulberry-dark
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.item-inner {
			flex-flow: column;
			background-position: 155% -30px;
		}

		&.compact {
			.item-container {
				flex-flow: row wrap;
			}

			.item-media {
				margin-bottom: 0;
				order: 1;
				flex: 0 0 180px;
			}
	
			.item-content {
				order: 0;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.item-inner {
			background-position: 120% -30px;
		}

		.item-container {
			flex-flow: column;
		}
	}

	@include media-breakpoint-up(lg) {
		.item-inner {
			flex-flow: row wrap;
		}

		.item-container {
			flex-flow: row wrap;
		}

		.item-media {
			margin-bottom: 0;
			order: 1;
			flex: 0 0 180px;
		}

		.item-content {
			order: 0;
		}
	}
}

.item-single-resource {
	.item-content {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}

	.item-text {
		flex: 1 1 auto;

		p {
			line-height: 1.2;
			padding-right: $space;
		}

		.h5 {
			font-weight: 600;
		}
	}

	.item-media {
		flex: 0 0 120px;
		margin-top: $space-md;

		img {
			padding-left: $space-xs;
			max-width: 120px;
			margin: 0 auto;
		}
	}

	@mixin stackedArticleStyle{
		.item-content {
			flex-wrap: wrap;
		}

		.item-text {
			flex: 0 0 100%;
			order: 1;

			.text {
				font-size: $font-size-sm;
			}
		}

		.item-media {
			flex: 0 0 100%;
			order: 0;
			margin-top: 0;
			margin-bottom: $space-sm;
			height: 130px;
			overflow: hidden;
				position: relative;

			img {
				max-width: 100%;
				width: 100%;
				height: auto;
				position: absolute;
					top: 50%;
					transform:translateY(-50%)
			}
		}
	}

	@include media-breakpoint-between(lg, xxl){
		@include stackedArticleStyle();
	}

	@media (max-width: 450px){
		@include stackedArticleStyle();
	}
}

.item-pathfinder-recommends {
	.item-inner {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 70%;
		padding: calc($space-md * 1.2);
		flex-flow:column;

		&::before {
			display: block;
			background: $mulberry;
			content: ' ';
			opacity: 0.8;
			border-radius: $border-radius-lg;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.h3 {
			font-weight: 600;
		}

		h2,
		.h2,
		h3,
		.h3,
		p {
			line-height: 1.3
		}

		h2,
		.h2,
		h3,
		.h3,
		p,
		a {
			z-index: 10;
		}
	}

	.pathfinder-inner {
		p {
			@include media-breakpoint-up (lg) {
				max-width: 80%;
			}
		}

		.pathfinder-text {
			flex: 1;
		}
	}

	.btn {
		margin-bottom: 0;
		align-self: flex-start;
	}

	&.item-sentio-recommends {
		.item-inner {
			@include media-breakpoint-up(sm) {
				flex-flow: column;
			}
		}

		.column-text,
		.column-image {
			z-index: 1;
		}

		.column-text {
			display: flex;
			flex-flow: column;

			.text {
				margin-bottom: $space-sm;
			}

			.btn {
				margin-top: auto;
			}
		}

		.column-image {
			padding-left: $space-sm;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 300px;
			margin-top: $space;

			@include media-breakpoint-up(sm) {
				margin-bottom: calc($space* -1.4);
				background-size: 100%;
				min-height: unset;
			}
		}
	}
}

.dashboard-row-container {

	.dashboard-row-inner {
		margin-left: 0;
		margin-right: 0;
		padding: $space-lg $space;
		background-color: $white;
		box-shadow: 0px 3px 6px 0px $grey-light;
	}

	.tile-resource-card.polaroid {
		display: flex;
		flex-flow: column;

		.card-container {
			height: unset!important;
			flex: 1 1 auto;
			display: flex;
			flex-flow: column;
		}

		.item-media {
			height: 180px;

			@include media-breakpoint-up(md) {
				height: 140px;
			}
		}

		.card-content {
			flex: 1 1 auto;
			height: unset;

			.summary {
				display: block;
			}

			//@include media-breakpoint-up(md) {
			//	height: 100%;
			//}
		}

		.polaroid-link {
			margin-top: auto;
		}

		&.spaced {
			margin-top: $space-lg;
		}
	}

	.col-additional-resource,
	.col-service-recommendation-list {
		padding-top: calc($space * 1.8888888889);
	}

	.item-resource-list {
		margin-top: $space-lg;
		color: blue;

		.item-inner {
			height: unset;
		}

		ul {
			justify-content: flex-start;
		}

		li {
			padding: $space 0;
			flex: 0 0 auto;
		}

		.column-icon {
			width: 70px;

			img {
				width: 45px;
			}
		}
	}
}

.item-pathfinder-recommends-v2 {
	.item-inner {
		background-color: $white;
		padding: 0;
		border-radius: 0;
		border: none;
		box-shadow: 0px 3px 6px 0px $grey-light;
		overflow: hidden;
		flex-flow: column;
	}


	.column-image {
		padding-left: $space-sm;
		padding: $space-md;
		background-position: top center;
		background-repeat: no-repeat;
		background-size: contain;
		min-height: 300px;
		margin-top: $space;
		margin-bottom: $space-md;

		@include media-breakpoint-up(sm) {
			margin-bottom: calc($space* -1.8);
			background-size: 90%;
			min-height: unset;
		}
	}
	.item-media {
		height: 200px;
		flex: 1 1 auto;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right center;
	}

	.item-content {
		flex: 1 1 auto;
		padding: $space-md;

		.h3,
		.h6 {
			font-weight: $font-weight-semibold;
		}

		.h6 {
			font-size: $font-size-sm;
		}

		p {
			line-height: 1.3;
		}

		a {
			text-decoration: none;

			&:hover {
				text-decration: underline;
			}

			i {
				font-size: $font-size-xs;
				margin-left: $space-sm;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.item-inner {
			flex-flow: column;
		}
	}

	@include media-breakpoint-up(lg) {
		.item-inner {
			flex-flow: row;
			flex-wrap: nowrap;
		}

		.item-media {
			height: unset;
			flex: 0 0 250px;
		}

		.item-content {
			padding: $space-xl;
		}
	}
}

.item-pulsecheck-recommends-v2 {
	.item-inner {
		background: none;
		padding: 0;
		border: none;
		padding: $space $space-md;
		display: flex;
			flex-flow: column;
			justify-content: center;
	}

	.btn {
		margin-top: $space;
		align-self: flex-start;
	}

	.h6 {
		font-size: $font-size-sm;
		font-weight: $font-weight-semibold;
		margin-bottom: $space-sm;
	}

	.recommendation {
		margin-bottom: $space;

		a {
			font-weight: $font-weight-bold;
			display: block;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.summary {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			-webkit-line-clamp: 2;
			line-height:1.3;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.item-pulsecheck-recommends,
.item-cleveland-clinic {
	.item-inner {
		display: flex;
		flex-direction: column;
	}

	ul {
		display: flex;
		flex-direction: column;

		li {
			position: relative;
			display: block;
			margin: 0 $space 0 0;
			padding-left: $space-lg;
			padding-top: $space-xs;
			padding-bottom: $space-xs;

			&::before {
				content: "\f058";
				font-family: $font-awesome;
				font-size: $font-size-xl;
				color: $green-dark;
				display: block;
				position: absolute;
				top: 2px;
				left: 0;
			}

			a {
				font-size: $font-size-md;
				word-break: break-word;
			}
		}
	}

	.btn-pulsecheck-recommends {
		align-self: center;
	}
}

.item-limited-access {
	color: $white;
	padding: 0 calc($grid-gutter-width / 2);

	.item-inner {
		padding: 0;
		display: flex;
		flex-flow: column;
		border: none;
		border-radius: 0;
		background: $gradient-dark-to-light-mulberry;
		box-shadow: 0px 3px 6px 0px $grey-light;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.item-media {
		display: none;
		flex: 1 1 auto;
		margin-bottom: 0;
		position: relative;

		picture {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
		}

		img {
			height: 100%;
			max-width: unset;
		}
	}

	.item-container {
		position: relative;
		display: flex;
		flex: 1 1 auto;
		flex-flow: column;
		padding: $space-md;

		@include media-breakpoint-up(xxl) {
			padding: $space-xxl;
		}
	}

	.item-content {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column;
		order: 1;
		margin-right: $space;

		.h4 {
			font-weight: $font-weight-semibold;
		}

		.btn {
			margin-top: auto;
			align-self: flex-start;
			margin-bottom: $space-sm;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
		}
	}

	.homewood-logo {
		order: 0;
		flex: 1 1 auto;
		margin-bottom: $space;

		img {
			display: block;
			max-width: 195px;
			margin: 0 auto;
		}

		@include media-breakpoint-up(sm) {
			img {
				max-width: unset;
			}
		}
	}

	.item-recommendations {
		flex: 1 1 100%;
		margin-bottom: 0;
	}

	ul {
		display: flex;
		flex-direction: column;
		margin-bottom: 0;

		li {
			position: relative;
			display: block;
			margin: 0 0 $space-xs;
			padding-left: $space-lg;
			padding-top: $space-xs;
			padding-bottom: $space-xs;
			line-height: 1.1;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: "\f111";
				font-family: $font-awesome;
				font-size: $font-size-xl;
				color: $green;
				display: block;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&.checked {
				&::before {
					content: "\f058";
					font-family: $font-awesome;
				}
			}
		}
	}

	.item-code {
		position: absolute;
		right: $space-sm;
		bottom: $space-xs;
	}

	@include media-breakpoint-up(sm) {
		.item-container {
			flex-flow: row;
		}

		.item-content {
			order: 0;
		}

		.homewood-logo {
			order: 1;
			flex: 0 0 270px;
		}

		.item-recommendations {
			order: 1;
			flex: 0 0 270px;
		}
	}

	@include media-breakpoint-up(md) {
		.item-content {
			align-items: flex-start;
		}

		.homewood-logo {
			order: 1;
			flex: 0 0 350px;
		}

		.item-recommendations {
			flex: 0 0 350px;
		}
	}

	@include media-breakpoint-up(lg) {
		.item-inner {
			flex-flow: row;
		}
	}

	@include media-breakpoint-up(xl) {
		.item-media {
			display: block;
			flex: 0 0 380px;
		}
	}

	@include media-breakpoint-down(sm) {
		.item-recommendations {
			order: 2;
			margin-bottom: $space-sm;
			margin-top: $space;

			ul {
				padding-left: 0;
			}

		}
	}
}

.item-sentio-therapy {
	.item-inner {
		background-image: url("#{$url-cdn-images}/backgrounds/background-dashboard-vector-mulberry02.png");
		background-repeat: no-repeat;
		overflow: hidden;

		background-position-x: -501px;
		background-size: 208%;
		background-position-y: 0;

		&::before {
				background-color: rgba($mulberry, 0.7);
		}

		@include media-breakpoint-down(lg) {
			background-position-x: -400px;
			background-size: 208%;
		}

		.column-text {
			display: flex;
			flex-direction: column;

			.text {
				flex: 1;
			}

		}

		.column-image {
			position: relative;

			img {
				position: absolute;
				top: 0;
				right: 0;
				max-width: 90%;

				@include media-breakpoint-between(xl, xxl) {
					bottom: -25%;
					max-width: 100%;
				}

				@include media-breakpoint-between(lg, xl) {
					bottom: -28%;
					max-width: 120%;
					right: -27px;
				}

				@include media-breakpoint-between(md, lg) {
					top: -3%;
				}

				@include media-breakpoint-between(sm, md) {
					top: 0;
				}

				@include media-breakpoint-down(sm) {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}

.item-booking {
	.appointment-time,
	.appointment-type,
	.name,
	.instructions,
	.number,
	.email-address {
		font-weight: 600;
	}

	.number,
	.email-address {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.phone,
	.number,
	.email,
	.email-address {
		// font-size: $font-size-sm;
	}

	.name {
		font-size: $font-size-lg;
	}

	.item-inner {
		display: flex;
			flex-direction: row;
		padding: 0;
	}

	.item-header {
		padding: $space-md $space-md 0 $space-md;
		width: 100%;
		max-height: 120px;
		margin-bottom: $space-xs;

		h3 {
			color: $grey-dark;
			font-size: $font-size-std;
			margin-bottom: 0;
		}

		.appointment-time {
			span {
				color: $body-color;
			}

			.btn {
				position: relative;
					top: -3px;
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		p {
			line-height: 1.2;
		}
	}

	.item-content {
		flex: 1;
		padding: 0 $space-md;
		font-size: $font-size-sm;
		margin-bottom: $space;
	}

	.provider-image {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		margin: 0 auto;
		overflow: hidden;

		picture {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: none;
		}
	}

	.row-provider,
	.row-appointment-details {
		line-height: 1.2;
		p {
			margin-bottom: 0;
		}
	}

	.row-provider {
		display: flex;
			flex-wrap: nowrap;
		margin-bottom: $space-sm;

		button {
			display: inline-flex;
			color: $mulberry-dark;
			background-color: transparent;
			margin-top: 0;
			padding: 0;
			font-size: $font-size-sm;
		}

		.column-provider-details{
			flex: 1 1 auto;
			display: flex;
				flex-direction: column;
				justify-content: center;
		}
	}

	.item-actions {
		display: flex;
		width: 100%;
		padding-top: $space-sm;
		padding-bottom: $space-sm;
		background-color: $grey-ultralight;
		border-bottom-left-radius: $border-radius-lg;
		border-bottom-right-radius: $border-radius-lg;
		border-top: 1px solid $grey-light;
		justify-content: center;
		margin-top: auto;

		.btn:first-child {
			margin-right: $space;
		}
	}
}

.item-booking-v2 {
	$calendar-width: 80px;

	.item-inner  {
		flex-flow: column;
		border-radius: 0;
		padding: 0;
	}

	// TODO: Add to base
	.booking-label {
		display: block;
		color: $grey-dark;
		font-size: $font-size-sm;
	}

	// TODO: Add to base
	.end-service-note {
		color: $grey-dark;
		font-size: $font-size-xs;

		button {
			padding: 0;
			color: $mulberry;
			background-color: transparent;
			font-size: $font-size-xs;

			&:hover {
				color: $link-hover-color;
			}
		}
	}

	.item-header {
		//flex: 0 0 108px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		border-bottom: 1px solid $border-light;
		padding: calc($space * 0.6666666667) $space-md;
	}

	.item-content-container {
		flex: 1 1 100%;
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		padding: calc($space * 0.6666666667) $space-md 0;

		p {
			line-height: 1.2;
		}

		@include media-breakpoint-up(md) {
			display: block;
		}

		@include media-breakpoint-up(xl) {
			display: flex;
			flex-wrap: nowrap;
		}
	}

	.header-content {
		flex: 1 1 auto;
		padding: 0 $space-xs $space-xs 0;

		@include media-breakpoint-up(sm) {
			padding: $space-xs $space-xs $space-xs 0;
		}

		.pre-title {
			color: $grey-dark;
		}

		.h4 {
			line-height: 1.1;
		}
	}

	.header-actions {
		flex: 1 1 auto;
		display: flex;
		justify-content: space-between;

		.btn {
			min-width: calc(50% - $space-sm);
			line-height: 1.1;
			margin-top: unset;
			display: flex;
				align-items:center;
				justify-content: center;

			&:first-child {
				margin-right: $space-sm;
			}
		}

		@media(min-width:423px) {
			margin-top: 0;
		}

		@include media-breakpoint-up(sm) {
			flex: 0 1 auto;
			margin-top: 0;

			.btn {
				min-width: 115px;
			}
		}

		@include media-breakpoint-up(md) {
			flex: 1 1 100%;
			margin-left: 0;

			.btn {
				min-width: calc(50% - $space-sm);

				&:first-child {
					margin-right: $space-sm;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			margin-top: 0;
			flex: 0 1 auto;

			.btn {
				min-width: 115px;
			}
		}
	}

	.item-calendar-container {
		max-width: $calendar-width;
		width: 100%;
		display: flex;
			flex-flow: column;
			align-items: center;
		margin-right: $space-md;

		.fa-calendar-plus {
			margin-right: $space-xs;
		}

		.add-text {
			font-size: $font-size-xs;
		}

		.btn {
			color: $mulberry-dark;
			margin-top: 0;

			&:hover {
				color: $mulberry-darkest;
			}
		}

		@include media-breakpoint-down(sm) {
			max-width: 45px;

			.fa-calendar-plus {
				margin-right: 0;
			}

			.btn {
				margin-top: $space-xs;
				padding: 0;
				display: flex;
				flex-flow: column;
				align-items: center;
			}
		}

		@include media-breakpoint-up(md) {
			float: left;
		}
	}

	.calendar {
		width: 100%;
		border: 1px solid $border-color;
		border-radius: $border-radius-lg;
		overflow: hidden;

		.month,
		.day {
			text-align: center;
		}

		.month {
			width: 100%;
			display: block;
			text-transform: uppercase;
			background-color: $red;
			color: $white;
		}

		.day {
			display: block;
			text-align: center;
			font-size: $font-size-xxl;
			font-weight: $font-weight-semibold;
		}

		@include media-breakpoint-down(sm) {
			border-radius: $border-radius;

			.month {
				font-size: $font-size-sm;
			}

			.day {
				font-size: $font-size-std;
			}
		}
	}

	.item-appointment-details {
		margin-right: $space-xs;
		margin-bottom: $space-sm;

		.appointment-date-time {
			display:block;
			line-height: 1;
			margin-bottom: $space-sm;
		}

		.instructions {
			line-height: 1.2;
		}

		@include media-breakpoint-up(lg) {
			margin-right: $space;
		}
	}

	.item-content {
		flex:1 1 auto;
	}

	.item-provider {
		flex: 0 0 270px;
		display: flex;
		flex-flow: row;

		.name {
			line-height: 1;
			margin-bottom: 0;
		}

		.btn-link {
			font-size: $font-size-sm;
			color: $link-color;
			padding: 0;
			text-align: left;
		}

		@include media-breakpoint-up(sm) {
			justify-content: flex-end;
		}

		@include media-breakpoint-up(md) {
			justify-content: flex-start;
		}

		@include media-breakpoint-up(lg) {
			margin-left: calc($calendar-width + $space-md);
			margin-bottom: $space-md;
		}

		@include media-breakpoint-up(xl) {
			margin-left: auto;
			margin-bottom: 0;
		}
	}


	.provider-image {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		margin-right: $space-sm;
		overflow: hidden;

		picture {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: none;
		}
	}

	.end-service-note {
		display: block;
		padding: calc($space * 0.6666666667) $space-md $space-sm;
		margin-top: auto;

		@include media-breakpoint-up(lg) {
			padding: 0 $space-md $space-sm calc($calendar-width + $space-md + $space-md);
		}
	}

	&.sliver:not(.item-booking-end-services-v2, .item-booking-continue-v2, .item-booking-text-v2, .item-booking-future-v2) {

		.item-content-container {
			flex: 1;
		}

		.end-service-note {
			order: 3;
			flex: 0 0 auto;
			padding-bottom: $space-sm;
		}

		.header-actions {
			display: flex;
			flex-flow: row;
		}

		.appointment-date-time {
			margin-bottom: 0;
		}

		.instructions,
		.booking-label.d-inline-block.mb-0,
		.phone,
		.email {
			display: none;
		}

		@include media-breakpoint-up(sm) {

			.header-actions {
				display: flex;
				flex-flow: row;

				.btn {
					&:first-child {
						margin-right: $space-sm;
						margin-bottom: 0;
					}
				}
			}
		}

		@include media-breakpoint-up(md) {
			.item-provider {
				display:none;
			}

			.instructions {
				clear: both;
			}
		}

		@include media-breakpoint-up(lg) {
			.instructions {
				clear:unset;
			}

			.header-actions {
				flex-flow: row;
				flex: 1;
				justify-content: space-evenly;
				.btn {
					width: 48%;

					&:first-child {
						margin-right: 0;
						margin-bottom: 0;
					}
				}
			}

			.end-service-note {
				padding-left: 0;
				text-align: center;
			}
		}

		@include media-breakpoint-up(xl) {
			.header-actions {
				.btn {
					&:first-child {
						margin-right: $space-sm;
					}
				}
			}

			.end-service-note {
				text-align: unset;
				padding-left: calc($calendar-width + $space-md + $space-md);
			}
		}
	}

	&.item-booking-current-v2 {
		.appt-email {
			word-break: break-all;
		}

		@include media-breakpoint-down(sm) {
			.item-calendar-container {
				flex: 0 1 45px;
				max-width: unset;
				margin-right: $space;
			}

			.item-appointment-details {
				margin-right: 0;
				flex: 1;
			}

			.item-provider {
				margin-top: $space-sm;
				flex: 0 0 100%;
			}
		}

		@include media-breakpoint-up(sm) {
			.item-calendar-container {
				flex: 0 0 80px;
			}

			.header-actions {
				margin-top: 0;
			}
		}

		@include media-breakpoint-between(sm, md) {
			.item-appointment-details {
				flex: 0 1 calc(100% - 110px);
			}

			.item-provider {
				margin-top: $space-sm;
				justify-content: flex-start;
			}
		}

		&.sliver {
			.header-actions {
				justify-content: space-between;
			}

			.item-appointment-details {
				.instructions {
					display: block;
				}
			}
		}

		&.item-booking-confirm {
			margin-top: $space-xl;

			.item-content-container {
				padding: calc($space * 0.6666666667) $space-md;
			}
		}
	}
}

.item-booking-end-services-v2 {
	.item-inner {
		display: block;
	}

	.item-header {
		max-height: unset;

		p {
			margin-bottom: 0;
		}
	}

	.item-content-container {
		padding: calc($space * 0.6666666667) $space-md;
	}

	.item-provider {
		flex: 1 1 auto;
		margin-left: 0;
		justify-content: flex-start;
	}

	.header-actions {
		margin-top: auto;
		margin-left: 0;


		@include media-breakpoint-up(sm) {
			margin-top: unset;
		}

		@include media-breakpoint-up(md) {
			margin-top: auto;
		}

		@include media-breakpoint-up(xxl) {
			margin-top:unset;
		}
	}

	&.sliver {
		.column-provider-details {
			.btn-link {
				text-align: left;
			}
		}

		@include media-breakpoint-up(md) {
			.header-actions {
				flex-flow:column;

				.btn {
					&:first-child {
						margin-right: 0;
						margin-bottom: $space-sm;
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.header-actions {
				flex-flow:row;

				.btn {
					&:first-child {
						margin-right: $space-sm;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.item-booking-continue-v2 {
	.item-inner {
		flex-flow: column;
	}

	.item-header {
		flex-flow: column;
		align-items: flex-start;

		.btn {
			margin-top: $space;
		}

		@include media-breakpoint-up(sm) {
			flex-flow: row;
			align-items: center;

			.btn {
				margin-top: 0;
			}
		}
	}

	.header-actions {
		margin: 0;
		justify-content: flex-end;
	}

	.end-service-note {
		margin-left: $space-md;
		padding-left:0;
	}

	&:not(.sliver) {
		@include media-breakpoint-between(md, lg) {
			.item-header {
				flex-flow: column;
				align-items: flex-start;
			}
		}
	}

	&.sliver {
		flex: 1;

		.item-header {
			align-items: flex-start;
		}

		.header-actions {
			.btn {
				margin-top: 0;

				&:first-child {
					margin-top: 0;
				}
			}
		}

		@include media-breakpoint-up(sm) {
			.item-header {
				align-items: center;
			}
		}

		@include media-breakpoint-up(md) {
			.item-header {
				align-items: flex-start;
				flex-flow: column;
			}
		}

		@include media-breakpoint-up(xl) {
			.item-header {
				align-items: center;
				flex-flow: row;

				.btn {
					margin-top: 0;
					margin-right: 0;
				}
			}
		}
	}
}

.item-booking-text-v2 {

	.end-service-note {
		margin-left: $space-md;
		padding-left: 0;
	}
}

.item-booking-future-v2 {
	.end-service-note {
		padding-left: $space-md;
	}

	.item-header {
		.header-actions {
			flex: 0 0 auto;

			.btn {
				&:first-child {
					margin-left: 0;
					margin-right: 0;

					@media (min-width:389px) and (max-width:575px) {
						margin-left: auto;
					}

					@include media-breakpoint-up(sm) {
						margin-left: auto;
					}

					@include media-breakpoint-up(md) {
						margin-left: 0;
					}

					@include media-breakpoint-up(lg) {
						margin-left: auto;
					}
				}
			}
		}
	}

	.item-content-container {
		display:block;

		@include media-breakpoint-up(sm) {
			display: flex;
		}

		@include media-breakpoint-up(md) {
			display: block;
		}

		@include media-breakpoint-up(xl) {
			display: flex;
		}
 	}

	.item-provider {
		@include media-breakpoint-up(sm) {
			margin-left:auto;
		}

		@include media-breakpoint-up(md) {
			margin-left: 0;
		}

		@include media-breakpoint-up(lg) {
			margin-left:auto;
		}
	}

	&.sliver {
		.item-header {
			flex: 1;
		}
		.header-actions {
			flex: 0 0 auto;
		}

		.end-service-note {
			margin-top: $space;
			margin-left: $space-md;
			margin-botom: $space-md;
			padding-left: 0;
			padding-bottom: $space-sm;
		}

		@include media-breakpoint-up(md) {
			.item-provider {
				flex-flow: column;
			}
		}

		@include media-breakpoint-up(lg) {
			.item-provider {
				margin-left: 0;
				margin-bottom: 0;
				flex-flow: row;
			}
		}

		@include media-breakpoint-up(xl) {
			.item-provider {
				justify-content: flex-end;
			}
		}
	}
}

.item-booking-more-options {
	.item-inner {
		background: $yellow-lightest;
		box-shadow: unset;
		color: $body-color;
		border: none;
		justify-content: center;


		.item-text {
			text-align: center;
		}

		.btn-outline-primary {
			margin-top: unset;
			align-self: center;
		}
	}
}

.item-booking-provider {
	flex: 0 0 100%;
	padding: 0 0 $space;
	border: none;

	.item-inner {
		border: 1px solid $border-color;
		border-radius: $border-radius-lg;
		overflow: hidden;
		height: 100%;
		flex-direction: column;
	}

	.col-provider-image {
		margin-right: $space;
		text-align: center;
		position: relative;
	}

	.provider-image {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		margin: 0 auto $space-xs;
		overflow: hidden;

		picture {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: none;
		}
	}

	.modality-icons {
		display: flex;
			justify-content: space-between;
		margin-top: $space-sm;
		width: 100%;

		picture {
			width: $space;
			margin-right: $space-xs;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.item-content {
		background: $white;
		flex: 1 1 auto;
		padding: $space $space $space-sm;
		font-size: $font-size-xs;
		line-height: 1.2;
	}

	.col-provider-details {
	}

	p {
		margin-bottom: 0;
	}

	.badge {
		margin-bottom: $space-xs;
		line-height: 1.1;
	}

	.provider-name {
		font-size: $font-size-md;
		font-weight: 700;
	}

	.provider-type {
		font-size: $font-size-sm;
		color: $grey-dark;
		padding-bottom: $space;
	}

	table {
		margin-bottom: 0;
		td {
			padding: 0 $space-xs $space-xs 0;

			&:last-child {
				padding-right: 0;
			}
		}

		em {
			font-style: normal;
			&:not(:last-child) {
				&:after {
					content: ', '
				}
			}
		}
	}

	.item-actions {
		flex: 0 0 auto;
		width: 100%;
		background: $white-off;
		border-top: 1px solid $border-color;
		padding: $space-sm;

		.btn {
			width: 100%;
		}
	}

	@include media-breakpoint-up(md) {
		flex: 0 0 50%;
		padding: 0 $space $space 0;

		.provider-name {
			font-size: $font-size-lg;
		}

		// &:nth-child(2n){
		// 	padding-right: 0;
		// }
	}

	@include media-breakpoint-up(xxl) {
		flex: 1 0 33%;
		padding: 0 $space $space 0;

		// &:nth-child(2n){
		// 	padding-right: $space;
		// 	background: inherit;
		// }

		// &:nth-child(3n){
		// 	padding-right: 0;
		// 	flex: 1 0 33%;
		// }
	}
}

.item-book-now {
	.item-inner {
		background: $mulberry;
	}
}

// Collection Table
.table-collection {
	.cell {
		vertical-align: middle;
	}
}

.item-manager {
	.title {
		margin-bottom: 0;
	}
}

// HOMEWEB - Category List Tile
.item-library,
.item-category-list {
	@include make-col(12);
	background-color: $white;
	padding: $space-md;
	transition-duration: 0.3s;

	@include media-breakpoint-up(lg) {
		padding: $space-xl;
	}

	a {
		text-decoration: none;


		&.h3 {
			font-size: calc($font-size-std * 1.7777777778);
			font-weight: 600;
		}
	}

	&:hover {
		background-color: $mulberry-white;
	}
}

.item-library {
	background-color: $yellow-light;

	&:hover {
		background-color: $yellow-light;
	}
}

.item-category-list {
	.item-content {
		@include make-col(12);
		display: flex;
		flex-flow: column;
		justify-content: center;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		@include media-breakpoint-up(lg) {
			@include make-col(9);
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				float: left;
				margin-right: $space-sm;

				a {
					font-size: $font-size-sm;
					text-transform: uppercase;
					transition-duration: 0.3s;
					border-bottom: 2px solid transparentize($primary, 1);

					&:hover {
						border-bottom: 2px solid $primary;
					}
				}
			}
		}
	}

	.item-media {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		min-height: 105px;
		@include make-col(12);
		margin-top: $space;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
			margin-top: 0;
		}

		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}

	}

	&.item-library {
		.item-content {
			display: block;
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}
	}

	&.minimal {
		background-color: unset;
		padding: $space 0;

		&:first-child {
			padding-top: 0;
		}

		.item-content {
			@include make-col(12);

			.h3 {
				@extend .h4;
				font-weight: 600;
				margin-bottom: 0;

				&:hover {
					color: $primary;
				}
			}

			ul {
				li {
					font-weight: 600;
				}
			}
		}

		.item-media {
			display: none;
		}
	}

	&.sliver {
		background-color: transparent;
		padding: 0;
		border-bottom: 0;
		margin-bottom: $space-lg;


		.item-inner {
			@include media-breakpoint-up(sm) {
				flex-wrap: nowrap;
			}
		}

		.item-media {
			flex: 1 1 auto;
			height: 100px;
			margin-bottom: $space;

			@include media-breakpoint-up(sm) {
				margin-bottom: 0;
				flex: 0 0 105px;
			}
		}

		.item-content {
			flex: 1 1 auto;
			justify-content: flex-start;

			.title {
				display: block;
				font-family: $font-standard;
				font-size: calc($font-size-std * 1.7777777778);
				line-height:1;
				font-weight: 600;
				color: $body-color;
				margin-bottom: $space-sm;
			}

			p {
				margin-bottom: 0;
				line-height: 1.3;
			}

			@include media-breakpoint-up(sm) {
				margin-left: $space-lg;
			}

			p {
				color: $black;
			}
		}

		&:hover {
			.item-content {
				.title {
					color: $primary;
					text-decoration: underline;
				}
			}
		}
	}
}

// HOMEWEB RESOURCE TILE THAT ONLY DISPLAYS TEXT
.item-resource-text {
	display: block;
	text-decoration: none;
	padding: $space-lg $space;
	transition-duration: 0.3s;

	&:hover {
		background-color: $mulberry-white;
	}

	.item-content {
		width: 100%;
	}

	.categories {
		display: block;
		width: 100%;
		overflow: hidden;
		margin-bottom: $space-xs;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				font-size: $font-size-sm;
				font-weight: 600;
				float: left;
				color: $mulberry-light;
				text-transform: uppercase;
				margin-right: $space-xs;
			}
		}
	}

	.content-container {
		display: flex;
		flex-wrap: wrap;
	}

	.content {
		@include make-col(12);
		padding: 0;
		margin: 0;
		color: $black;

		.title {
			font-family: $font-standard;
			font-size: calc($font-size-std * 1.7777777778);
			font-weight: 600;
			color: $body-color;
			text-transform: initial;
			line-height: 1;
			margin-bottom: $space-sm!important;
		}

		p {
			line-height: 1.3;
		}
	}

	.duration-column {
		@include make-col(12);

		.resource-duration {
			&:hover {
				background-color: darken($success, 5%);
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.content {
			@include make-col(8);

			p {
				margin-bottom: 0;
			}
		}
		.duration-column {
			@include make-col(4);

			.resource-duration {
				float: right;
			}
		}
	}
}

//======================================================================//
//========== <<<<< HOMEWEB Resource / HOMEWEB Event Card >>>>> =========//
//======================================================================//
.tile-resource-card,
.tile-event-card {
	.card-container {
		display: block;
		box-shadow: 0px 3px 6px 0px $grey-light;
		background-color: $white;
	}

	.card-container {
		text-decoration: none;
		transition-duration: 0.3s;

		.spacer {
			display: block;
			height: calc($space * 1.5);
		}

		&:hover {
			box-shadow: 0 1px 14px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.24);

			.resource-duration {
				background-color: darken($success, 5%);
			}
		}

		.card-content {
			padding: calc($space * 1.3888888889);
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			display: flex;
			flex-flow: column;

			.resource-duration {
				align-self: start;
				margin-top: auto;
			}

			.h4 {
				font-weight: 600;
				line-height: 1.2;
				margin-bottom: $space-sm;
			}

			p {
				margin-top: 0;
				line-height: 1.3;
			}
		}
	}

	.category-list {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			float: left;
			padding-right: $space-xs;
			color: $mulberry-light;
			font-weight: 600;
			font-size: $font-size-sm;
			text-transform: uppercase;
		}
	}

	&.card-highlight {
		.card-container {
			background: $mulberry-light;
			background: linear-gradient(180deg, $mulberry-light 0%, $mulberry-dark 100%);

			.category-list {
				li {
					color: $white;
				}
			}

			h1,.h1,
			h2,.h2,
			h3,.h3,
			h4,.h4,
			p,
			.event-day,
			.event-month {
				color: $white;
			}

			.event-day,
			.event-month,
			.event-description {
				p {
					color: $white;
				}
			}

			.resource-duration,
			.event-btn {
				color: $black;
				background-color: $white;

				.fa-solid {
					background-color: $grey-medium;
					color: $white;
				}
			}
		}
	}

	&.tile-event-card {
		a {
			text-decoration: none;
		}

		.event-day,
		.event-month {
			display: block;
			margin-bottom: 0;
			text-align: center!important;
			line-height: 1;
		}

		.event-day {
			text-align: left;
			font-weight: 300;
			font-size: $font-size-xxxl;
		}

		.event-month {
			font-weight: 600;
		}

		.card-content {
			min-height: 250px;
			display: flex;
		}

		.summary-column {
			.h4 {
				line-height: 1em;
				font-weight: 600;
			}

			@include media-breakpoint-up(lg) {
				// margin-top: $space;
			}

			@include media-breakpoint-up(xxl) {
				// padding-left: $space-lg;
				margin-top: 0;
			}
		}

		.event-actions {
			margin-top: auto;
		}

		.event-description {
			color: $grey-dark;
		}

		.event-actions {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;

			.event-btn {
				flex:1 1 auto;
				margin: 0 $space-xs $space-xs;

				@include media-breakpoint-down(sm) {
					flex: 1 1 100%;
					margin: 0 0 $space-xs;
				}

			}
		}

		.count-me-out {
			i {
				color: $grey-dark;
			}
		}

		&.card-highlight {
			.event-btn {
				&:hover {
					background-color: darken($white, 10);
				}
			}
		}
	}

	.polaroid-link,
	.item-media{
		display: none;
	}

	.polaroid-link {
		margin-top: $space-xl;
		display: inline-block;
		color: $primary;

		.fa-chevron-right {
			color: $primary;
			font-size: $font-size-xs;
		}
	}

	&.polaroid {
		.item-media {
			display: block;
			width: 100%;
			height: 180px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		.spacer,
		.category-list {
			display: none;
		}

		.card-content {
			.summary {
				display: none;
			}
		}

		.resource-duration {
			display: none;
		}

		&:hover {
			.polaroid-link {
				color: $link-hover-color;

				.fa-chevron-right {
					color: $link-hover-color;
				}
			}
		}
	}

	&.polaroid-list {
		.card-container {
			display: flex;
			flex-flow: row;
		}

		.item-media {
			flex: 0 0 100px;
			display: block;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		.spacer,
		.category-list,
		.resource-duration {
			display: none;
		}

		.polaroid-link {
			margin-top: unset;
			display: block;
		}

		.card-content {
			flex: 1 1 auto;
			height: unset;
			padding: $space;

			.h4 {
				margin-bottom: $space-xs;
				font-size: $font-size-md;
			}

			.summary {
				font-size: $font-size-sm;
				display: block;
				margin-bottom: calc($space / 3);
				line-height: 1.2;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				-webkit-line-clamp: 3;
			}

			.polaroid-link {
				font-size: $font-size-sm;

				.fa-chevron-right {
					font-size: $font-size-xxs;
				}
			}
		}

		@include media-breakpoint-up(sm) {
			.item-media {
				flex: 0 0 200px;
			}

			.card-content {
				padding: $space-lg;

				.h4 {
					font-size: $h4-font-size;
				}

				.summary {
					font-size: $font-size-std;
					line-height: 1.3;
				}

				.polaroid-link {
					font-size: $font-size-std;
				}
			}
		}

		@include media-breakpoint-up(md) {
			.summary {
				margin-bottom: $paragraph-margin-bottom;
			}

			.card-content {
				padding: calc($space-md * 2);
			}
		}
	}

	&.polaroid-resource-duration {
		display: flex;

		.item-media {
			flex: 0 1 180px;
		}

		.card-container {
			display: flex;
			flex-flow: column;
			flex: auto;
		}

		.polaroid-link {
			display: none;
		}

		.card-content {
			display: flex;
			flex: 1;

			.summary {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				-webkit-line-clamp: 3;
			}
		}

		.resource-duration {
			margin-top: auto;
			display: inline-flex;
		}
	}

	&.tile-resource-browse-resources {
		align-self: flex-start;

		.card-container {
			display: flex;
			flex-flow: column;
		}

		.item-media {
			padding: calc($space * 1.3888888889);
			padding-bottom: 0;
			display: block;

			img {
				width: 100px;
			}
		}

		.card-content {
			padding: $space calc($space * 1.3888888889) calc($space * 1.3888888889);

			.polaroid-link {
				margin-top: unset;
			}
		}

		.polaroid-link {
			text-decoration: none;
		}

		@include media-breakpoint-up(md) {
			.card-content {
				padding: $space calc($space-md * 2) calc($space-md * 2);
			}

			.item-media {
				padding: $space-lg calc($space-md * 2) calc($space-md * 2);
				padding-bottom: 0;
			}
		}

	}
}

.item-list-minimal {
	margin-bottom: $space;

	a {
		font-weight: $font-weight-bold;
		display: block;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.summary {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		-webkit-line-clamp: 2;
		line-height:1.3;
	}

	&:last-child {
		margin-bottom: 0;
	}

}

.page-homeweb-dashboard {
	.tile-resource-card {
		.h4 {
			display: flex;
			// margin-bottom: $space-sm;
		}
	}

	@include media-breakpoint-down(lg){
		.tile-resource-card {
			&:first-child {
				margin-top: 0!important;
			}
		}
	}
}

//======================================================================//
//============= <<<<< Tile Resource Image Overlay >>>>> ===========//
//======================================================================//
.item-resource-image-overlay {
	position: relative;
	text-decoration: none;
	color: $white;
	margin-bottom: calc($space * 1.3333333333);
	min-height: 200px;

	a {
		text-decoration: none;
	}

	.item-inner {
		box-shadow: 0px 3px 6px 0px $grey-light;
		position:relative;
		padding: $space-xl;
		height: 100%;
		min-height: 300px;
	}

	.item-background,
	.item-overlay {
		z-index: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.item-overlay {
		transition-duration: 0.3s;
		background-color: transparentize($black, 0.5);
		z-index: 1;
	}

	.item-content {
		position: relative;
		z-index: 2;
		height: 100%;
		display: flex;

		.h1 {
			@extend .h2;
			color: $white;
			font-weight: 600;
			text-align: left!important;
		}
	}

	&:hover {
		.item-overlay {
			background-color: transparentize($black, 0.9);
		}
	}

	@include media-breakpoint-up(lg) {
		min-height: unset;
	}
}

//======================================================================//
//================ <<<<< HOMEWEB Booking Option >>>>> ==================//
//======================================================================//
.item-booking-option {
	background-color: $white;
	border: 1px solid $border-color;
	border-radius: $border-radius-lg;
	padding: 0;
	margin-bottom: $space;

	&:last-child {
		margin-bottom: 0;
	}

	.item-inner {
		display: flex;
		flex-wrap: wrap;
		padding: $space;

		// TODO: Temporarily disabling this so that the layout makes more sense
		// @include media-breakpoint-down(xl) {
		// 	flex-wrap: wrap;
		// }
	}

	.col-bio {
		display: flex;
		flex-flow: column;
		flex: 0 0 80px;
		text-align: center;
		margin-right: $space-md;

		.provider-image {
			width: 80px;
			height: 80px;
			overflow: hidden;
			border-radius: 50%;
			overflow: hidden;
			margin: 0 auto $space-xs;

			picture {
				width: 100%;
				height: 100%;
			}

			img {
				width: auto;
				object-fit: cover;
			}
		}

		.btn-link {
			color: $mulberry;
			font-size: $font-size-sm;
			padding-top: 0;
			margin-top: 0;
		}
	}

	.col-content {
		flex: 1 1 auto;
		overflow: hidden;

		& > span {
			display: block;
			margin-bottom: $space-xs;
		}

		p.swipe-text {
			display: block;
			font-size: $font-size-sm;
			line-height: $font-size-sm;
			margin-bottom: $space-xs;
			color: $grey-darkest;

			@include media-breakpoint-up(md) {
				display: none;
			}
		}

		.provider-name {
			// margin-bottom: $space-sm;
			font-weight: 600;
		}
	}

	.col-filter {
		flex: 1 1 45%;

		@include media-breakpoint-up(xl){
			flex: 0 0 45%;
			// position: relative;
			// 	top: calc($space * -1);
		}
	}

	.btn-pagination {
		display: none;
	}

	.btn-time {
		margin-right: calc($space * 0.6666666667);
		border: 1px solid $border-color;
		border-radius: $border-radius;
		font-size: $font-size-sm;
		line-height: $font-size-sm;
		font-weight: 600;

		&:hover {
			border-color: $mulberry-lightest;
			background-color: $mulberry-lightest;
		}

		&.selected {
			border-color: $mulberry-lightest;
			background-color: $mulberry-lightest;
			color: $mulberry-dark;
		}
	}

	.col-appointment-type {
		flex: 1 1 calc(100% - 104px);
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: $space;

		span {
			display: block;
			flex: 1 1 100%;
			margin-bottom: $space-xs;
		}

		.btn {
			flex:0 1 49%;
			margin-bottom: $space-sm;
			font-weight: 600;
		}

		@include media-breakpoint-up(xl) {
			flex: 0 1 220px;
			margin-top: 0;
			flex-wrap: nowrap;
			flex-flow: column;
			justify-content: center;

			span {
				display: none;
			}

			.btn {
				flex: unset;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.time-only {
		border: none;
		margin-bottom: 0;

		.item-inner {
			padding: 0;
		}

		.btn-time {
			min-width: 90px;
			margin-right: calc($space * 0.6666666667);
			margin-top: 0;
			margin-bottom: $space-sm;
			font-size: $font-size-std;
		}

		.provider-times-container {
			position: relative;
			height: 100%;
		}

		.title-times-available {
			flex: 0 0 100%;
		}
	}
}

.item-booking-option-digest {
	.col-bio {
		flex: 1 1 100%;
			flex-direction: row;
			align-items: center;
		text-align: unset;
		margin: 0 0 $space-sm;

		.provider-image {
			width: $space-xxl;
			height: $space-xxl;
			margin: 0 $space 0 0;
			border: 2px solid $border-light;
		}

		.provider-content {
			flex: 1 1 auto;

			.provider-name,
			.provider-type {
				flex: 0 0 100%;
				display: block;
				line-height: 1.2;
			}

			.provider-name {
				font-size: $font-size-md;
				font-weight: 700;

				@include media-breakpoint-up(md) {
					font-size: $font-size-lg;
				}
			}

			.provider-type {
				font-size: $font-size-sm;
				color: $grey-dark;
			}
		}
	}

	.provider-times {
		align-items: center;

		& + .provider-times {
			margin-top: $space-sm;
		}

		.label-container {
			flex: 0 0 100%;

			.tip {
				display: none;
				line-height: 1em;
				margin-bottom: 0;
			}
		}

		.title-times-available {
			margin-bottom: $space-xs;
		}
	}

	.provider-name {
		text-decoration: none;
		color: $body-color;

		&:hover {
			color: $mulberry-darkest
		}
	}

	.link-provider-details {
		font-size: $font-size-xs;
		position: relative;
			top: -$space-xs;
	}

	.provider-times-container {
		margin-bottom: -$space-sm;
	}

	.btn-time {
		margin-bottom: $space-sm;
	}

	@include media-breakpoint-up(xl){
		.provider-times {
			flex-flow: row;
			flex-wrap: nowrap;

			.label-container {
				flex: 0 0 auto;
				width: auto;
				.tip {
					display: block;
				}
			}
		}

		.title-times-available {
			flex: 0 0 215px;
		}

		.provider-times-container {
			flex: 1 1 auto;
			padding-left: 0;
		}
	}
}

.item-provider-preferred {
	.item-inner {
		background: $white-off;
		border-radius: $border-radius-lg;
		padding: $space-md;
		text-align: center;
		margin-bottom: $space;
	}

	.col-bio,
	.col-content {
		flex: 0 0 100%;
	}

	.provider-image {
		overflow: hidden;
		border-radius: 999px;
		width: 100px;
		height: 100px;
		border: 2px solid $border-light;
		margin: 0 auto $space-sm;
		box-shadow: 0px 0px 10px 6px transparentize($white, 0.2);
	}

	.provider-content {
		margin-bottom: $space;
		line-height: 1.2;

		span {
			display: block;
		}
	}

	.provider-name {
		font-size: $font-size-xl;
		font-weight: 700;
	}

	.provider-type {
		font-size: $font-size-sm;
		color: $grey-dark;
	}

	.col-content {
		text-align: left;
	}

	.btn {
		font-size: $font-size-sm;
		font-weight: 600;
		margin-right: $space-sm;
		margin-bottom: $space-sm;

		&:last-child {
			margin-right: 0;
		}
	}

	.provider-times {
		& + .provider-times {
			margin-top: $space;
		}
	}

	.provider-times-container {
		margin-bottom: -$space-sm;
	}

	&.no-provider {
		.item-inner {
			color: $grey-darkest;
			padding-left: $space-lg;
			padding-right: $space-lg;
		}

		.provider-content {
			margin-bottom: $space-sm;
		}

		.col-content {
			text-align: center;
		}
	}
}

//======================================================================//
//=================== <<<<< ASSESSMENT PAGES >>>>>======================//
//======================================================================//

.item-question-assessment {
	.item-header {
		flex: 0 0 100%;
		margin-bottom: $space-sm;
	}

	.item-content {
		display: flex;
			flex-wrap: wrap;
		margin-top: $space-md;
	}

	.option {
		flex: 0 0 100%;
		padding-right: $space-sm;
		padding-bottom: $space-sm;
	}

	.btn-answer {
		display: inline-flex;
			align-items: center;
			justify-content: center;
		min-width: 130px;
		height: 100%;
		width: 100%;
		line-height: 1.2;
		padding-top: $space;
		padding-bottom: $space;
	}

	@include media-breakpoint-up(lg){
		.item-content {
			margin-top: $space-lg;
		}
	}

	// Single Column Layout
	&.single-column {
		.item-content {
			flex-flow: column;
		}

		.btn-answer {
			display: flex;
			margin: 0 auto;
		}

		@include media-breakpoint-up(sm){
			.option {
				flex: 0 0 50%;
			}
		}

		@include media-breakpoint-up(md){
			.btn-answer {
				width: 50%;
			}
		}
	}

	// Multi Column Layout
	&.multi-column {
		@include media-breakpoint-up(sm){
			.option {
				flex: 0 0 50%;
			}
		}

		@include media-breakpoint-up(md){
			.options-inline {
				flex-wrap: nowrap;
				.option {
					flex: 1 1 auto;
				}
			}

			.options-9,
			.options-7,
			.options-6,
			.options-5,
			.options-3 {
				.option {
					flex: 0 0 33%;
				}
			}

			.options-8 {
				.option {
					flex: 0 0 25%;
				}
			}

			.options-4,
			.options-2,
			.options-1 {
				.option {
					flex: 0 0 50%;
				}
			}
		}
	}
}

.collection-timeline {
	position: relative;
	margin-top: $space-lg;

	.column-headers {
		flex: 0 0 100%;
		padding-bottom: $space-xs;
		//background-color: $white;
	}

	&:before {
		content: ' ';
		display: block;
		position: absolute;
			top: 0;
			bottom: 0;
			left: calc($space-sm - 1px);
		width: 1px;
		background: $border-light;
		z-index: -1;
	}

	@include media-breakpoint-up(md){
		margin-top: $space-xl;
		.column-headers {
			display: block;
		}

		&.columns-2 {
			&:before {
				left: 50%;
				min-height: 250px;
			}
		}
	}
}

.item-mood-history {
	$icon-size: 48px; // Must be even numbers
	flex: 0 0 100%;
	border-bottom: none;
	margin-bottom: $space-lg;
	padding: 0;
	padding-left: $space-lg;
	position: relative;

	.item-inner {
		display: flex;
			flex-wrap: nowrap;
		background: $white;
		box-shadow: $shadow-dropdown;
		padding: $space $space;
		position: relative;

		&:before {
			content: ' ';
			background: $white;
			border: 1px solid $border-dark;
			border-radius: 999px;
			box-shadow: 0px 0px 0px 6px $white-off;
			display: block;
			position: absolute;
				top: 50%;
				left: calc($space-lg * -1);
			width: $space;
			height: $space;
			transform: translateY(-50%);
		}
	}

	.item-media {
		font-size: $font-size-sm;
		font-weight: 500;
		margin-right: $space;
		min-width: $icon-size;
	}

	.item-content {
		display: block;
		line-height: 1.2;
		width: 100%;
	}

	.mood-indicator {
		background: $grey-lightest;
		border-radius: 99px;
		width: $icon-size;
		height: $icon-size;
		margin: 0 auto;
		position: relative;

		&:before {
			content: '';
			display: block;
			background: $white;
			border-radius: 999px;
			position: absolute;
				top: 6px; // Must be even numbers
				left: 6px; // Must be even numbers
				right: 6px; // Must be even numbers
				bottom: 6px; // Must be even numbers
		}

		&.excellent, &.mood-0		{ background: $gradient-excellent; }
		&.good, &.mood-25			{ background: $gradient-good }
		&.getting-by, &.mood-50		{ background: $gradient-getting-by }
		&.not-good, &.mood-75		{ background: $gradient-not-good }
		&.in-crisis, &.mood-100		{ background: $gradient-in-crisis }
	}

	.mood-icon {
		width: $icon-size!important;
	}

	span {
		display: inline-block!important;
	}

	.title,
	.time {
		// flex: 0 1 auto;
		margin-right: $space-sm;
		vertical-align: bottom;
	}

	.category {
		text-transform: uppercase;
		font-weight: 600;
		font-size: $font-size-sm;
		color: $mulberry-light
	}

	.title {
		font-size: $font-size-lg;
		font-weight: 600;
	}

	.time {
		font-weight: 300;
	}

	@include media-breakpoint-up(md){
		display: flex;
		padding-left: 0;
		margin-bottom: $space-md;
		pointer-events: none;

		.item-inner {
			flex: 0 0 50%;
		}


		&.pathfinder,
		&.pulsecheck,
		&.pulse {
			padding-right: calc($space-lg + $space-sm);

			.item-inner {
				&:before {
					left: auto;
					right: -1.65em;
				}
			}
		}

		&.sentio,
		&.mood {
			justify-content: flex-end;
			padding-left: calc($space-lg + $space-sm);

			.item-inner {
				&:before {
					left: -1.6em;
				}
			}
			}
	}

	&.no-history {
		// display: none;
		pointer-events: none;
		width: 100%;

		.item-inner {
			pointer-events: all;
			flex-wrap: wrap;
		}

		.item-media {
			flex: 0 0 100%;
			text-align: center;
			margin-bottom: $space;
			display: flex;
				justify-content: center;
				align-items: center;

			img {
				margin-right: $space-sm;
				filter: grayscale(1);
			}

			.mood-indicator {
				margin-left: 0;
				margin-right: $space-sm;
				filter: grayscale(1);
			}
		}

		@include media-breakpoint-up(md){
			display: flex;
			position: absolute;

			&:hover {
				pointer-events: none;
				z-index: 2;
			}
		}
	}

	&.call-to-action {
		.item-inner {
			margin-left: auto;
			margin-right: auto;
			pointer-events: all;

			&:before {
				display: none;
			}
		}

		@include media-breakpoint-down(md) {
			.item-inner {
				&:before {
					display: block;
				}
			}
		}
	}
}

.item-mood-check {
	border-bottom: 0;
	padding: 0;

	.mood-container {
		position:relative;
		margin: $space-sm 0 0 0;

		@include media-breakpoint-up(sm) {
			margin: $space $space-lg 0 0;
		}

		input[type="radio"] {
			position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
			opacity: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;

			&:hover {
				cursor: pointer;
			}

			&:hover + label,
			&:checked + label {
				color: $mulberry;
				//color: $white;
				border-color: $mulberry-light;
				//border-color: $mulberry;
				text-decoration: underline;
				//background-color: $mulberry
			}
		}

		label {
			background-color: $white;
			position: relative;
				z-index: 1;
			width: 100%;
			height: 100%;
			display: flex;
				align-items: center;
			color: $mulberry-dark;
			border: 1px solid $border-light;
			border-radius: $border-radius;
			//font-size: $font-size-lg;
			font-weight: 400;
			overflow: hidden;
			padding: $space-xs $space-sm;
			transition-duration: 0.2s;
			word-break: break-all;
			line-height: 1.2;
			font-size: $font-size-md;


			.icon {
				margin-right: $space;
			}
		}

		.mood-icon {
			$icon-size: 48px; // Must be even numbers
			width: $icon-size;
			margin-top: $space-sm;
			margin-bottom: $space-sm;
			margin-left: $space-sm;
		}
	}
}

.item-take-assessment-cta {
	border-bottom: 0;
	padding: 0;

	.item-inner {
		background: $yellow-lightest;
		border: 1px solid $grey-lightest;
		border-radius: $border-radius-lg;
		display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
		padding: $space-md;

		p {
			flex: 0 0 100%;
		}
	}
}

.item-in-person-address-option {
	padding: 0;
	border: none;

	.item-inner {
		border: 1px solid $border-light;
		border-radius: $border-radius;
		// background: $white;
		// box-shadow: $shadow-dropdown;
		overflow: hidden;
		flex-wrap: nowrap;
	}

	&.card {
		.item-inner {
			border-radius: 0;
			box-shadow: $shadow-dropdown;
		}
	}

	.item-media {
		flex: 0 0 auto;
		position: relative;
		padding: $space $space-sm;
		border-right: 1px solid $border-light;

		.icon-container {
			background: $white;
			box-shadow: $shadow-dropdown;
			padding: $space;
			border-radius: 999px;
			width: $space-xl;
			height: $space-xl;
			display: flex;
				justify-content: center;
				align-items: center;
			position: relative;
				z-index: 2;
		}

		i {
			font-size: $font-size-md;
			color: $mulberry-dark;
		}

		.background {
			position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			background-size: cover;
			background-position: center center;
			z-index: 0;
			opacity: 0.8;

			&:after {
				content: '';
				display: block;
				background: transparentize($white, 0.7);
				backdrop-filter: blur(1px);
				position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
			}
		}
	}

	.item-content {
		flex: 1 1 auto;
		padding: $space;
	}

	.accordion-header {
		border-bottom: none!important;
		button {
			font-weight: 600;
			padding-top: $space-sm;
			padding-bottom: $space-sm;
			padding-left: $space-xl;
			text-decoration: underline;
			background: $white-off;
			border: none!important;

			&:hover {
				color: $link-hover-color
			}

			&::after {
				margin-left: 0;
				position: absolute;
					left: calc( $space-sm + $space-xs);
			}
		}
	}

	.accordion-body {
		font-size: $font-size-sm;
		padding-left: $space-sm;
		padding-right: $space-sm;
		line-height: 1.3;

		p {
			margin-bottom: $space-sm;
		}
	}

	@include media-breakpoint-up(md){
		.item-media {
			padding: $space;
	
			.icon-container {
				width: $space-xxl;
				height: $space-xxl;
			}
	
			i {
				font-size: $font-size-lg;
			}
		}
		
		.item-content {
			padding: $space-md;
		}

		.accordion-body {
			padding-left: $space;
			padding-right: $space;
		}
	}

	@include media-breakpoint-up(lg){
		.item-content {
			padding: $space-md $space-lg;
		}

		.accordion-body {
			font-size: $font-size-std;
			padding-left: $space;
			padding-right: $space;
		}
	}
}

.item-provider-info {
	.wrapper {
		border: 1px solid $border-light;
		border-radius: $border-radius;
		background: $white;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: $space-lg;

		& >.col-12.col-md.d-flex.g-0 {
			padding: $space-sm $space;
		}
	}

	&.card {
		background-color: transparent!important;
		border: none;
		border-radius: 0;
		margin-bottom: 0!important;

		.wrapper {
			background: $white;
			border-radius: 0;
			box-shadow: $shadow-dropdown;
			margin-bottom: 0;
		}
	}

	//.col-provider-image {
	//	padding: $space $space-sm $space-sm 0;
	//	padding-right: 0;
	//}

	.col-bio {
		padding: 0 $space-md;
		padding-right: 0;
		flex: 1 1 auto;
	}

	.provider-image {
		overflow: hidden;
		border-radius: 999px;
		width: 70px;
		height: 70px;
		border: 2px solid $border-light;
		margin-top: $space-md;

		picture {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: none;
		}
	}

	.provider-name {
		font-size: $font-size-xl;
		font-weight: 700;
	}

	.provider-bio-content {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;

		&.expanded {
			-webkit-line-clamp: unset;
		}
	}

	.btn-bio-link {
		font-size: $font-size-sm;
		padding: 0;
		margin: 0;
		text-decoration: none;

		border-bottom: 1px solid $grey-dark;
		border-radius: 0;

		i {
			font-size: $font-size-xxs;
		}
	}

	.col-service-selection {
		border: solid $border-light;
		border-width: 1px 0 0;
		padding: $space-md $space-lg;
		margin-top: $space;
	}

	p {
		margin-bottom: $space-xs;
	}

	p:not(.provider-name, .provider-lock p),
	a {
		font-size: $font-size-sm;
	}

	// Styles specific to provider section that does not include a bio.
	.no-bio {
		align-items: center;
		padding: $space-sm $space;

		.col-provider-image {
			padding: 0;
		}

		.provider-image {
			width: 60px;
			height: 60px;
			margin-top: 0;
		}

		.col-bio {
			display: flex;
			flex-flow: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			padding-top: 0;
			padding-bottom: 0;
			padding-right: 0;

			& > .row {
				order: 1;
			}
		}

		.provider-name {
			line-height: 1;
			margin-right: $space;
			margin-top: $space-xs;
		}

		.provider-lock {
			margin-top: calc( $space * 0.6666666667);
		}
	}

	@include media-breakpoint-up(md){
		.col-provider-image {
			padding: $space $space-sm;
		}

		.col-bio {
			padding: 0 $space-sm;
		}

		.col-service-selection {
			border-width: 0 0 0 1px;
			margin-top: 0;
		}
	}

	@include media-breakpoint-up(lg){
		padding: 0 $space-xxl;

		&.card {
			padding: 0;
		}

		.wrapper {
			margin-bottom: 0;
		}

		.no-bio {
			.provider-name {
				margin-top: unset;
			}
		}
	}

	@include media-breakpoint-up(xl){
		.col-provider-image {
			padding-top: 0;
		}

		.col-bio {
			padding: 0 $space-md;
		}

		.provider-image {
			width: 85px;
			height: 85px;
		}
	}

}