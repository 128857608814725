//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _pages.scss
// $Purpose: Styles relating to specific pages
//

.container-page {
	margin-top: $space-xxl;
	margin-bottom: $space-xxl;

	&:has(.homeweb-header-secondary) {
		margin-top: 0;
	}

	&:has(.section-steps) {
		margin-bottom: 0;
	}
}

//======================================================================//
//====================== <<<<< System Pages >>>>> ======================//
//======================================================================//

// Page SSO Welcome
.page-sso-welcome {
	display: flex;
	flex-flow: column;
	min-height: 100vh;

	#content {
		height:100%;
		flex: 1 1 100%;
		display: flex;
		align-items:center;
	}

	.section-sso-welcome {
		padding-top: $space-xxxl;
		padding-bottom: $space-xxxl;
	}

	.row-media {
		margin-bottom: $space-lg;

		> div {
			display: flex;
			align-items: center;
		}
	}

	.logo {
		max-width: 375px;
		padding: 0 $space-xs;


		@include media-breakpoint-up(md) {
			padding: 0 $space;

		}
	}

	.logo-single {
		margin: 0 auto;
	}

	.theme-col {
		border-left: 1px solid $border-color;
	}

	.footer {
		margin-top: auto;
	}
}

.page-style-guide {
	h2, h3, h4, h5, h6 {
		color: $grey-dark;
	}
}

.page-error {
	.media {
		.compass-image {
			max-width: 60%;
			height: auto;

			@include media-breakpoint-down(lg) {
				max-width: 280px;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		ul > li {
			text-align: left;
		}
	}

	.actions {
		@include media-breakpoint-down(sm) {
			display: flex;
			flex-direction: column;
		}
	}

	.container {
		@include media-breakpoint-up(sm) {
			&.width-narrow {
				max-width: 800px;
			}
		}
	}
}

/**
 * Modules - TODO:JON20240130 - Refactor how the module JS imports styles so that this can be moved to editor.scss
 */
.module {
	padding: $space;

	.output-template,
	.placeholder-template {
		display: none;
	}
}

.page-welcome {
	background-color: $white;
	background-image: url('#{$url-cdn-images}/backgrounds/login-bg.png');
	background-position: top right;
	background-repeat: no-repeat;

	.section {
		margin: $space-xxl 0;
	}

	.sub-title {
		font-weight: 300;
		color: $grey-dark;
		margin-bottom: $space-sm;
	}
}

//======================================================================//
//====================== <<<<< Onboarding >>>>> ========================//
//======================================================================//

.page-onboarding {
	#content {
		position: relative;
		overflow: auto;
		display: flex;
		justify-content: center;
	}

	.container-page {
		margin: $space-xxl 0 0;
		display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1 1 100%;
		position: relative;
		min-height: 100%;
	}

	.section-layout-body {
		flex: 1 1 auto;
	}

	.section-steps {
		background: $grey-ultralight;
		padding: $space-md 0 $space-lg 0;
	}

	.nav-onboarding {
		margin-top: $space-xxxl;
		margin-bottom: $space-xxl;
		text-align: center;
	}

	@include media-breakpoint-up(md){
		.section-steps {
			padding: $space-xl 0 $space-xxl 0;
		}
	}
}

//======================================================================//
//====================== <<<<< Activities >>>>> ========================//
//======================================================================//

// Exercises - Interactive Activities
.container-activity-interactive {
	margin-top: 0!important;
	margin-bottom: 0!important;

	.container-activity-header {
		background: $primary url('#{$url-cdn-images}/backgrounds/brand-bg-mulberry-vertical.png') top right no-repeat;
		background-size: cover;
		color: $white;
		padding-top: $space-xl;
		padding-bottom: $space-lg;

		@include media-breakpoint-up(md){
			background-size: 60% auto;
		}

		@include media-breakpoint-up(xl){
			background-size: 40% auto;
		}
	}

	.section-questions-answers {
		label {
			font-weight: 400;
		}
	}

	.container-question {
		&>.container {
			&:first-child {
				padding-top: $space-lg;
				padding-bottom: $space-md;
			}
		}
	}

	.question-title {
		font-size: $font-size-lg;
		margin-bottom: $space-sm;
	}

	.question-example {
		background-color: $white-off;
		margin-top: $space;
		padding-top: $space;
		padding-bottom: $space;
		// border-bottom: 15px solid $primary
	}

	.question-input {
		.form-check {
			margin-bottom: $space-sm;

			&:last-child {
				margin-bottom: 0;
			}
		}

		label {
			font-size: $font-size-md;
		}
	}

	.nav-activity-interactive {
		background-color: $yellow-light;
		border-top: 2px solid $border-light;
		padding-top: $space;
		padding-bottom: $space;

		.container-buttons {
			display: flex;
			justify-content: flex-end;
			position: relative;

			&:has(.btn-previous){
				justify-content:space-between;
			}
		}

		p {
			font-size: $font-size-sm;
			margin: 0 auto 3px!important;
		}
	}

	@include media-breakpoint-up(lg){
		.question-title {
			font-size: $font-size-lg
		}
	}
}

// Resources - Static Activities


//======================================================================//
//========================= <<<<< Preview >>>>> ========================//
//======================================================================//
.container-page-sidebar {
	position:relative;

	@include media-breakpoint-up(md) {
		border-top: $space-xs solid $mulberry-white;
		border-left: $space-xs solid $mulberry-white;
		border-right: calc($space*0.6666666667) solid $mulberry-white;
	}

	.preview-summary,
	.preview-elective-material,
	.course-item .item-content {
		padding: $space-lg;
	}

	.preview-title {
		position: sticky;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.preview-header {
		width: 100%;
		height: 140px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.preview-summary {
		.pre-title {
			margin-bottom: 0;
			display: block;
			font-size: $font-size-sm;
			color: $grey-dark;
		}

		.title {
			display: block;
			font-family: $font-foco;
			font-size: calc($font-size-std*1.7777777778);
			line-height: 1em;
			margin-bottom: $space-md;
		}

		a {
			text-decoration: none;

			i {
				font-size: $font-size-xs;
			}

			.fa-circle-play {
				margin-right: $space-xs;
			}

			.fa-chevron-right {
				margin-left: $space-xs;
			}
		}
	}

	.preview-meta {
		margin-top: $space-xl;

		.meta-title {
			font-weight: 700;
			font-size: $font-size-lg;
			margin-bottom: 0;
			line-height: 1em;
		}

		.meta {
			font-size: $font-size-xs;
			color: $grey-darkest;
		}
	}

	.estimated-time {
		color: $grey-dark;
		font-size: $font-size-xs;
		line-height: 1.1em;
		min-width: 45px;
	}

	.accordion-flush {
		border-top: 1px solid #dee2e6;
	}

	.accordion-body {
		padding: 0;

		.course-item {
			background-color: $white;
		}
	}

	.accordion-button {

		align-items: flex-start;
		padding-left: $space-lg;
		padding-top: calc($space*1.4444444444);
		padding-bottom: calc($space*1.4444444444);

		&.locked {
			background-color: $white-off;
		}

		&.collapsed {
			.title {
				color: $grey-dark;
			}
		}

		.button-header {
			margin-right: auto;
		}

		.fa-lock,
		.pre-title,
		.item-count,
		.fa-circle-check {
			font-size: $font-size-xs;
		}

		.fa-lock,
		.fa-circle-check {
			color: $grey-light;
			margin-right: $space-sm;
			margin-top: 2px;
		}

		.pre-title {
			color: $black;
		}

		.item-count {
			margin-left: $space-sm;
			color: $grey-dark;
		}

		.title {
			display: block;
			color: $mulberry;
			font-size: $font-size-lg;
			font-weight: 700;
			padding-right: $space-sm;
		}

		&:after {
			color: $mulberry;
			background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
			background-color: transparent;
			background-size: cover;
			mask-image: unset;
			-webkit-mask-image: unset;
			background-repeat: no-repeat;
			background-position: center;
			width: calc($space*0.6666666667);
			height: calc($space*0.6666666667);
			margin-left: $space-sm;
		}

		.estimated-time {
			margin-right: $space-xs;
		}
	}

	.course-item {
		background-color: $white-off;
		border-bottom: 2px solid $grey-lightest;

		&.active {
			background-color: $white;

			.item-title {
				color: $mulberry-light;
			}
		}

		&.locked {
			.fa-lock {
				color: $grey-light;
				margin-right: $space-sm;
				margin-top: 2px;
			}

			.item-title {
				margin-right: auto;
			}
		}

		&:first-child {
			border-top: 2px solid $grey;
		}

		&:last-child {
			border-bottom: 0;
		}

		.item-header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding-top: $space-lg;
			padding-left: $space-lg;
			padding-right: $space;

			.fa-lock {
				margin-right: $space-sm;
			}
		}

		.item-title {
			line-height: 1.2em;
			font-size: $font-size-md;
			color: $grey-darkest;
			font-weight: 600;
			margin-right: $space-sm;
		}

		.item-meta {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.format-icon {
				height: calc($space*1.3333333333);
				width: auto;
			}
		}

		.item-content {
			padding-top: $space-sm;

			span {
				display: block;

			}
		}

		p {
			font-size: $font-size-sm;
			color: $grey-darkest;
		}

		.btn {
			font-size: $font-size-sm;

			i {
				font-size: $font-size-xxs;
			}
		}

		&.locked {
			.fa-lock {
				color: $grey-light;
			}

			.item-title {
				color:$grey-medium;
			}
			.btn {
				@extend .disabled;
			}
		}
	}

	.preview-elective-material {
		background-color: $white;
		text-align: center;
		font-size: $font-size-lg;
		line-height: 1.1em;

		p {
			font-weight: 300;
			font-style: italic;
			margin-bottom: calc($space*2);
		}

		span {
			font-weight: 800;
		}
	}

	.preview-helper-content {
		background-color: $grey-lightest;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		position: sticky;
		bottom: 0;
		padding: $space 0;
		text-align: center;
		z-index: 10;

		@include media-breakpoint-up(md) {
			display: none;
		}
	}
}

.preview-title,
.container-page-dynamic .preview-pill {
		height: $preview-pill;
		width: 100%;
		background-color: $mulberry-darkest;
		color: $white;
		z-index: 10;
		text-align: center;
		display: block;
}

.container-page-dynamic {
	.preview-pill {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		//position: sticky;
		top:0;
		left: 0;
		right: 0;
	}
}

 // Add a margin to offset the preview pill overlap
.container-page-dynamic:has(> .preview-pill) > :nth-child(2) {
	margin-top: $preview-pill;
}

// Preview Footer
.container-preview-footer {
	background-color: $white-off;
	margin-top: $space-xxl;
	padding: $space-xxl $space-lg;

	.preview-nav {
		width: 100%;
		margin: 0 auto;

		display: flex;
			align-items: center;
			justify-content: space-between;

		.btn-primary {
			width: 100%;
			max-width: 170px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: $space-lg;
			padding-right: $space-lg;

			.fa-solid {
				font-size: $font-size-xs;
			}

			.fa-chevron-right {
				margin-left: $space;
			}

			.fa-chevron-left {
				margin-right: $space;
			}

		}

		@include media-breakpoint-up(md) {
			max-width: 870px;

		}

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;

			.container-next-activity {
				margin:0 0 $space;
				flex:1 1 100%;
				order: 1;
			}

			.btn-previous,
			.btn-next {
				max-width: 49%;
				flex: 1 1 50%;
				padding-left: $space;
				padding-right: $space;
			}

			.btn-previous {
				order: 2;
			}

			.btn-next {
				order: 3;
			}
		}
	}

	.container-next-activity {
		flex: 0 1 366px;
		margin: 0 $space;

		.title {
			font-size: $font-size-lg;
			font-weight: 700;
		}
	}

	.activity {
		width: 100%;
		padding: $space;
		background-color: $white;
		border: 2px solid $green;
		border-radius: $space;
		display: flex;

		.col-image {
			flex: 0 0 65px;
			width: 65px;
			height: 75px;
			background-color: $grey-ultralight;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: $border-radius;
		}

		.col-content {
			margin-left: $space;
		}

		.activity-title {
			font-weight: 600;
			color: $mulberry;
		}

		.estimated-time,
		.type {
			color: $grey-dark;
			font-size: $font-size-xs;
			line-height: 1.1em;
		}

		.type {
			color: $yellow-dark;
		}

		p {
			font-size: $font-size-sm;
			margin-bottom: 0;
		}

		.activity-meta {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

//======================================================================//
//====================== <<<<< Profile Page >>>>> ======================//
//======================================================================//

.page-profile {
	.column-sidebar,
	.mobile-nav {
		.nav-item {
			width: 100%;

			.nav-link {
				width: 100%;
				border-radius: $border-radius-xs;
				transition-duration: 0.3s;
				color: $grey-dark;

				i {
					padding-right: $space-sm;
				}

				&:hover {
					color: $charcoal;
					background-color: $grey-ultralight;
				}

				&.active {
					color: $mulberry;
				}
			}
		}
	}

	.column-sidebar {
		.nav {
			.nav-item {
				padding-top: $space-xs;
				padding-bottom: $space-xs;
				.nav-link {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					@include media-breakpoint-between(lg,xl) {
						padding-left: 0;
					}
				}
			}
		}
	}

	.column-personal-info,
	.column-security {
		@include media-breakpoint-between(lg,xl) {
			padding-left: $space-lg;
		}
		@include media-breakpoint-between (xl, xxl) {
			padding-left: $space-xxxl;
		}
		@include media-breakpoint-up (xxl) {
			padding-left: calc($space * 6);
		}
	}


	.btn-nav-collapse {
		display: flex;
		align-items: center;
		font-size: $font-size-sm;
		font-weight: 600;
		text-align: left;
		text-transform: uppercase;
		margin-bottom: $space-md;

		i {
			font-size: $font-size-sm;
			margin-left: auto;
			transition-duration: 0.3s;
		}

		&[aria-expanded="false"] {
			margin-bottom: 0;
		}

		&.collapsed {
			i {
				transform:rotate(180deg);
			}
		}

		@include media-breakpoint-up(lg) {
			display:none;
		}
	}

	.mobile-nav {
		@include media-breakpoint-up(lg) {
			display: block;
		}

		ul {
			padding: 0;

			.nav-item {
				list-style: none;
				margin-bottom: $space-xs;

				.nav-link {
					display: flex;
					align-items: center;
					padding: $space-xs $space;

					i {
						font-size: $font-size-sm;
					}
				}
			}

			@include media-breakpoint-down(lg) {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.fade {
		display: none;
	}

	.show {
		display: block;
	}

	.container-addresses {
		display: flex;
		.card {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;

			.card-body {
				.section-title {
					font-weight: 600;
				}
				.row-space-top {
					padding-top: $space-sm;
				}
			}

			.card-content {
				flex-grow: 1;
			}
			.card-footer {
				display: flex;
				justify-content: start;
				align-items: center;
				background-color: $white;
				padding-top: $space;
				padding-bottom: $space;

				.card-button {
					color: $mulberry;

					&:not(:last-child) {
						margin-right: $space-lg;
					}

					i {
						padding-right: $space-xs;
					}
				}
			}
		}
	}

	.column-security {
		.input-container {
			position: relative;
			button {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}

	.profile-image {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		margin-right: $space-sm;
		overflow: hidden;

		picture {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: none;
		}
	}
}

//======================================================================//
//============ <<<<< Service Recommendation >>>>> ======================//
//======================================================================//

.container-homeweb-service-recommendation {
	margin-bottom: 0;

	.container-dsg {
		min-height: initial;
	}

	.container {
		@include media-breakpoint-down(md) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.section-header {
		display: flex;

		h1 {
			@include media-breakpoint-between(md, lg) {
				padding-right: $space-sm;
			}
		}

		.column-logo {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include media-breakpoint-down(md) {
				justify-content: center;
			}

			img {
				width: 100%;
			}
		}

		.pathfinder-logo {
			max-width: 250px;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
			gap: $space;
			padding-left: $space-md;
			padding-right: $space-md;
		}
	}

	.row-recommendation-tiles {
		.item {
			margin-top: $space-xl;
			.item-inner,
			.item-inner::before {
				@include media-breakpoint-down(sm)
				{
					border-radius: 0;
					padding-left: 0;
					padding-right: 0;
				}
			}

			.item-inner {

				.btn {
					margin-top: $space;
					width: fit-content;

					i {
						margin-left: $space;
					}
				}
			}

			@include media-breakpoint-down(lg) {
				margin-top: $space-md;
			}
		}
	}

	.row-additional-resources {
		margin-top: $space-xxl;
		margin-bottom: $space-xl;

		.item-resource-list {
			.item-inner,
			.item-resource {
				border: none;
			}

			ul {
				flex-direction: row;
				justify-content: start;

				li {
					.row {
						padding-left: 0;
						padding-right: 18px;

						@include media-breakpoint-between(sm, lg) {
							flex-direction: column;
						}

						@include media-breakpoint-between (sm, md) {
							justify-content: flex-start;
						}

						@include media-breakpoint-down(sm) {
							justify-content: center;
						}

					}
				}
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}
			}

			.item-content {
				display: flex;
				flex-direction: row;

				.item-heading {
					align-self: center;
				}

				.item-resource {
					padding-bottom: $space;
					padding-top: $space;

					@include media-breakpoint-up(xl) {
						.row {
							justify-content: flex-start;
						}
						max-width: 300px;

					}

					.column-text {
						@include media-breakpoint-up(lg) {
							max-width: 215px;
						}
						@include media-breakpoint-between(sm, xl) {
							text-align: center;
						}
					}

					.column-icon {
						align-self: center;
					}

					//&:hover {
					//	background-color: $mulberry-white;
					//}
				}

				@include media-breakpoint-down(lg) {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}
	}

	&.container-pathfinder-service-recommendation {
		.row-recommendation-tiles {
			margin-bottom: $space-xl;

			.item {
				&.item-service-recommendation-list,
				&.item-service-recommendation-pathfinder {
					min-width: unset;
				}

				&.item-service-recommendation-pathfinder {
					.pathfinder-inner {
						.pathfinder-text {
							flex: unset;
							margin-bottom: $space-sm;

							&.header {
								@extend .h5;
								text-transform: capitalize;
								font-weight: 600;
								margin-bottom: $space-md;
							}

							&.footer {
								//margin-top: auto;
							}
						}
					}

					.btn {
						margin-top: auto;
					}
				}

				&.item-service-recommendation-list {
					.item-inner {
						padding-top: $space-sm;
						padding-bottom: $space-sm;
					}
				}
			}
		}

		.row-additional-resources {
			.item-resource-list {
				ul {

					@include media-breakpoint-up(sm) {
						flex-wrap: wrap;
					}

					.item-resource {
						width: unset;
						max-width: unset;
						flex: 1 1 100%;

						@include media-breakpoint-up(sm) {
							flex: 0 1 50%;
						}

						@include media-breakpoint-up(lg) {
							flex: 0 1 25%;
						}
					}
				}

				.item-content {
					.item-resource {
						.row {
							@include media-breakpoint-up(xl) {
								flex-flow: row;
							}
						}
					}
				}
			}
		}

		.section-no-recommendations {
			max-width: 80%;
			margin: $space-xxl auto;
		}
	}

	.section-about-pathfinder {
		display: flex;
		align-items: center;
		background-color: $mulberry-white;
		padding: $space-xxxl $space-xl;

		@include media-breakpoint-up(xl) {
			padding-top: 0;
			padding-bottom: 0;
			min-height: 290px;
		}


		.row {
			@include media-breakpoint-down(lg) {
				flex-direction: column;
				align-items: center;
			}
		}

		.pathfinder-logo {
			max-width: 380px;
			max-height: 100%;

			@include media-breakpoint-down(sm) {
				max-width: 250px;
			}
		}

		a {
			margin-top: 0;
			text-decoration: none;

			// &:not(:hover) {}
		}

		.column-text {
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include media-breakpoint-down(sm) {
				text-align: center;
			}
			p {
				color: $grey-dark;
				margin-bottom: $space-xs;
				@include media-breakpoint-up(xl){
					max-width: 610px;
				}
			}
		}

		.column-image {
			display: flex;
			justify-content: center;
		}

		.column-text {
			@include media-breakpoint-down(xl) {
				margin-top: $space;
			}
		}
	}
}

.page-homeweb-profile {
	.profile-image {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		margin: 0;
		overflow: hidden;
		border: 2px solid $border-color;

		picture {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: none;
		}
	}

	.column-profile-header {
		line-height: 1.2;
	}

	.profile-name {
		font-weight: 600;
		margin-top: $space-sm;
		margin-bottom: 0;
	}

	.profile-role {
		// color: $grey-dark;
		font-size: $font-size-sm;
	}

	.form-profile {
		background: $white;
		border: 1px solid $border-light;
		box-shadow: $shadow-dropdown;
		padding: $space-md;
		margin-bottom: $space;
	}

	.form-check-label {
		color: $body-color
	}

	.column-profile-body {
		display: flex;
			flex-wrap: wrap;
		margin-top: $space-md;
	}

	.profile-details {
		padding-left: $space-md;
	}

	.item-key-value {
		line-height: 1.3;
		margin-bottom: $space-sm;

		span {
			display: block;
		}

		.label {
			color: $grey-dark;
			font-size: $font-size-xs;
			// font-weight: 600;
		}

		.text {
			
		}
	}
}

//======================================================================//
//====================== <<<<< HOMEWEB  >>>>> ==========================//
//=========== <<<<< ARTICLE DIGEST, DETAIL AND SEARCH >>>>> ============//
//======================================================================//

// ARTICLE DIGEST AND SEARCH
.container-homeweb-category,
.container-homeweb-search {

	.homeweb-header-secondary {
		margin-bottom: $space;

		@include media-breakpoint-up(lg) {
			margin-bottom: $space-xs;
		}
	}

	h1,
	.sidebar-title {
		font-size: $font-size-sm;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: $space;
		line-height: 1.2;
	}

	.sidebar-title {
		display: none;
		color: $mulberry-light;

		@include media-breakpoint-up(lg) {
			margin-left: $space;
			display: block;
		}
	}

	.article-featured {
		a {
			text-decoration: none;

			&:hover {
				.title {
					color: $primary;
					text-decoration: underline;
				}
			}
		}

		.article-image {
			width: 100%;
			min-height: 250px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			margin-bottom: $space-lg;
		}

		.title {
			display: inline-block;
			font-size: $font-size-xxl;
			font-weight: 600;
			line-height: 1.1;
			margin-bottom: $space-sm;
			color: $black;
		}

		p {
			color: $black;
			line-height: 1.3;
		}

		.resource-duration {
			&:hover {
				background-color: darken($success, 5%);
			}
		}

		@include media-breakpoint-up(sm){
			.article-image {
				min-height: 330px;
			}
		}

		@include media-breakpoint-up(md){
			.article-image {
				min-height: 430px;
			}

			.title {
				margin-bottom: $space-xs;
			}
		}

		@include media-breakpoint-up(lg){
			.article-image {
				min-height: 530px;
			}
		}
	}

	.col-category-sidebar {
		margin-bottom: $space-lg;

		@include media-breakpoint-up(lg){
			margin-bottom: 0;
		}
	}

	.btn-nav-collapse {
		display: flex;
			align-items: center;
		font-size: $font-size-sm;
		font-weight: 600;
		text-align: left;
		text-transform: uppercase;
		margin-bottom: $space-md;

		i {
			font-size: $font-size-sm;
			margin-left: auto;
			transition-duration: 0.2s;
		}

		&[aria-expanded="false"] {
			margin-bottom: $space-xs;
		}

		&.collapsed {
			i {
				transform:rotate(180deg);
			}
		}

		@include media-breakpoint-up(lg) {
			display:none;
		}
	}

	.category-nav {
		@include media-breakpoint-up(lg) {
			display: block;
		}

		i { 
			font-size: $font-size-sm;
		}

		ul {
			padding: 0;

			li {
				list-style: none;
				margin-bottom: $space-xs;

				&.active {
					> a {
						color: $mulberry-dark;
						background-color: $yellow-lightest;

						.fa-chevron-right {
							transform-origin: center;
							transform: rotate(90deg);
						}

						&:hover {
							color: $mulberry-dark;
							background-color: $yellow-lightest;
						}
					}
				}

				&:has(.child-nav > .active) {
					.fa-chevron-right {
						transform-origin: center;
						transform: rotate(90deg);
					}
				}

				a {
					display: flex;
					align-items: center;
					text-decoration: none;
					border-radius: $border-radius-xs;
					padding: $space-xs $space;
					transition-duration: 0.3s;

					.fa-chevron-right {
						margin-left: auto;
					}

					&:hover {
						color: $charcoal;
						background-color: $grey-ultralight;
					}
				}
			}
		}

		.child-nav {
			padding-left: $space;
		}
	}

	.form-search {
		&.form-search-offset {
			.category-filters {
				margin-top: 0;
			}
		}
	}

	.category-filters {
		margin: $space-xxl 0 0;
		padding-bottom: $space-lg;
		border-bottom: 1px solid $border-color;

		.resource-count {
			line-height: 1.2;
			font-weight: 600;

			.count {
				font-weight: 600;
			}
		}

		@include media-breakpoint-up(lg) {
			.resource-count {
				font-weight: inherit
			}
		}
	}

	.filter-section {
		display: flex;
		margin-top: $space-xs;

		@include media-breakpoint-up(lg) {
			margin-top: 0;
			justify-content: flex-end;
		}

		form {
			display: flex;
			flex-flow: row;
			width: 100%;
		}
	}

	.filter {
		display: flex;
			flex-flow: row;
			align-items: center;
			flex-wrap: wrap;
		flex: 0 0 50%;

		.form-label {
			font-weight: normal;
			margin: 0 $space-sm 0 0;
			flex: 0 0 100%;
		}

		.form-select {
			margin-right: $space;
		}

		&:last-child {
			.form-select {
				margin-right: 0;
			}
		}

		@include media-breakpoint-up(xl){
			flex-wrap: nowrap;

			.form-label {
				flex: 0 0 auto;
				margin: 0 $space-sm;
			}
		}
	}

	.section-search {
		input {
			font-size: $font-size-xxl;
			border: none;
			border-bottom: 1px solid $border-color;
			border-radius: 0;
		}

		button {
			border: none;
			border-bottom: 1px solid $border-color;
			background-color: transparent;
			border-radius: 0;
			color: $primary;

			i {
				font-size: $font-size-xl;
			}
		}
	}
}

.container-homeweb-search {
	h1 {
		font-weight: normal;
		text-transform: unset;
		color: $grey-dark;
	}
}

// RESOURCE DETAIL
.container-homeweb-resource {
	@include media-breakpoint-down(md){
		.container-article {
			.row {
				padding-left: $space-sm;
				padding-right: $space-sm;
			}
		}
	}

	.container-article {
		margin-top: $space-lg;

		.resource-subtitle {
			color: $mulberry-light;
			font-weight: 300;
			margin-top: 0;
		}

		.resource-title {
			margin-top: 0;
			font-weight: 600;
		}

		.resource-summary {
			font-weight: 300;
		}

		.resource-title,
		.resource-summary {
			margin-bottom: $space-xl;
		}

		@include media-breakpoint-up(md){
			margin-top: $space-xxl;
		}

		article {
			p + h1,
			p + h2,
			p + h3,
			p + h4,
			p + h5,
			p + h6,
			ol + h1,
			ol + h2,
			ol + h3,
			ol + h4,
			ol + h5,
			ol + h6,
			ul + h1,
			ul + h2,
			ul + h3,
			ul + h4,
			ul + h5,
			ul + h6{
				&:not(.resource-title) {
					margin-top: $space-xxl;
				}
			}
		}

		.mediresource-content {
			margin-top: $space-lg;

			p {
				font-weight: 300;
			}
		}

		.sidebar {
			margin-bottom: $space-xl;
		}
	}

	.banner.banner-hero {
		&.banner-hero-resource {
			display: block;
			padding: 0;

			.banner-background {
				background-position: 50%;
				position: relative;
				width: 100%;
				height: 200px;
			}

			.banner-inner {
				flex: 1 1 100%;

				@include media-breakpoint-up(lg) {
					padding-left: $space;
				}
			}

			.banner-content {
				padding: $space-xxl $space-lg;
				background-color: $mulberry-dark;
			}

			h1 {
				font-size: 3.5rem!important;
				line-height: 3.75rem;
				letter-spacing: -.75px;
			}

			@include media-breakpoint-down(md) {
				padding-bottom: 0!important;

				.banner-content {
					padding: $space-lg;
				}

				h1 {
					font-size: $font-size-xxl!important;
					line-height: 1.15;
				}
			}

			@include media-breakpoint-up(md) {
				height: 500px;
			}

			@include media-breakpoint-up(sm) {
				display: flex;
				align-items: flex-end;

				.banner-background {
					width: unset;
					height: unset;
					position: absolute;
				}

				.banner-content {
					background-color: transparentize($mulberry-darkest, 0.2);
				}
			}
		}
	}

	.podcast-player {
		width: 100%;
		margin-bottom: $space-xl;
	}

	.sidebar {
		@include media-breakpoint-down(lg) {
			border-top: 1px solid $border-color;
			margin-left: 0;
			margin-top: $space-xl;
			padding-top: $space-xl;
		}

		.title {
			color: $mulberry-light;
			font-size: $font-size-sm;
			font-weight: 600;
			display: block;
			margin-bottom: $space-sm;
			text-transform: uppercase;
		}

		.resource-duration {
			&:hover {
				background-color: darken($success, 5%);
			}
		}
	}

	.sidebar-section {
		margin-bottom: $space-xl;
		overflow: hidden;

		&:last-child {
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
			}
		}
	}

	.sidebar-helper-rating {
		.btn {
			i {
				font-size: $font-size-xs;
			}
		}
	}

	.topics {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			float:left;
			margin-right: $space-xs;
			margin-bottom: $space-xs;

			a {
				margin-top: 0;
			}

			&:last-child {
				margin-right: 0;
				margin-bottom: 0;
			}
		}
	}

	.pathfinder-cta {
		display: block;
		overflow: hidden;
		border: 1px solid $border-color;
		border-radius: $border-radius;
		background-color: $white;
		padding: $space-sm $space-md $space-md;
		text-decoration: none;
		text-align: center;
		transition-duration: 0.3s;

		.title {
			font-size: $font-size-sm;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		&:hover {
			background-color: $grey-ultralight;
		}
	}

	.section-gate,
	.section-certificate,
	.section-cbt-gate {
		margin: $space-xl 0;
	}

	blockquote {
		font-weight: 400;
		margin-bottom: calc($space * 3);
		margin-top: calc($space * 3);
		color: $grey-dark;
		em {
			font-size: calc($font-size-std * 1.667);
			line-height: calc($space * 2.11);
		}

		&:before,
		&:after {
			font-size: calc($font-size-std * 2.225);
			vertical-align: middle;
			line-height: calc($space * 1.5);
		}

		&:before {
			content: open-quote;
			padding-right: $space-xs;
		}
		&:after {
			content: close-quote;
			padding-left: $space-xs;
		}
	}
}

//======================================================================//
//====================== <<<<< Booking Pages >>>>> =====================//
//======================================================================//
// BOOKING DIGEST
.page-homeweb-booking-digest {
	background-color: $white;

	#content { 
        overflow: unset; 
    }

	.section-selected-filters {
		display: flex;
			flex-wrap: wrap;
		border: solid $border-light;
		border-width: 1px 0;
		margin: $space-sm auto;
		padding: $space 0  $space-sm;
		
		.btn { 
			margin-right: $space-sm; 
			margin-bottom: $space-sm;

			.icon {
				font-size: $font-size-xs!important;
			}
		}
	}

	.section-suggestions {
		padding: $space $space-md;
		background: $yellow-lightest;
		border-radius: $border-radius-lg;
		margin-top: $space;
	}

	.sticky-container {
		@include media-breakpoint-up(lg) {       
			position: sticky;       
			top: $space;    
		}
	}

	.col-provider-list {
		.column-header div:last-child {
			display: flex;
				flex-direction: column;
				justify-content: center;
		}

		.btn-clear-filters {
			display: block;
		}
	}

	.notice {
		text-align: center;
		margin-bottom: $space-md;
		font-size: $font-size-sm;

		p {
			display: inline-block;
			background: $white;
			border-radius: 999px;
			color: $grey-darkest;
			font-style: italic;
			margin: 0 auto $space;
			padding: $space-sm $space-lg;
			margin-bottom: 0;
		}
	}

	.matches-description {
		color: $grey-darkest;
		font-style: italic;
		margin-bottom: $space-sm;
	}

	.collection {
		display: flex;
		flex-wrap: wrap;
	}

	.toggle-filters-mobile {
		display: none;
		background: $white;
		position: fixed;
			left: $space;
			right: $space;
			bottom: $space;
		z-index: 15;

		.btn {
			display: block;
			margin: 0 auto;
			width: 100%;
		}
	}

	.curtain-toggle-filters {
		display: none;
		background: transparentize($black, 0.8);
			position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
		backdrop-filter: blur(2px);
		min-width: 375px;

		& + .toggle-parent.hidden{
			display: none;
		}
	}

	@include media-breakpoint-down (lg){
		overflow: hidden;

		&:has(.hidden .col-filters) {
			overflow: auto;

			.col-filters {
				top: unset;
				&:before {
					display: none;
				}
			}

			.curtain-toggle-filters {
				display: none;
			}
		}

		.curtain-toggle-filters,
		.toggle-filters-mobile {
			display: block;
		}

		.col-filters {
			max-width: calc(100% - $space-sm);
			background: $white;
			box-shadow: $shadow-default;
			border-radius: $border-radius-lg $border-radius-lg 0 0;
			padding-top: $space-lg;
			min-height: $space-xxxl;
			position: fixed;
				top: calc($nav-height-desktop + $space-xs);
				bottom: 0;
				left: $space-xs;
				right: $space-xs;
				z-index: 1;
			transition-duration: 0.2s;

			&:before {
				content: " ";
				display: block;
				position: absolute;
					bottom: ($space-xxl + $space-sm);
					left: 0;
					right: 0;
					z-index: 2;
				background: linear-gradient(0deg, $white 30%, transparentize($white,1) 100%) 0% 0% no-repeat padding-box;
				height: $space-xl;
				pointer-events: none;
				backdrop-filter: blur(1px);
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.col-filters {
			top: 30vh;
		}
	}

	@include media-breakpoint-up(md){
		.section-suggestions {
			padding: $space-md $space-lg;
		}
	}

	@include media-breakpoint-up(lg){
		.toggle-parent.hidden .toggle-target {
			display: block!important;
		}
	}
}

// BOOKING LANDING
.page-homeweb-booking {
	.section-header {
		margin-top: $space-xl;
		margin-bottom: $space;
	}

	.col-calendar {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: $space-md;

		@include media-breakpoint-up(sm) {
			flex-flow: row;
			margin-bottom: $space-lg;
		}

		@include media-breakpoint-up(lg) {
			flex-flow: column;
			margin-bottom: 0;
		}

		.item-dashboard {
			padding: 0;
		}

		.calendar-section {
			display: flex;
			flex-flow: column;
			margin: 0 auto;

			@include media-breakpoint-up(sm) {
				margin: 0;
				display: block;
			}

			.helper-text {
				order: 1;
				margin-top: $space-sm;
				margin-bottom: $space-sm;
				font-size: $font-size-xs;
				line-height: $font-size-xs;
				text-align: center;

				@include media-breakpoint-up(sm) {
					margin-bottom: 0;
				}

				@include media-breakpoint-up(lg) {
					margin-bottom: $space-lg;
				}

			}

			.calendar-container {
				order: 2;
				margin-bottom: $space;

				@include media-breakpoint-up(sm) {
					margin-bottom: 0;
				}
			}
		}

		.calendar-container {
			display: flex;
			justify-content: center;
		}

		.item-browse {
			flex: 1 1 auto;
			height: auto;

			.item-inner {
				justify-content: center;
			}

			@include media-breakpoint-up(sm) {
				margin-left: $space;
				flex: 0 1 280px;
			}

			@include media-breakpoint-up(lg) {
				flex: 0 0 auto;
				margin-left: 0;
			}
		}

		.item-dashboard {
			display: none;

			@include media-breakpoint-up(sm) {
				display: block;
			}
		}

		@media(min-width: 576px) and (max-width: 600px) {
			flex-flow: column;

			.calendar-section {
				margin: 0 auto $space;
				flex: 1 1 auto;
			}

			.item-dashboard {
				margin-left: 0;
			}
		}
	}

	// .section-provider {
	// 	.wrapper {
	// 		border: 1px solid $border-light;
	// 		border-radius: $border-radius;
	// 		background: $white;
	// 		margin-left: 0;
	// 		margin-right: 0;
	// 		margin-bottom: $space-lg;

	// 		& >.col-12.col-md.d-flex.g-0 {
	// 			padding: $space-sm $space;
	// 		}
	// 	}

	// 	//.col-provider-image {
	// 	//	padding: $space $space-sm $space-sm 0;
	// 	//	padding-right: 0;
	// 	//}
	
	// 	.col-bio {
	// 		padding: $space $space-md;
	// 		padding-right: 0;
	// 		flex: 1 1 auto;
	// 	}
	
	// 	.provider-image {
	// 		overflow: hidden;
	// 		border-radius: 999px;
	// 		width: 70px;
	// 		height: 70px;
	// 		border: 2px solid $border-light;
	// 		margin-top: $space-md;

	// 		picture {
	// 			width: 100%;
	// 			height: 100%;
	// 		}
	
	// 		img {
	// 			width: 100%;
	// 			height: 100%;
	// 			object-fit: cover;
	// 			max-width: none;
	// 		}
	// 	}
	
	// 	.provider-name {
	// 		font-size: $font-size-xl;
	// 		font-weight: 700;
	// 	}

	// 	.provider-bio-content {
	// 		overflow: hidden;
	// 		display: -webkit-box;
	// 		-webkit-box-orient: vertical;
	// 		-webkit-line-clamp: 3;

	// 		&.expanded {
	// 			-webkit-line-clamp: unset;
	// 		}
	// 	}

	// 	.btn-bio-link {
	// 		font-size: $font-size-sm;
	// 		padding: 0;
	// 		margin: 0;
	// 		text-decoration: none;

	// 		border-bottom: 1px solid $grey-dark;
	// 		border-radius: 0;

	// 		i {
	// 			font-size: $font-size-xxs;
	// 		}
	// 	}
	
	// 	.col-service-selection {
	// 		border: solid $border-light;
	// 		border-width: 1px 0 0;
	// 		padding: $space-md $space-lg;
	// 		margin-top: $space;
	// 	}
	
	// 	p {
	// 		margin-bottom: $space-xs;
	// 	}
	
	// 	p:not(.provider-name),
	// 	a {
	// 		font-size: $font-size-sm;
	// 	}

	// 	// Styles specific to provider section that does not include a bio.
	// 	.no-bio {
	// 		align-items: center;
	// 		padding: $space-sm $space;

	// 		.col-provider-image {
	// 			padding: 0;
	// 		}

	// 		.provider-image {
	// 			width: 60px;
	// 			height: 60px;
	// 			margin-top: 0;
	// 		}

	// 		.col-bio {
	// 			display: flex;
	// 			flex-flow: row;
	// 			flex-wrap: wrap;
	// 			align-items: center;
	// 			justify-content: space-between;
	// 			padding-top: 0;
	// 			padding-bottom: 0;
	// 			padding-right: 0;

	// 			& > .row {
	// 				order: 1;
	// 			}
	// 		}

	// 		.provider-name {
	// 			line-height: 1;
	// 			margin-right: $space;
	// 			margin-top: $space-xs;
	// 		}
	// 	}

	// 	@include media-breakpoint-up(md){
	// 		.col-provider-image {
	// 			padding: $space $space-sm;
	// 		}

	// 		.col-bio {
	// 			padding: $space $space-sm;
	// 		}

	// 		.col-service-selection {
	// 			border-width: 0 0 0 1px;
	// 			margin-top: 0;
	// 		}
	// 	}

	// 	@include media-breakpoint-up(lg){
	// 		padding: 0 $space-xxl;

	// 		.wrapper {
	// 			margin-bottom: 0;
	// 		}

	// 		.no-bio {
	// 			.provider-name {
	// 				margin-top: unset;
	// 			}
	// 		}
	// 	}

	// 	@include media-breakpoint-up(xl){
	// 		.col-provider-image {
	// 			padding-top: 0;
	// 		}

	// 		.col-bio {
	// 			padding: 0 $space-md $space;
	// 		}

	// 		.provider-image {
	// 			width: 85px;
	// 			height: 85px;
	// 		}
	// 	}
	// }

	.col-providers {
		.provider-filters {
			display: flex;
			justify-content: flex-start;
			margin-bottom: $space-lg;

			.filter {
				// display: flex;
				// flex-flow: row;
				// align-items: center;

				.form-label {
					// font-weight: normal;
					margin: $space-md 0 0 0;
				}

				.form-select {
					flex:0 1 200px;
				}
			}
		}

		p.availability {
			text-align: center;
			.date {
				display: block;
			}

			@include media-breakpoint-up(lg) {
				text-align: left;

				.date {
					display: inline-block;
				}
			}

			.date {
				font-weight: 600;
				color: $mulberry-dark;
			}
		}

		@include media-breakpoint-up(lg){
			padding: $space-lg $space-xxl;
		}
	}

	.section-booking {
		@include media-breakpoint-up(lg){
			.wrapper {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	.section-modality-availability {
		margin-bottom: $space-xl;
	}

	.section-booking-footer {
		margin-top: calc($space-xxl);

		.language-option-service {
			display: none;
		}

		.item-dashboard {
			padding-left: 0;
			padding-right: 0;
		}

		@include media-breakpoint-up(sm) {
			.language-option-service {
				display: block;
			}

			.item-dashboard {
				display: none;
			}
		}
	}

	.booking-nav {
		margin-top: $space;

		.booking-nav-column {
			display: flex;
			justify-content: space-between;

			.btn {
				min-width: 115px;
			}
		}
	}

	.provider-lock {
		border-top: 1px solid $border-color;
		margin-top: $space;
		padding-top: $space;
		display: block;
		width: 100%;

		p, a {
			line-height: 1.2;
		}

		p {
			color: $grey-dark;
			font-size: $font-size-xxs;
		}

		a {
			font-size: $font-size-xxs;
		}
	}
}

.container-reschedule,
.container-cancel,
.container-end-service,
.container-confirm-booking {

	input[id="phone"] {
		margin-bottom: $space-lg;
	}

	.cancellation-policy {
		color: $grey-dark;
	}

	.container-buttons {
		display: flex;
		gap: $space-sm;
		margin-top: $space-lg;
		margin-bottom: $space-lg;
		width: 100%;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		.btn-booking {
			display: flex;
			flex-direction: row;
			align-items: center;
			text-align: center;
			justify-content: center;
			min-height: 65px;

			@include media-breakpoint-between(sm,xl) {
				width: 50%;
			}

			@include media-breakpoint-up(xl) {
				width: 173px;
			}
		}
		.btn-reschedule {
			padding: $space-xs calc($space * 1.6667);
		}
	}
}

.page-homeweb-end-service {
	.end-service-note {
		font-size: $font-size-sm;
		a {
			font-size:$font-size-sm;
		}
	}
}

.page-homeweb-confirm-booking,
.page-homeweb-confirm-service {
	#content {
		min-height: unset;
		overflow: unset;

		@include media-breakpoint-up(lg) {
			overflow: hidden;
		}
	}
	.section-dsg-navbar-mobile {
		justify-content: space-between;
		gap: $space-xs;
		.btn {
			width: 100%;
		}
	}

	.container-confirm {
		.section-header {
			h1 {
				@include media-breakpoint-down(sm) {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		.row-buttons {
			display: none;
			@include media-breakpoint-up(md) {
				display: flex;
				gap: $space-md;
				justify-content: flex-start;
			}
		}
	}
}

.page-homeweb-confirm-service {
	.user-email {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 95vw;
	}
}

.page-homeweb-confirm-booking {
	.page-footer {
		position: sticky;
		bottom: 0;
	}
	.container-add-to-calendar {
		max-width: 500px;
		background-color: $white;
		margin-top: $space-xl;
		border: 1px solid $grey-light;
		border-radius: $border-radius-lg;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: $space-lg $space-xxl;
		@include media-breakpoint-down(md) {
			padding: $space-lg $space;
		}
	}
}

.page-homeweb-service-recommendation {
	.item-pathfinder-recommends {
		.text {
			flex-grow: 1;
		}

		.btn {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}

	.row-additional-resources {
		.item-inner {
			.item-resource {
				.row {
					@include media-breakpoint-between(md, xl) {
						display: flex;
						flex-direction: column;
						align-content: center;
						justify-content: flex-start;
					}
				}
			}
		}
	}

	.item-pathfinder-recommends-v2 {
		.item-media {
			&.item-media-fallback {
				background-position: center;
			}
		}

		@include media-breakpoint-between(lg, xl) {
			.item-inner {
				flex-flow: column;
			}

			.item-media {
				display: block;
			}
		}
	}
}

.page-homeweb-booking-success {
	// Appointment tile row
	.item-booking-confirm {
		.header-actions {
			flex: 0 1 auto;
		}
	}

	// Provider info row
	.item-provider-info,
	.item-provider-info .no-bio {
		.provider-image {
			width: 45px;
			height: 45px;
			margin-top: $space-md;
		}

		.col-bio {
			padding-left: $space-md;
			padding-right: $space-md;
		}
	}

	.item-provider-info {
		.no-bio {
			.provider-image {
				margin-top: 0;
			}
		}
	}

	// In person info row
	.item-in-person-address-option {
		.item-media {
			flex: 0 0 63px;
		}

		.icon-container {
			margin: 0 auto;
		}

		.item-content {
			padding: $space-md;
		}
	}

	@include media-breakpoint-up(sm) {
		.item-provider-info,
		.item-provider-info .no-bio {
			.provider-image {
				width: 80px;
				height: 80px;
			}
		}

		.item-in-person-address-option {
			.item-media {
				flex: 0 0 100px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.item-provider-info {
			.provider-image {
				margin-top: 0;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.item-provider-info,
		.item-provider-info .no-bio {
			.col-provider-image {
				padding-left: calc($space / 3);
				padding-right: 0;
			}
		}

		.item-provider-info {
			.provider-image {
				margin-top: $space-md;
			}
		}
	}
}

//======================================================================//
//======================== <<<<< Dashboard >>>>> =======================//
//======================================================================//
.page-homeweb-dashboard {
	.popover {
		.popover-arrow {
			//left: calc($space-xxl * -2)!important;
		}
	}

	.item-pathfinder-recommends-v2 {
		.item-media {
			@include media-breakpoint-up(lg) {
				display: none;
			}

			@include media-breakpoint-up(xl) {
				display:block;
			}
		}
	}

	.section-recently-viewed {
		.card-column {
			display: flex;
			margin: 0;
			padding: 0;
		}

		.polaroid-resource-duration {
			flex: 1 1 auto;
		}

		.single-tile {
			align-self: flex-start;
			flex: 0 0 auto;
		}
	}
}

//======================================================================//
//==================== <<<<< Dashboard Browse >>>>> ====================//
//======================================================================//

.section-dashboard {
	margin-bottom: $space-md;

	.container {
		padding-left: 0;
		padding-right: 0;
	}

	&.section-hero-featured-resource {
		margin-bottom: calc($space * 2.4444444444);
	}
}

.section-welcome-block {
	padding: $space-xxxl 0;
	background-color: $white-off;

	.profile-link {
		transition-duration: 0.3s;
		text-decoration: none;
		border-bottom: 4px solid $primary;
	}

	h1 {
		@extend .h2;
		margin-bottom: $space-md;
	}

	&.first-time {
		.container-collection {
			margin-top: $space;
		}

		input {
			border-right: 0;
		}

		.input-group-text {
			background-color: $white;
			border-left: 0;
			transition-duration: 0.3s;

			&:hover {
				background-color: $primary;
				color: $white;
			}
		}

		.column-title {
			color: $grey-dark;
		}
	}
}

// TODO: Remove this if we don't use this section anymore
.section-category-nav {
	display: none;
	color: $white;
	background-color: $primary;
	overflow: hidden;
	padding: $space-lg 0;

	.container {
		display: flex;
		flex-flow: row;
		align-items: center;
	}

	.title {
		font-weight: 600;
		text-transform: uppercase;
		margin-right: $space-xxl;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			float: left;
			margin: $space-xs $space-sm $space-xs 0;

			&:last-child {
				margin-right: 0;
			}
		}

		.btn {
			min-width: 150px;
		}
	}

	@include media-breakpoint-up(lg) {
		display: block;
	}
}

.navigation-browse {
	margin-top: $space-xxl;
	margin-bottom: $space;

	.column-browse-topics,
	.column-search {
		display: flex;
			align-items: center;
		height: 100%;
	}

	.column-browse-topics {
		margin-right: $space;
		flex: 0 0 auto;
		
		.label {
			display: none;
			margin: 0 $space-sm 0 0;
		}

		.dropdown-toggle {
			background: $white;
			font-size: $font-size-std;
			color: $black;

			&.show,
			&:hover,
			&:focus,
			&:active {
				color: $primary
			}
		}

		.dropdown-menu {
			&.show {
				max-height: calc($space-xl * 4.75);
				overflow: auto;
				position: relative;
				padding-bottom: 0;

				.overlay {
					pointer-events: none;
					width: 100%;
					height: calc(35px / 2);
					position: sticky;
					right: 0;
					bottom: 0;
					left: 0;
					background: linear-gradient(180deg, transparentize($white, 1) 0%, transparentize($white, 0) 100%);
				}
			}
		}
	}

	.column-search {
		flex: 1 1 auto;
	}

	.form-search {
		button {
			background: $white;
			color: $primary;

			&:hover,
			&:focus,
			&:active,
			&.active {
				background: $primary;
				color: $white;
			}
		}
	}

	@include media-breakpoint-up(md){
		.column-browse-topics {
			.label {
				display: inline-block;
			}
		}
	}
}

.section-resource-monthly {
	box-shadow: 0px 3px 6px 0px $grey-light;
}

.section-browse-resources {
	padding-top: $space-xxxl;

	h2 {
		margin-bottom: $space-lg;
	}

	.section-topic-nav {
		overflow: hidden;
	}

	.topics {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			float: left;
			margin-right: $space-xs;
			margin-bottom: $space-xs;

			a {
				font-size: $font-size-sm;
				font-weight: 600;
			}
		}
	}

	.section-resource-list {
		margin-top: $space-xxl;

		.h5 {
			font-weight: 600;
			margin-bottom: calc($space * 1.1111111111);
		}
	}

	.browse-resources-sidebar {
		@include media-breakpoint-up(lg) {
			.row {
				margin-left: $space;
			}
		}
	}
}

.section-events-alumni {
	margin-top: $space-xxl;
	margin-bottom: $space-xxxl;
}

.section-category-list {
	box-shadow: 0px 3px 6px 0px $grey-light;
}

//======================================================================//
//====================== <<<<< PULSECHECK >>>>> ========================//
//======================================================================//
.page-homeweb-pulsecheck {
	#content {
		overflow: unset;
		//min-height: unset;

		@include media-breakpoint-up(lg) {
			overflow: hidden;
		}
	}

	.container-homeweb-pulsecheck {
		.btn-continue,
		.btn-skip {
			display: none;
			align-self: flex-start;

			@include media-breakpoint-up(md) {
				display: inline-block;
			}
		}
	}

	.pulsecheck-slide {
		margin: $space-lg 0;
	}

	.pulsecheck-slide-container {
		width: 100%;
		height: 70px;
		margin-top: $space-xxl;
		border-radius: $border-radius-lg;
		background: linear-gradient(90deg, $green, $yellow 50%, $red);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.form-range {
		width: 95%;
	}

	.label-container {
		margin-top: $space;
		// display: none;
		justify-content: space-between;
		text-align: center;

		.feeling-label {
			@extend .h5;
			margin-bottom: 0;
			display: none;
			font-weight: 600;

			&.active {
				display: inline-block;
			}

			&.excellent {
				&.active {
					color: $green;
				}
			}

			&.good {
				margin-left: $space;

				&.active {
					color: $green-dark;
				}
			}

			&.gettingBy {
				margin-left: $space-md;

				&.active {
					color: darken($yellow, 30);
				}
			}

			&.notGood {
				&.active {
					color: darken($yellow-dark, 8);
				}
			}

			&.inCrisis {
				&.active {
					color: $red;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.feeling-label {
				margin-left: 0!important;
			}
		}

		@include media-breakpoint-up(sm) {
			display: flex;
			text-align: left;

			.feeling-label {
				display: inline-block;
			}
		}
	}

	.alert {
		&.feeling-alert {
			width: 100%;
			margin-bottom: $space-xl;
			border: none;
			background: transparent;
			box-shadow: unset;
			padding: 0;

			@include media-breakpoint-up(lg) {
				max-width: 80%;
			}

			.wrapper {
				display: flex;
				align-items: center;
			}

			.status {
				display: none;
			}

			.alert-message {
				font-weight: 600;
				font-size: $font-size-lg;
			}

			.alert-close {
				display: none;
			}
		}
	}
}

.section-dsg-navbar-mobile {
	position: sticky;
	margin-top: $space-xxl;
	padding: $space-sm;
	bottom: 0;
	background-color: $white;
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid $border-color;
	box-shadow: 1px -2px 6px rgba(0,0,0, .05);
	z-index: 3;

	.btn {
		width: 100%;

		@include media-breakpoint-up(sm) {
			width: auto;
		}
	}

	@include media-breakpoint-up(md) {
		display: none;
	}
}

.page-pulsecheck-welcome {
	.container-pulsecheck-welcome {

		.button-container {
			display: flex;
				flex-flow:column;
			gap: $space-sm;
			margin-top: $space-lg;

			.btn-answer {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				margin:0 auto;
				line-height: 1.2;
				padding-top: $space;
				padding-bottom: $space;
			}

			@include media-breakpoint-down(sm){
				flex-direction: column;
				margin-bottom: $space-lg;
			}

			@include media-breakpoint-up(md) {
				.btn-answer {
					width: 50%;
				}
			}
		}
	}
}

//======================================================================//
//======================== <<<<< Moodcheck >>>>> =======================//
//======================================================================//
.page-homeweb-moodcheck {
	background-color: $white;
	//background-image: url("#{$url-cdn-common}/uploads/images/login-bg.png");
	//background-position: right center;
	//background-repeat: no-repeat;
	//background-size: 33%;

	#content {
		overflow: unset;

		@include media-breakpoint-up(md) {
			overflow: hidden;
		}
	}

	.container-homeweb-moodcheck {
		position: relative;

		.dsg-inner {
			margin-bottom: $space-xl;


			.btn-continue,
			.btn-skip {
				display: none;

				@include media-breakpoint-up(md) {
					display: inline-block;
				}
			}
		}
	}
}

// Pulsecheck and Moodcheck Pages
.page-homeweb-pulsecheck,
.page-homeweb-moodcheck {
	.section-dsg-navbar-mobile {
		justify-content: space-between;
		gap: $space-xs;
		.btn {
			width: 100%;
		}
	}

	.btn-skip {
		margin-left: $space;
	}
}

//======================================================================//
//===================== <<<<< CASE CREATION >>>>> ======================//
//======================================================================//
.page-homeweb-case-creation {
	#content {
		overflow: unset;

		@include media-breakpoint-up(lg) {
			overflow: hidden;
		}
	}

	form {
		.dsg-inner {
			@include media-breakpoint-up(lg) {
				max-width: 60%;
			}
		}

		#comments {
			margin-bottom: $space-sm;
		}

		.language-option-text {
			font-size: $font-size-sm;
			margin-bottom: 0;
		}

		.submit-inner {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}
		}

		.section-dsg-navbar-mobile {
			.btn {
				width: 100%;
			}
		}
	}
}

//======================================================================//
//=================== <<<<< ASSESSMENT PAGES >>>>>======================//
//======================================================================//
.page-assessment {
	#content {
		overflow: unset;

		@include media-breakpoint-up(lg) {
			overflow: hidden;
		}
	}

	.container-assessment {
		margin-left: auto;
		margin-right: auto;

		&:not(&:has(.section-dsg-navbar-mobile)) {
			.section-progress-tracker.bottom {
				margin-top: $space-xxl;
			}
		}
	}

	.container-dsg {
		position: relative;
	}

	.dsg-inner {
		margin-top: 0;
		margin-bottom: $space-lg;
	}

	.container-alert {
		.alert {
			margin-top: $space-lg;
		}
	}

	.container-assessment-nav {
		display: none;
		margin-top: calc($space * 2.1666666667);

		@include media-breakpoint-up(md){
			display: block;
		}
	}

	.section-dsg-navbar-mobile {
		justify-content: space-between;
	}

	.assessment-sentio,
	.assessment-hra {
		.row {
			@include media-breakpoint-up(xl) {
				flex-wrap: nowrap;
			}
		}

		&.container-fluid {
			@include media-breakpoint-up(xxl) {
				max-width: $container-header-xxl;
			}
		}

		.col-assessment-content {
			@include make-col(12);

			@include media-breakpoint-up(xl) {
				@include make-col(7);
			}
		}

		.col-assessment-image,
		.col-assessment-helper {
			@include make-col(12);

			@include media-breakpoint-up(xl) {
				flex: 1 1 auto;
				width: auto;
				margin-left: $space-xxl;
			}
		}
	}

	.assessment-sentio {
		.sentio-title {
			margin-top: $space-sm;
		}
	}

	.assessment-pathfinder {
		margin-bottom: auto;

		.assessment-question-text {
			h6 {
				@extend .h5;
				@extend .sentio-title;
				font-weight: 300;
			}

			h3 {
				@extend .h2;
				font-family: $font-accent;
			}
		}
	}

	// HRA Assessment
	&.page-hra-assessment {
		#content {
			&:before {
				background-image: none;
			}
		}

		.helper-image {
			width: 100%;
			min-height: 300px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			margin-top: $space-md;

			@include media-breakpoint-up(xl) {
				margin-top: 0;
			}
		}

		.section-assessment-tip {
			margin-top: auto;
			padding-top: $space-lg;

			@include media-breakpoint-up(xl) {
				padding-top: $space-xxl;
			}
		}

		.assessment-tip {
			background-color: $yellow-light;
			border: 1px solid darken($yellow-light, 10%);
			border-radius: $border-radius-lg;
			padding: $space-lg calc($space * 2);

			.title {
				font-size: $font-size-xl;
				font-weight: 600;
			}

			p {
				line-height: 1.2;
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-down(md) {
			.section-progress-tracker {
				&.bottom {
					margin-top: $space-xxl;
				}
			}

			.container-manager {
				margin-bottom: 0;
			}
		}
	}
}

//======================================================================//
//==================== <<<<< 5 STAR RATING >>>>>========================//
//======================================================================//
.page-pathfinder-five-star-rating {
	#content {
		min-height: unset;
	}
}

.container-page-rating {
	.section-five-star-rating {

		.container-dsg {
			min-height: unset;
			align-items: center;
		}

		.rating-heading {
			float: none;
			font-size: calc($font-size-std * 2);
			line-height: 1.2;
			text-align: center;

			@include media-breakpoint-down(lg) {
				font-size: calc($font-size-std * 1.6);
			}
		}

		.rating-container {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			gap: $space-xs;
			margin-top: $space-md;

			label {
				font-size: $font-size-xxxl;
				color: $grey-light;
				cursor: pointer;
				transition: color 0.3s;
			}

			// Changes color of highlighted stars
			.highlight {
				color: $warning;
			}

			input {
				position: absolute;
				opacity: 0;
				width: 0;
				height: 0;
				z-index: -1;
			}
		}
	}
}

.page-recent-check-ins {
	.collection {
		display: flex;
			flex-wrap: wrap;
		position: relative;
	}

	.section-check-in {
		background: $yellow-lightest;
		border-radius: $border-radius-lg;
		padding: $space-md $space;
		margin-top: $space-lg;
		margin-bottom: $space-md;
	}
}

//======================================================================//
//===================== <<<<< Resource Error >>>>> =====================//
//======================================================================//
.page-resource-error {
	.error-image {
		display: block;
		max-width: 160px;
		margin: 0 auto;
	}

	.header-sub-title {
		font-weight: $font-weight-light;
	}

	.btn-primary {
		.fa-chevron-left {
			margin-right: $space-xs;
			font-size: $font-size-xs;
		}
	}
}

//======================================================================//
//============== <<<<< Cleveland Clinic Interstitial >>>>> =============//
//======================================================================//
.page-cleveland-clinic-canada-interstitial {
	.item-cleveland-clinic {
		ul {
			padding-left:0;

			li {
				&:before {
					@extend .fas;
					content: "\f00c";
					font-family: $font-awesome;
					font-size: $font-size-xl;
					top: $space-sm;
				}
			}
		}
	}
}

.page-consent-gate {
	.button-container {
		display: flex;
		flex-flow: column;

		.btn {
			&:first-child {
				margin-right: 0;
				margin-bottom: $space-sm;
			}
		}

		@include media-breakpoint-up(sm) {
			flex-flow: row;

			.btn {
				&:first-child {
					margin-right: $space-sm;
					margin-bottom: 0;
				}
			}
		}
	}

	.homewood-health-logo {
		display: block;
		margin: calc($space-xxl * 2) auto 0;
		max-width: 300px;
	}
}

//======================================================================//
//======================== <<<<< Meet Now >>>>> ========================//
//======================================================================//

.page-homeweb-meet-now:not(.page-meet-now-options) {
	.multi-column {
		.btn-answer {
			height: auto;
		}
	}
}

.page-meet-now-options {
	@include media-breakpoint-up(sm){
		.option{
			&:first-child {
				flex: 0 0 100%!important;
			}

			&:not(:first-child){
				flex: 1 1 50%!important;
			}
		}
	}

	@include media-breakpoint-up(lg){
		.option{
			&:first-child {
				flex: 0 0 50%!important;
			}

			&:not(:first-child){
				flex: 1 1 25%!important;
			}
		}
	}
}

//======================================================================//
//======================== <<<<< Feedback >>>>> ========================//
//======================================================================//

.page-feedback {
	.header-section {
		margin-bottom: $space-xl;
	}

	.feedback-image {
		width: 100%;
		max-width: 300px;
		margin: 0 auto $space-lg;
	}

	ol {
		margin-top: calc($space * 1.3333333333);
		padding-left: calc($space * 0.8888888889);

		li {
			margin-bottom: $space;
			padding-left: calc($space * 0.6666666667);
		}
	}

	.help-container {
		background-color: $white-off;
		margin-top: $space-xl;
		padding: $space-sm $space;
		font-size: $font-size-sm;

		.text-highlight {
			color: $red;
			font-weight: $font-weight-bold;
		}

		p {
			line-height: 1.3;
		}
	}

	@include media-breakpoint-up(md){
		.feedback-image {
			max-width: 400px;
		}
	}
}