//
// (C) Copyright 2024 by Homewood Health Inc.
//
// $File: _messages.scss
// $Purpose: Styles relating to the messaging feature
//

.message-page {
	text-align: center;

	.icon {
		font-size: 80px;
		color: $grey-dark;
	}

	h4 {
		color: $grey-dark;
		margin-bottom: 0;
	}

	p {
		color: $grey-light;
	}

	&.hide-icon {
		.icon {
			display: none;
		}
	}
}

.container-page-messages {
	@include media-breakpoint-down(md){
		margin-top: $space;
	}

	.alertPage {
		position: relative;
			top: -$space;
	}

	.container-inbox,
	.container-messages {
		border: 1px solid $border-color;
		position: relative;
		border-radius: $border-radius;
		overflow: scroll;

		.message-page {
			margin-top: $space-lg;
		}

		.loading-container {
			&:before {
				position: absolute;
			}
		}

		@include media-breakpoint-up(md){
			min-height: 600px;
		}
	}

	.container-inbox {
		overflow: visible;
		// flex: 0 0 320px;
		margin-bottom: $space;
		z-index: 2;
		position: relative;
		border: none;

		.alert {
			margin-top: $space-sm;
			margin-left: calc($grid-gutter-width/2);
			margin-right: calc($grid-gutter-width/2);
		}

		.header {
			// padding: $space-sm $space $space-sm;
			position: sticky;
				top: 0;
				z-index: 5;

			&.has-prefs {
				display: flex;

				.form-switch {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					position: relative;
					margin-left: $space;

					.form-check-label {
						font-weight: normal;
					}
				}

				h1 {
					flex: 1 1 auto;
					padding-right: $space-sm;
				}

				input {
					min-width: 2em;
					margin-right: $space-xs;

					&:focus {
						box-shadow: none;
					}
				}

				.container-label {
					font-size: $font-size-xs;
					padding-top: $space-xs;
					line-height: 1;
				}

				.container-loading {
					width: 2.3em;
					position: absolute;
					left: 0px;
					background: transparentize($white, 0.1);
					text-align: center;
					padding-top: 8px;
					color: $primary;
					font-size: $font-size-sm;
				}

				@include media-breakpoint-between(md, lg) {
					input {
						min-width: 1.5em;
					}

					.container-label {
						font-size: $font-size-xs *  0.9;
					}
				}
			}

			h1 {
				line-height: 1.5rem;
				color: $grey-darkest;
				font-weight: 600;
				font-size: $font-size-lg;
				margin-bottom: 0;

				// Visually hide the title on mobile
				position: absolute;
				visibility: hidden;
				width: 0;
				height: 0;
			}
		}

		.btn-nav-collapse {
			width: 100%;
			font-size: $font-size-sm;
			font-weight: 600;
			text-align: left;
			text-transform: uppercase;
			display: flex;
				justify-content: space-between;
				align-items: center;

			i {
				position: relative;
					top: 2px;
				margin-left: $space-xs;
				transition-duration: 0.5s;
			}

			&:not(.collapsed) {
				i {
					transform: rotate(180deg);
					top: 0px;
				}
			}
		}

		.filters {
			margin-bottom: $space;
			> * {
				margin: 0 $space-xs 0 0;
			}

			.label {
				font-size: $font-size-xs;
			}
		}

		.collection-inbox {
			margin: $space-sm 0 0;
			padding: $space $space 0;
			border: 1px solid $border-color;
			border-radius: $border-radius;

			// min-height: 230px;
			max-height: 230px;
			overflow: scroll;

			// box-shadow: $shadow-dropdown;
			// position: absolute;
			// background: $white;
			// z-index: 2;
			// width: 100%;

			.item-inner {
				&:hover {
					box-shadow: $shadow-default;
				}
			}
		}

		@include media-breakpoint-up(sm) {
			// flex: 0 0 250px;
			.alert {
				margin-top: 0;
				margin-left: 0;
				margin-right: 0;
			}
		}

		@include media-breakpoint-up(md) {
			border: 1px solid $border-color;
			flex: 0 0 320px;
			margin-bottom: 0;
			margin-right: $space;
			max-height: 85vh;
			overflow: scroll;

			.header {
				background-color: $white;
				border-bottom: 1px solid $border-color;
				padding: $space;
				margin-bottom: $space;

				h1 {
					position: static;
					visibility: visible;
					height: auto;
					height: auto;
				}
			}

			.collection-inbox {
				padding: 0 $space;
				margin: 0;
				border: none;
			
				min-height: initial;
				max-height: none;
				overflow: visible;

				&.collapse {
					display: block;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			flex: 0 0 320px;
		}
	}

	.container-messages {
		flex: 1 1 auto;
		overflow: hidden;

		.alert {
			margin-left: calc($grid-gutter-width/2);
			margin-right: calc($grid-gutter-width/2);
		}

		.header {
			border: 1px solid $border-color;
			border-radius: $border-radius;
			padding: $space;
			margin: -1px;
			// margin-bottom: $space;
			box-shadow: $shadow-default;
			position: relative;
				z-index: 1;

			.column-profile {
				display: flex;
				align-items: center;
			}

			.profile {
				flex: 0 0 auto;
				margin-right: $space;
			}

			.stat {
				.label {
					font-size: $font-size-sm;
				}

				.h4 {
					font-weight: 700;
					display: block;
					color: $mulberry-dark;
					margin-bottom: 0;
				}

				a {
					display: block;
					margin-top: $space-xs;
				}
			}

			.message-notice {
				font-size: $font-size-xxs;
				color: $grey-dark;
				line-height: 1.2;
				margin-top: $space-sm;

				span {
					text-transform: uppercase;
					display: block;
					margin-bottom: $space-xs;
				}

				ol {
					margin: 0;
					padding-left: calc($space * 0.6666666667);
				}

				@include media-breakpoint-up(xl) {
					display: flex;
					justify-content: center;
					flex-flow: column;
					margin-top: 0;
				}
			}

			@include media-breakpoint-up(md) {
				.profile {
					margin-right: $space;
				}
			}
		}

		.collection {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			min-height: 500px;
			max-height: 60vh;
			overflow: scroll;
			padding-top: $space;
			padding-bottom: calc($space-xxxl * 2);

			.item-wrapper {
				padding: 0 $space;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;

				&.item-recipient {
					font-size: $font-size-sm;

					em {
						margin-top: calc($space * 1.2222222222);
					}
				}
			}

			@include media-breakpoint-down(sm){
				.item-wrapper {
					padding: 0;
				}
			}
		}

		.footer-message {
			background: transparentize($white, 0.2);
			backdrop-filter: blur(3px);
			border-top: 1px solid $border-color;
			position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			padding: $space;
			margin: $space 0 0 0;
			display: flex;

			.btn {
				max-height: 41px;
			}

			#message {
				max-height: 200px;
			}

			.btn-primary {
				display: flex;
				align-items: center;

				.fa-spinner,
				.fa-paper-plane {
					display: inline-block!important;
					position: relative;
					top: 1px;
					left: -3px;
					margin-right: 3px;
				}

				.fa-spinner {
					font-size: $font-size-xs;
				}

				.fa-paper-plane {
					margin-right: $space-xs;
					font-size: $font-size-xs;
				}

				&.disabled {
					color: $white-off;
				}
			}

			.btn-refresh {
				display: inline-flex;
					// justify-content: center;
					// align-items: center;
				margin-left: $space-sm;
				padding-left: $space;
				padding-right: $space;

				i, .icon {
					font-size: $font-size-xs;
					position: relative;
					top: 4px;
				}

				.text {
					margin-left: $space-xs;
				}

				&:hover {
					background-color: $mulberry;
					border-color: $mulberry;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.stat {
				.stat {
					font-size: $font-size-std;
				}

				.h4 {
					font-size: $font-size-md;
				}
			}
		}

		@media (max-width: 375px) {
			.footer-message {
				flex-wrap: wrap;

				.input-group {
					width: 100%;
				}

				.btn-refresh {
					margin-left: 0;
					margin-top: $space-sm;
					flex: 1 1 auto;
					justify-content: center;
				}
			}
		}
	}

	.profile {
		flex: 0 0 auto;
		background-color: $white;
		border: 1px solid $border-color;
		width: 64px;
		height: 64px;
		border-radius: $border-radius-lg;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			object-fit: cover;
		}

		.icon {
			font-size: 32px;
			color: $mulberry-dark;
		}

		.initials {
			color: $mulberry-dark;
			display: inline-block;
			margin-top: 2px;
			font-size: $font-size-xl;
		}

		&.small {
			width: 32px;
			height: 32px;
			border-radius: 8px;

			.icon {
				font-size: 20px
			}

			.initials {
				margin-top: 1px;
				font-size: $font-size-sm;
			}
		}
	}

	.label {
		// text-transform: uppercase;
		color: $grey-dark;
	}

	.item-date-separator {
		flex: 0 0 100%;
		font-size: $font-size-sm;
		color: $grey-light;
		text-align: center;
		text-transform: uppercase;
		margin: $space-xs 0;

		.item-inner {
			border: none;
			padding: $space-xs;
		}
	}

	.item-inbox,
	.item-message {
		.item-inner {
			display: flex;
			flex-wrap:nowrap;
			width: 100%;
		}

		.profile {
			margin-right: $space;
		}

		.date {
			color: $grey-dark;
			font-size: $font-size-xxs;
			margin-top: $space-xs;
			text-transform: uppercase;
		}
	}

	.item-inbox {
		padding: 0;
		border-bottom: 0;
		margin-bottom: $space;

		.item-inner {
			padding: $space;
			border-radius: $border-radius;
			border: 1px solid $border-color;
		}

		.title {
			color: $mulberry;
			font-size: $font-size-sm;
			font-weight: 600;
		}

		.message {
			color: $black;
			font-size: $font-size-xs;
			line-height: 1.2;
			text-align: unset;
			margin: unset;
		}

		i {
			color: $grey-medium;
			font-size: $font-size-xs;
			display: inline-block;
		}

		.date {
			margin-top: $space-xs;
		}

		.statusDot {
			$size: 8px;
			display: inline-block;
			background: $grey-light;
			width: $size;
			height: $size;
			border-radius: $size;
			position: absolute;
			top: 28px;
			left: $space-xs;

			&.unread {
				background-color: $info;
			}
		}

		&.active {
			.item-inner{
				border: 1px solid $mulberry-light;
			}
		}

		&.action {
			background: $mulberry-white;

			.item-inner {
				border: none;
			}

			.item-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.profile {
				border-color: lighten( $mulberry-light, 25%);
			}

			&:hover {
				.profile {
					border-color: $mulberry-light;
				}

				.item-inner {
					box-shadow: 0px 0px 3px transparentize($mulberry-light, 0.1);
				}
			}
		}
	}

	.item-message {
		padding: 0;
		border: none;
		max-width: 75%;

		.item-inner {
			padding: $space-xs $space;
			border: none;
			overflow: hidden;
			position: relative;

			&:hover {
				box-shadow: none;
			}
		}

		.message {
			font-size: $font-size-sm;
			color: inherit;
			text-align: left;
			margin: unset;
		}

		.profile {
			margin-right: $space;
		}

		.item-content {
			background: #f4fbfe; // Sentio lightest blue
			color: darken($blue, 10%);
			padding: $space;
			border-radius: $border-radius;
		}

		&.outbound {
			align-self: flex-end;

			.item-inner {
				flex-direction: row-reverse;
			}

			.profile {
				margin-right: 0;
				margin-left: $space;
			}

			.item-content {
				background: $white-off;
				color: $grey-darkest!important;
			}
		}

		@include media-breakpoint-up(lg) {
			max-width: 75%;
		}

		&.system {
			max-width: none!important;
			width: 100%;

			.item-inner,
			.item-content {
				width: 100%;
			}

			.item-content {
				font-size: $font-size-sm;
				text-align: center;
				padding: $space-sm;
			}

			.date {
				margin-top: 0;
			}

			&.warning {
				.item-content {
					background-color: transparentize( $warning, 0.8 );
					color: darken($warning, 30%);
				}
				.date { color: darken($warning, 15%); }
			}

			&.error {
				.item-content {
					background-color: transparentize( $danger, 0.9 );
					color: darken($danger, 30%);
				}
				.date { color: darken($danger, 15%); }
			}
		}
	}

	.loading-container {
		&:before {
			z-index: 9;
		}

		@include media-breakpoint-down (md) {
			.loading-image {
				top: calc(50% - 0.5em);
			}

			.icon-loading {
				width: 50px;
				height: 50px;
			}

			.loading-text {
				top: calc(50% + 1.5em);
			}

			&.inline {
				.loading-image {
					top: 52%;
					left: calc(50% - 2.2em);
				}

				.icon-loading {
					width: 28px;
					height: 28px;
				}

				.loading-text {
					top: 50%;
					left: calc(50% + 1em);
				}
			}
		}
	}
}

.item-message-banner {
	margin: 0 auto;
	max-width: 500px;
	width: 80%;
	position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

	.item-media {
		max-width: 70px;
		margin: 0 0 $space-md;
	}

	.title {
		font-size: $font-size-lg;
		font-weight: 600;
		color: $primary;
		margin-bottom: $space-xs;
		line-height: 1.2;
	}

	p:not(.title) {
		font-size: $font-size-sm;
		color: $grey-dark;
	}

	@include media-breakpoint-up(md) {
		display: flex;

		.item-media {
			max-width: 100px;
			margin-right: $space-md;
		}
	}
}