//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _elements.scss
// $Purpose: Styles relating to page elements
//

//======================================================================//
//======================== <<<<< Buttons >>>>> =========================//
//======================================================================//
.btn {
	.icon {
		font-size: $font-size-xs;
	}

	// No margin right for icons if the button only contains an icon or if the button is the last element
	// NOTE: for this to work the text inside the button needs to be wrapped in an element like a span
	i:last-child,
	i:only-child {
		margin-right: 0;
	}

	&.btn-icon-spaced {
		i,
		.icon,
		img {
			margin-right: $space-xs;
		}
	}

	&.btn-icon-end {
		i,
		.icon,
		img {
			margin-left: $space-xs;
		}
	}

	&:not(.btn-lg){
		&.btn-icon-spaced {
			i,
			.icon,
			img {
				font-size: $font-size-sm;
			}
		}
	
		&.btn-icon-end {
			i,
			.icon,
			img {
				font-size: $font-size-sm;
			}
		}
	}
}


.btn-outline-grey {
	@include button-outline-variant($grey-dark);
	border-color: $grey-lightest;
}

.btn-outline-primary {
	// TODO: NZ20240201 - Test out the styling and confirm that it matches old styling then remove the commented out code below
	// @include button-outline-variant($primary);
	// background: $white;

	// &:hover {
	// 	background: $mulberry-lightest;
	// 	color: $mulberry-darkest;
	// 	border-color: $mulberry-lightest;
	// }

	// TODO: NZ20240201 - Do outline buttons using the normal macro if you plan on overriding so much of a button - it impacts hover/focus/focus-visible states which happen when using keyboard etc.
	@include button-variant(
		$white,
		$primary,
		$primary,
		$mulberry-lightest,
		$mulberry-lightest,
		$mulberry-darkest,
		$white,
		$primary,
		$primary,
	)
}

.btn-primary {
	@include button-variant(
					$primary,
					$primary,
					$white,
					$yellow-light,
					$primary,
					$primary
	)
}

.btn-secondary {
	@include button-variant(
					$yellow,
					$yellow,
					$mulberry-dark,
					$mulberry-lightest,
					$mulberry-lightest
	)
}

.btn-save{
	@include button-variant(
							$success,
							$success,
							$white,

							lighten($success, 5%),
							lighten($success, 5%),
							$white,

							lighten($success, 5%),
							lighten($success, 5%),
							$white,

							lighten($success, 5%),
							lighten($success, 5%),
							$white,
	)
}

.btn-outline-muted {
	@include button-outline-variant($primary);
	border-color: $grey-lightest;
}

.btn-outline-default {
	background: $white;
	@include button-outline-variant(
		$body-color,
		$body-color,
		$mulberry-lightest,
		$mulberry-lightest,
		$body-color
	);
	border-color: $grey-lightest;
}

.btn-outline-muted-light {
	@include button-variant(
					$mulberry-dark,
					$grey-lightest,
					$white,
					$mulberry-light,
					$mulberry-light,
					$white,
					$mulberry-light,
					$mulberry-light,
					$white
	)
}

.btn-outline-muted-white {
	@include button-variant(
							$white,
							$grey-lightest,
							$mulberry-dark,
							$mulberry,
							$mulberry,
							$white,
							$mulberry,
							$mulberry,
							$white
	);
	border-color: $grey-lightest;
}

.btn-tertiary {
	@include button-variant(
					$fuscia,
					$fuscia,
					$yellow-light,
					$mulberry-darkest,
					$mulberry-darkest,
					$yellow-light
	)
}

.btn-sm {
	.icon {
		font-size: $font-size-xxs;
	}
}

.btn-lg {
	line-height: 2rem;
	.icon {
		font-size: $font-size-lg;
	}
}

.btn-link:not(.btn-outline-muted) {
	color: $grey-dark;
	text-decoration: underline;

	i {
		text-decoration: none;
	}

	&:hover {
		color: $mulberry;
	}
}

.btn-icon {
	min-width: $space * 3;
	font-size: $font-size-sm * 2;
	color: $yellow-light;
	background: $mulberry-light;
	border-color: $mulberry-darkest;
	padding: 0;

	&:hover, &:focus {
		background: $mulberry;
		color: $yellow-light;
	}
}

.btn-activity-type {
	@extend .btn-link;
	padding: 0;
}

.btn-icon-stack {
	@extend .btn-link;
	display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
	text-align: center;
	text-decoration: none!important;

	i,
	.icon {
		margin-right: 0!important;
		padding-right: 0;
	}

	.text {
		margin-top: $space-xs;
		font-size: $font-size-xs;
		margin-left: 0;
	}
}

.btn-add {
	color: $yellow-light;
	display: flex;
	align-items: center;
	margin-left: auto;
	border-radius: $border-radius;
	font-weight: 700;

	@include media-breakpoint-up(sm) {
		margin-left: $space-lg;
	}

	i {
		@include media-breakpoint-up(md) {
			margin-right: $space-sm;
		}
	}

	span {
		display: none;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}
	}
}

.btn-square {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.btn-square-dark {
	@include button-variant(
					$mulberry,
					$mulberry,
					$yellow-light,
					$mulberry-lightest,
					$mulberry-lightest,
					$mulberry-darkest,
	);
	color: $yellow-light;
	background: $mulberry;
	border-color: $mulberry;
	padding: $space-md $space-lg;

	span {
		margin-top: $space-xs;
	}

	&:hover {
		svg path {
			fill: $mulberry-darkest;
			transition: all ease 0.3s;
		}
	}
}

.btn-square-outline {
	@include button-outline-variant($mulberry, $mulberry-darkest);
	padding: $space-md $space-md;
	border-color: $grey-lightest;

	img {
		margin-bottom: $space-xs;
		max-width: 65px;
	}

	&:hover, &:focus {
		background: $white;
	}
}

.btn-primary-light {
	@include button-variant(
					darken($mulberry-light, 3%),
					$mulberry,
					$yellow-light,
					$mulberry,
					$mulberry,
					$yellow-light,
					$primary,
					$primary
	)
}

.btn-close {
	width: calc($space*1.6666666667);
	height: calc($space*1.6666666667);
	background-image: url('#{$url-cdn-images}/icons/exit-close-icon.svg');
	background-size: calc($space*1.6666666667);
}

.btn-onboarding-next {
	i {
		margin-left: $space-xs;
		font-size: $font-size-xs;
	}
}

.btn-back {
	display: inline-block;
	text-decoration: none;
	margin-bottom: $space;

	i {
		margin-right: $space-sm;
		font-size: $font-size-xs;
	}

	&:hover {
		text-decoration: underline;
	}
}

.btn-answer {
	// @include button-outline-variant($primary);
	// border-color: $grey-lightest;
	font-size: $font-size-md;
	padding-left: $space-md;
	padding-right: $space-md;

	&.active {
		color: $mulberry-dark;
		background: $mulberry-lightest;
		border-color: $mulberry-lightest;
	}
}

.btn-light {
	@include button-variant(
		$yellow-lightest,
		$yellow-lightest,
		$mulberry,

		$mulberry-lightest,
		$mulberry-lightest,
		$mulberry-dark,

		$mulberry-lightest,
		$mulberry-lightest,
		$mulberry-dark,

		$grey-ultralight,
		$grey-ultralight,
		$grey-dark,
	)
}

.btn-primary-lightest {
	@include button-variant(
					$mulberry-white,
					$mulberry-white,
					$mulberry-dark,
					$mulberry,
					$mulberry,
					$yellow-light,
					$primary,
					$primary
	)
}

.btn-rounded {
	border-radius: 999px;
}

//======================================================================//
//====================== <<<<< Pagination >>>>> ========================//
//======================================================================//

.container-pagination {
	border-top: 0;
	padding-top: 0;

	&.container-manager-header {
		flex: 0 0 auto;
		margin-top: $space;
		margin-left: auto;

		@include media-breakpoint-up(xl) {
			margin-top: 0;
			width: auto;
		}
	}

	.pagination {
		@include pagination-size(
						$space-xs * 2,
						$space-xs * 2,
						$font-size-md,
						$space-xs
		);

		margin-bottom: 0;
		justify-content: center;

		.page-item {
			margin: 0 $space-xs;

			.page-link {
				font-size: $font-size-sm;
				padding-top: $space-xs;
				padding-bottom: calc($space-xs / 2);
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				align-content: center;
				border-radius: 10px;

				.fa-ellipsis {
					padding: 4px 0;
					display: none;
				}

				.fa-chevron-left,
				.fa-chevron-right {
					font-size: $font-size-xs;
				}

				&:hover, &:focus {
					background: $mulberry-dark;
					color: $white;
				}
			}

			.next {
				border: none
			}

			.disabled {
				color: $grey-medium;
				background: none;
				border: transparent;
			}
		}

		@include media-breakpoint-between(xl, xxl) {
			.page-item {
				&:nth-child(n+6) {
					display: none;
				}


				&:last-child {
					display: inline-block;
				}

				&:nth-child(5) {
					.page-link {
						.pager-text {
							display: none;
						}
						.fa-ellipsis {
							display: block;
						}
					}
				}
			}
		}

		@include media-breakpoint-down(md) {
			.page-item {
				&:nth-child(n+6) {
					display: none;
				}


				&:last-child {
					display: inline-block;
				}

				&:nth-child(5) {
					.page-link {
						.pager-text {
							display: none;
						}
						.fa-ellipsis {
							display: block;
						}
					}
				}
			}
		}
	}
}

.btn-search {
	@include button-outline-variant($mulberry, $white);
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: $space;
	border-color: $grey-light;
	border-left: none;
	padding-top: 0;
	padding-bottom: 0;
}

//======================================================================//
//======================== <<<<< Steps >>>>> ===========================//
//======================================================================//

.steps {
	border-top: 0;
	padding-top: 0;
	margin-top: $space;

	.pagination {
		justify-content: center;

		.page-item {
			margin: $space-xs;

			.page-link {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				align-content: center;
				border-radius: 10px;
				color: $grey-dark;
				font-size: $font-size-md;
				padding: $space-sm $space-md;

				&:hover, &:focus {
					background: $mulberry-dark;
					color: $white;
				}

				&.active {
					color: $white;
				}
			}
		}
	}
}


.stepper-horizontal {
	background: $mulberry-dark;
	display: flex;
	align-items: center;

	li {
		margin: $space-md $space-md;
		list-style: none;
		font-size: $font-size-lg;
		border-radius: 50%;
		width: $space-lg;
		height: $space-lg;
		background-color: $mulberry;
		text-align: center;
		position: relative;

		a {
			text-decoration: none;
		}

		&.completed {
			a {
				color: $mulberry-lightest;
				font-size: $font-size-sm;
			}

			&:after {
				content: "";
				position: absolute;
				width: calc(100% + $space-xl);
				height: 6px;
				background-color: $mulberry;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&.active {
			width: $space-xl;
			height: $space-xl;
			border: 4px solid $mulberry;
			border-radius: 50%;
			background: $mulberry-light;

			a {
				color: $yellow-light;
				font-size: $font-size-xl;
			}

			&:after {
				content: "";
				position: absolute;
				width: calc(100% + $space);
				height: 2px;
				top: 50%;
				transform: translate(25%, -50%);
				background-image: linear-gradient(to right, $mulberry 50%, transparent 0%);
				background-position: 0 0;
				background-size: $space 2px;
				background-repeat: repeat-x;
			}
		}

		&.not-started {
			a {
				color: $yellow;
			}

			&:not(:last-child):after {
				content: "";
				position: absolute;
				width: calc(100% + $space);
				height: 2px;
				top: 50%;
				transform: translate(10%, -50%);
				background-image: linear-gradient(to right, $mulberry 50%, transparent 0%);
				background-position: 0 0;
				background-size: $space 2px;
				background-repeat: repeat-x;
			}
		}
	}
}

//======================================================================//
//======================== <<<<< Badges >>>>> ==========================//
//======================================================================//
.badge {
	border: none;
	background: none;
	font-size: $font-size-xxs;
	font-weight: $font-weight-normal;

	span {
		padding-right: $space-xs;
	}

	&.round {
		border-radius: 999px;
	}
}

//======================================================================//
//========================= <<<<< Icons >>>>> ==========================//
//======================================================================//
.icon {
	font-family: $font-icons;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-weight: var(--fa-style, 900);
	position: relative;
	font-style: normal;

	&::before {
		font-family: inherit;
	}
}

.icon-danger,
.icon-warning,
.icon-error {
	&::before {
		content: "\f071";
	}
}

.icon-success {
	&::before {
		content: "\f058"
	}
}

.icon-info {
	&::before {
		content: "\f06a";
	}
}

//======================================================================//
//======================== <<<<< Message >>>>> =========================//
//======================================================================//
.message {
	text-align: center;
	color: $grey-dark;
	line-height: 1.2;
	margin: $space-lg auto;

	.icon,
	h4 {
		color: $grey-dark;
	}

	.icon-loading {
		color: $blue;
	}

	.icon {
		font-size: $font-size-xxl * 1.5;
		width: $font-size-xxl * 1.5;
		height: $font-size-xxl * 1.5;
		line-height: $font-size-xxl * 1.5;
	}

	h4 {
		margin-top: $space;
	}
}


//======================================================================//
//================== <<<<< Loading Animations >>>>> ====================//
//======================================================================//
@keyframes loading {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.icon-loading {
	display: inline-block;
	animation-name: loading;
	animation-duration: 900ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.loading-container {
	&:before {
		content: '';
		background: transparentize($grey-lightest, 0.5);
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
		position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 11;
	}

	.loading-text {
		position: absolute;
			top: calc(50% + 3em);
			left: 50%;
			transform: translate(-50%, -50%);
		z-index: 12;
		text-transform: capitalize;
		font-weight: 500;
		color: $primary;
		font-size: $font-size-lg;
		// text-shadow: 0px 0px 6px transparentize($black, 0.5);
	}

	// Legacy Style
	.loading-image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 12;
		pointer-events: none;

		// Old Style - Add fa fa-spinner to JS component
		// .icon-loading {
		// 	width: 1.5em;
		// 	height: 1.5em;
		// 	line-height: 1.5em;
		// 	background: transparentize($white, 0.2);
		// 	// border: 1px solid $grey-dark;
		// 	box-shadow: 0px 0px 20px transparentize($black, 0.8);
		// 	color: $purple;
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	font-size: 3em;
		// 	border-radius: 999px;

		// 	&::before {
		// 		display: block;
		// 	}
		// }

		// New Style
		.icon-loading {
			margin: auto;
			border: 5px solid transparentize($grey-medium, 0.7);
			border-radius: 50%;
			border-top: 5px solid $primary;
			width: 60px;
			height: 60px;
			// box-shadow: 0px 0px 20px transparentize($black, 0.8);
		}
	}

	&.fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 12;

		@include media-breakpoint-up(lg) {
			z-index: 12;
		}
	}

	&.minimal {
		&:before {
			display: none;
		}

		.loading-image {
			top: initial;
			left: initial;
			right: $space-sm;
			bottom: $space-sm;
			transform: none;

			.icon-loading {
				width: 1.5em;
				height: 1.5em;
				line-height: 1.5em;
				font-size: 1.5em;
			}
		}

		.loading-text {
			position: absolute;
				top: initial;
				left: initial;
				right: calc($space-lg + 1.5em);
				bottom: calc($space - 2px);
				transform: none;
			font-size: $font-size-std;
			text-align: right;
		}
	}
}

//======================================================================//
//======================== <<<<< Modals >>>>> ==========================//
//======================================================================//
.modal {
	.modal-header {
		position:relative;

		.btn-close {
			position: absolute;
			right: $space-md;
			top: $space-md;
		}
	}

	.modal-title {
		font-weight: 700;
		font-size: $font-size-lg;
		padding-right: $space-xl;

		@include media-breakpoint-up(lg) {
			font-size: $font-size-xl;
			padding-right: 0;
		}
	}

	.modal-footer {
		margin-top: $space-lg;
		padding-top: calc($space*0.6666666667);
		padding-left: 0;
		padding-right: 0;
	}

	// Remove the padding on the modal footer when it exists in the modal body, required for VueJS based modals
	.modal-body .modal-footer {
		padding-bottom: 0;
	}

	&.modal-article-detail,
	&.modal-insights-video {
		video {
			max-width: 100%;
			height: auto;
		}

		.modal-footer {
			margin-top: 0;
			padding: $space $space-lg;
		}
	}
}

.modal-backdrop {
	@media (max-width: 375px){
		min-width: 375px;
		min-height: 100%;
	}
}
//======================================================================//
//===================== <<<<< Tooltip Pills >>>>> ======================//
//======================================================================//

.tooltip-pill {
	display: inline-block;
	position: relative;

	.btn-tooltip-pill {
		@include button-variant($white-off, $grey-lightest, $primary, $mulberry-white, $mulberry-dark, $mulberry-dark, $mulberry-dark );
		border-radius: 99px;
		padding: $space-xs $space 3px $space-xs;
		line-height: 1;
		position: relative;
			z-index: 2;

		i {
			color: inherit;
			font-size: $font-size-md;
		}

		.text {
			position: relative;
				top: -2px;
		}
	}

	.btn-tooltip-close {
		background: $grey-lightest;
		border-radius: 99px;
		color: $grey-darkest;
		position: absolute;
			top: $space-xs;
			right: $space-xs;
			padding: 0;
		width: 36px;
		height: 36px;
		line-height: 1;
		text-align: center;

		&:hover {
			background: $grey-ultralight;
			color: $error;
			cursor: pointer;
		}

		.fas {
			position: relative;
			padding-right: 0;
		}
	}

	.tooltip-content {
		display: none;
		border: 1px solid $border-light;
		border-radius: 20px 20px $border-radius $border-radius;
		padding: $space-xxl $space-lg $space-lg;
		position: absolute;
			top: -$space-xs;
			left: -$space-xs;
			z-index: 1;	
		background: $white;
		box-shadow: 0px 0px 10px transparentize($black, 0.9);
		width: 350px;

		p {
			margin-bottom: 0;
		}
	}

	&.active {
		.tooltip-content {
			display: block;
		}
	}

	&.right {
		.btn-tooltip-close {
			right: auto;
			left: $space-xs;
		}

		.tooltip-content {
			left: auto;
			right: -$space-xs;
		}
	}

	@include media-breakpoint-up(md){
		.tooltip-content {
			width: 400px;
		}
	}

	@include media-breakpoint-up(md){
		.tooltip-content {
			width: 600px;
		}
	}
}

// Buttons used in the Booking Flow for Next and Back

.btn-no-outline {
	@include button-variant(
					$white-off,
					transparent,
					$primary,
					$mulberry-lightest,
					$mulberry-lightest,
					$mulberry-darkest, 
					$white-off,
					$primary,
					$primary,
	)
}

.btn-outline-primary-offwhite {
	@include button-variant(
					$white-off,
					$primary,
					$primary,
					$mulberry-lightest,
					$mulberry-lightest,
					$mulberry-darkest,
					$white-off,
					$primary,
					$primary,
	)
}

//======================================================================//
//===================== <<<<< Step Counters >>>>> ======================//
//======================================================================//

//NOTE: Step counter is used in Progress Tracker element below.

.section-steps,
.section-steps-top {
	&.dark {
		background: $mulberry-dark;
		padding-top: $space-sm;
		padding-bottom: $space-sm;

		.container-steps-overflow {
			overflow-x: auto;
			position: relative;

			.nav-steps-static {
				li {
					flex: 1 0 100px;

					&.last-step {
						flex: 1 1 auto;
						padding-right: $space-sm;

						&:after {
							border: none;
						}
					}
				}
			}
		}
	}

	.col-steps-label {
		display: flex;
		align-items: center;
		margin-bottom: $space-sm;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
}

.nav-steps-static {
	font-family: $font-foco;
	ul {
		display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: flex;
			flex: 1 1 auto;
			align-items: center;
		position: relative;

		&.last-step{
			flex: 0 0 auto;
		}

		&:after {
			content: ' ';
			display: block;
			border-bottom: 1px dashed $primary;
			position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			z-index: 0;
		}

		&.completed {
			&:after {
				border-bottom-style: solid;
				border-bottom-width: 6px;
			}
		}
	}

	.step-marker {
		$size: 30px;
		width: $size;
		height: $size;
		background: $primary;
		border-radius: 999px;
		color: $yellow;
		display: flex;
			justify-content: center;
			align-items: center;
		font-size: $font-size-md;
		line-height: 1;
		text-align: center;
		position: relative;
		z-index: 1;

		&.active {
			$size: 47px;
			border: 4px solid $primary;
			background: $mulberry-light;
			color: $white;
			font-size: $font-size-xl;
			width: $size;
			height: $size;
		}
	}
}

//======================================================================//
//=================== <<<<< Progress Tracker >>>>> =====================//
//======================================================================//
.section-progress-tracker {
	margin: 0 auto $space-xxl;
	width: 100%;
	display: none;

	& > .container {
		border-radius: $border-radius-lg;
		background-color: $grey-ultralight;
		padding: $space-lg $space-xxl;

		@include media-breakpoint-down(sm) {
			padding: $space;
			max-width: 95%;
		}
	}

	.container-steps-overflow {
		overflow-x: auto;
		position: relative;

		&.nav-steps-static {
			li {
				flex: 1 0 100px;

				&.last-step {
					flex: 1 1 auto;
					padding-right: $space-sm;

					&:after {
						border: none;
					}
				}
			}
		}
	}

	.progress {
		height: calc($space * 0.4444444444);
		margin: $space-sm 0;
	}

	.section-header {
		width: 100%;
	}

	.progress-title {
		font-weight: 600;
		font-size: $font-size-sm;
		display: block;
		text-align: center;
		line-height: 1.2;
	}

	&.bottom {
		display: block;
		margin-bottom: 0;

		> .container {
			max-width: 100%;
			border-radius: 0;
		}

		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		display: block;

		.progress-title {
			text-align: left;
		}
	}
}

//======================================================================//
//=================== <<<<< Generic Elements >>>>> =====================//
//======================================================================//

// Images
img {
	max-width: 100%;
}

// Iframs
iframe.activity-content-frame {
	width: 100%;
	min-height: 600px;
	border: 1px solid $border-light;
}

//======================================================================//
//======================== <<<<< Helpers >>>>> =========================//
//======================================================================//

/**
 * Toggle-able children
 */
.toggle-parent {
	&.hidden {
		.toggle-target {
			display: none;
		}
	}
}

//======================================================================//
//=============== <<<<< Resource Duration Element >>>>> ================//
//======================================================================//
.resource-duration,
.event-btn {
	font-size: $font-size-xxs;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-radius: 40px;
	overflow: hidden;
	padding: $space-xs $space $space-xs $space-xs;
	display: inline-flex;
	align-items: center;
	color: $white;
	background-color: $success;
	transition-duration: 0.3s;

	i {
		&::before {
			position: relative;
				top: 1px;
		}
	}

	.fa-solid,
	.fa-regular {
		font-size: $font-size-xs;
		overflow: hidden;
		border-radius: 50%;
		background-color: $white;
		padding: $space-xs 6px $space-xs $space-xs;
		color: $success;
		margin-right: $space-sm;
	}

	.fa-play {
		font-size: $font-size-xxs;
		padding: $space-xs 7px $space-xs 7px;
		margin-right: 7px;
	}

	.fa-arrow-right {
		margin-right: $space-xs;
	}
}

.resource-duration {
	&:has(.fa-arrow-right) {
		padding-right: calc($space * 0.6666666667);
	}
}

.event-btn {
	&:hover {
		background-color: darken($success, 5);
	}
}

//======================================================================//
//================ <<<<< HOMEWEB BANNER FEATURE >>>>> ==================//
//======================================================================//
.banner-feature {
	position: relative;
	color: $white;

	@include media-breakpoint-up(lg) {
		padding: $space-xxl 0;
	}

	.banner-background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.banner-overlay {
		content: '';
		position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
		background: linear-gradient(120deg, rgba(0, 108, 149, 1), rgba(83, 30, 82, 1));
		opacity: 0.75;
	}

	.banner-inner {
		position: relative;
		z-index: 1;
	}

	.resource-category {
		font-weight: 600;
		text-transform: uppercase;
		display: block;
		// margin-bottom: $space-md;
	}

	.title {
		font-weight: 600;
		display:block;
	}

	.column-text {
		padding: $space-lg;
		background-color: transparentize($black, 0.7)
	}

	&.banner-contained {
		padding: 0;

		.column-text {
			background-color: transparent;
		}

		@include media-breakpoint-up(md){
			padding: $space-xxl;
		}
	}

	.column-button {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		.btn {
			display: none;
			align-items: center;
			justify-content: center;
			margin-top: $space;
			margin-bottom: $space;

			@include media-breakpoint-up(md) {
				display: flex;
				margin-right: $space-xl;
				margin-bottom: $space-xl;
			}

			@include media-breakpoint-up(lg) {
				margin-right: 0;
				margin-bottom: 0;
			}

			i {
				margin-left: $space;
			}
		}
	}

	.resource-duration {
		&:hover {
			background-color: darken($success, 5%);
		}
	}
}

//======================================================================//
//================ <<<<< HOMEWEB BANNER TWO TILE >>>>> =================//
//======================================================================//
.banner-two-tile {
	position: relative;

	.container {
		position: relative;
	}

	.banner-tile {
		position: relative;
		text-decoration: none;
		color: $white;
		margin-bottom: calc($space * 1.3333333333);
		min-height: 200px;

		.banner-inner {
			box-shadow: 0px 3px 6px 0px $grey-light;
			position:relative;
			padding: $space-xl;
			height: 100%;
			min-height: 300px;
		}

		.banner-background,
		.banner-overlay {
			z-index: 0;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.banner-overlay {
			transition-duration: 0.3s;
			background-color: transparentize($black, 0.5);
			z-index: 1;
		}

		.banner-content {
			position: relative;
			z-index: 2;
			height: 100%;
			display: flex;

			.h1 {
				@extend .h2;
				font-weight: 600;
				text-align: left!important;
			}
		}

		&:hover {
			.banner-overlay {
				background-color: transparentize($black, 0.9);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.banner-tile {
			min-height: unset;
		}
	}
}

//======================================================================//
//============ <<<<< SENTIO ASSESSMENT HELP CONTENT >>>>> ==============//
//======================================================================//
.helper-content {
	margin-top: $space-xl;
	padding: $space-xl;
	background-color: $white;
	box-shadow: $shadow-default;

	@include media-breakpoint-up(xl){
		margin-top: 0;
	}
}

//======================================================================//
//============== <<<<< Homeweb Article Iframe >>>>> ====================//
//======================================================================//
.iframeWrapper {
	position: relative;
	padding-bottom: 59.25% /* 16:9 */;
	padding-top: 25px;
	height: 0px;
	margin: $space-xl 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

//======================================================================//
//======================== <<<<< Popover >>>>> =========================//
//======================================================================//
.popover-body {
	box-shadow: 0px 0px 10px transparentize($black, 0.7);
	border-radius: $border-radius-lg;
}

//======================================================================//
//=============== <<<<< Homeweb DSG Content Footer >>>>> ===============//
//======================================================================//
.section-dsg-content-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	margin: auto 0 0;

	@include media-breakpoint-up(md) {
		flex-flow: row;
		justify-content: flex-end;
	}

	a {
		text-decoration: none;
		order: 2;
		margin-top: $space;

		&:hover {
			text-decoration: underline;
		}

		@include media-breakpoint-up(md) {
			margin-top: 0;
			margin-right: $space-lg;
			order: 1;
		}
	}

	picture {
		display: block;
		width: 100%;
		max-width: 250px;
		height: auto;
		order: 1;

		@include media-breakpoint-up(md) {
			order: 2;
		}
	}
}

//======================================================================//
//================== <<<<< Accordions - Filters >>>>> ==================//
//======================================================================//
.accordion.container-filters {
	// background: $white-off;
	border-radius: $border-radius-lg;
	padding: 0 $space-sm $space-md;
	margin-bottom: $space-lg;

	.accordion-item {
		background: none;
		border-width: 0 0 0;
		border-radius: 0!important;

		&:has(.collapsed){
			.accordion-footer {
				display: block;
			}
		}
	}

	.accordion-header {
		
	}

	.accordion-button {
		background: none;
		font-weight: 600;
		padding: $space-sm;
		border-radius: 0px!important;
	}

	.accordion-body {
		border-bottom-width: 0px;
		padding: $space-sm;
	}

	.accordion-footer {
		display: none;
		position: relative;
			top: calc(-1 * $space-xs);
		padding: 0 0 0 $space-sm;
		margin-bottom: $space-xs;

		.badge {
			margin-right: $space-xs;
			line-height: 1.1;
		}
	}

	.form-group {
		margin-bottom: $space-xs;
	}

	.form-check-inline {
		margin-right: 0;
	}

	@include media-breakpoint-down(lg) {
		margin-bottom: $space-xl;
		max-height: 60vh;
		overflow: scroll;

		& > *:last-child{
			// margin-bottom: $space-lg;
		}
	}
}

.page-mobile-safari {
	.accordion.container-filters {
		@include media-breakpoint-down(lg) {
			max-height: 80%;
		}
	}
}