//
// (C) Copyright 2024 by Homewood Health Inc.
//
// $File: _ring-central.scss
// $Purpose: Styles relating to ring central style overrides
//

//#cxone-guide-container .be-right.svelte-9s74ur {
//	bottom: 2em!important;
//	right: calc(2em + 5px)!important;
//}

#cxone-guide-container .icon.svelte-vefknh {
	background-color: #6C62A5!important;
}

#cxone-guide-container .header.svelte-zqjuvg.svelte-zqjuvg,
#cxone-guide-container .header.svelte-ylo5wi {
	background-color: #78356A!important;
}

#cxone-guide-container button.svelte-miju2q {
	background-color: #6C62A5!important;
	color: #ffffff!important;
}

#cxone-guide-container .entrypoint.simpleLabelMode.svelte-1ocxzmo .name {
	color: #ffffff!important;
	margin-left: 5px!important;
}

#cxone-guide-container .circle.svelte-1vjbgz1 {
	border-top-color:#78356A!important;
	border-right-color:transparent!important;
	border-bottom-color:#78356A!important;
	border-left-color:#78356A!important;
}

#cxone-guide-container .svelte-5uom1x {
	background-color: #78356A!important;
	color: #ffffff!important;
}

#cxone-guide-container button.svelte-5uom1x.svelte-5uom1x {
	background-color: #6C62A5!important;
	color: #ffffff!important;
}

#cxone-guide-container button.svelte-5uom1x,
#cxone-guide-container .precontact-btn.svelte-1sskk24 {
	background-color: #78356A!important;
	color: #ffffff!important;
}

#cxone-guide-container div.agent.svelte-qdgzax {
	background-color: #6A6A6A!important;
	color: #C0C0C0!important;
}

.page-mobile-safari {
	#cxone-guide-container .svelte-1ocxzmo .name {
		display: inline-flex!important;
		align-items: center!important;
	}
}

//======================================================================//
//== <<<<< Ring Central Styles - Overwritten in chat tool below >>>>> ==//
//======================================================================//
:root {
	--chat-shape-borderRadius: 8px;
	--chat-chatCustomization-colors-buttonColor: #78356A;
	--chat-chatCustomization-colors-headerBgColor: #78356A;
	--chat-chatCustomization-colors-headerFontColor: #ffffff;
	--chat-chatCustomization-colors-secondaryMessageBgColor: rgb(229, 240, 244);
	--chat-chatCustomization-colors-secondaryMessageFontColor: #000000;
	--chat-chatCustomization-colors-themeColor: #78356A;
	--chat-chatCustomization-colors-widgetBgColor: #78356A;
	--chat-chatCustomization-colors-widgetFontColor: #000000;
	--chat-palette-primary-main: #78356A;
	--chat-palette-primary-light: #A26094;
	--chat-palette-primary-dark: #531E52;
	--chat-palette-primary-contrastText: #fff;
	--chat-palette-primary-mainChannel: 0 108 149;
	--chat-palette-primary-lightChannel: #A26094;
	--chat-palette-primary-darkChannel: #531E52;
	--chat-palette-primary-contrastTextChannel: 255 255 255;
	--chat-palette-secondary-main: #78356A;
	--chat-palette-secondary-light: #A26094;
	--chat-palette-secondary-dark: #531E52;
	--chat-palette-secondary-contrastText: #fff;
	--chat-palette-secondary-mainChannel: 0 108 149;
	--chat-palette-secondary-lightChannel: #A26094;
	--chat-palette-secondary-darkChannel: #531E52;
	--chat-palette-secondary-contrastTextChannel: 255 255 255;
	--chat-palette-common-black: #000;
	--chat-palette-common-white: #fff;
	--chat-palette-common-background: #fff;
	--chat-palette-common-onBackground: #000;
	--chat-palette-common-backgroundChannel: 255 255 255;
	--chat-palette-common-onBackgroundChannel: 0 0 0;
}

#be-app .Header_Header__Z9Z4k {
	background-color: #78356A!important;
	color: #ffffff!important;
}

#be-app .Button_Button__KNUP1 {
	background-color: #78356A!important;
	color: #ffffff!important;
}

#be-app .MinimizeWindowButton_Button__BA-Jc {
	color: #ffffff!important;
}

#be-app .ActionPanel_Icon__vTr4d {
	color: #ffffff!important;
}

#be-app .EndSession_Button__PoyFC {
	color: #3F357A!important;
	text-decoration: underline!important;
}

#be-app .EndSession_Button__PoyFC .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.dfo-chat-window-styles-vubbuv {
	fill: #3F357A!important;
}

#be-app .ConnectionStatus_ConnectionStatus__3b8LU {
	background-color: #531E52!important;
	color: #ffffff!important;
}

#be-app .ConnectionStatus_Refresh__XRy-1 {
	color: #ffffff!important;
}

#be-app .ConnectionStatus_Refresh__XRy-1 {
	fill: #ffffff!important;
}

#be-app .ConnectionStatus_IconDisplayChat__99HOC {
	background-color: #531E52!important;
	color: #ffffff!important;
	fill: #ffffff!important;
}

#be-app .Avatar_TooltipText__08P3G {
	background-color: #3F357A!important;
	color: #ffffff!important;
}

#be-app .MessageContent_Bubble__v7T0J {
	background-color: #F9F9F9!important;
	color: #000!important;
}

#be-app .MessageContent_isCustomer__yOcm- .MessageContent_Bubble__v7T0J {
	background-color: #f2e3ee!important;
	color: #531E52!important;
}