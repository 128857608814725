//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _alerts.scss
// $Purpose: Defines styles for alerts and notification components
//

.alert {
    padding: 0;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $white;
        border-radius: $border-radius;
        z-index: 0;
    }

    .alert-inner {
        color: $body-color;
        position: relative;
        z-index: 1;
        line-height: 1.2;
        display: flex;
        background: transparent linear-gradient(165deg, transparentize($info, 0.8) 0%, $white 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(165deg, transparentize($info, 0.8) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        border: 1px solid $info-border;
        border-radius: $border-radius;
        box-shadow: 0px 0px 10px transparentize($black, 0.7);
        padding: $space-sm;
    }

    .btn-alert-close {
        background: $grey-lightest;
        border-radius: 99px;
        color: $grey-darkest;
        position: absolute;
        top: $space-sm;
        right: $space-sm;
        padding: 0;
        width: 36px;
        height: 36px;
        line-height: 1;
        text-align: center;

        &:hover,
        &:focus {
            background: $grey-ultralight;
            color: $error;
            cursor: pointer;
        }

        .fas {
            position: relative;
            padding-right: 0;
        }
    }

    .alert-status {
        background: $info;
        border-radius: $border-radius;
        color: $white;
        font-size: $font-size-xxl;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $space-sm;
        padding: $space-sm;
        width: $space * 3.8;
        height: $space * 3.8;
    }

    .alert-message {
        overflow-wrap: break-word;
    }

    .wrapper {
        flex: 0 1 calc(100% - 112px - $space-sm);
        max-width: calc(100% - 112px - $space-sm);
    }

    .status {
        color: $info;
        font-size: $font-size-sm;
        font-weight: 600;
    }

    &.alert-info {
        .alert-inner {
            background: transparent linear-gradient(165deg, transparentize($info, 0.8) 0%, $white 100%) 0% 0% no-repeat padding-box;
            background: transparent linear-gradient(165deg, transparentize($info, 0.8) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
            border-color: $info-border;
        }

        .alert-status {
            background: $info;
        }
        .status {
            color: $info;
        }
    }

    &.alert-success {
        .alert-inner {
            background: transparent linear-gradient(165deg, transparentize($success, 0.8) 0%, $white 100%) 0% 0% no-repeat padding-box;
            background: transparent linear-gradient(165deg, transparentize($success, 0.8) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
            border-color: $success-border;
        }

        .alert-status {
            background: $success;
        }
        .status {
            color: $success;
        }
    }

    &.alert-warning {
        .alert-inner {
            background: transparent linear-gradient(165deg, transparentize($warning, 0.8) 0%, $white 100%) 0% 0% no-repeat padding-box;
            background: transparent linear-gradient(165deg, transparentize($warning, 0.8) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
            border-color: $warning-border;
        }

        .alert-status {
            background: $warning;
        }
        .status {
            color: $warning;
        }
    }

    &.alert-error {
        .alert-inner {
            background: transparent linear-gradient(165deg, transparentize($error, 0.8) 0%, $white 100%) 0% 0% no-repeat padding-box;
            background: transparent linear-gradient(165deg, transparentize($error, 0.8) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
            border-color: $error-border;
        }

        .alert-status {
            background: $error;
        }
        .status {
            color: $error;
        }
    }
}



.alert-banner {
    background: $gradient-banner-alert;
    position: relative;
    z-index: 12;

    .banner-inner {
        // background: red;
        // text-align: center;
        padding-top: $space-xs;
        padding-bottom: $space-xs;
        font-size: $font-size-xs;
        text-align: center;
        line-height: 1.15;
    }

    .btn {
        margin-right: $space-xs;
    }

    span {
        display: block;
    }

    @include media-breakpoint-up(md){
        .banner-inner {
            text-align: left;
        }

        span {
            display: inline-block;
        }
    }

    @include media-breakpoint-up(xxl){
        .container-fluid {
            max-width: $container-header-xxl;
        }
    }

    &.warning {
        background: transparentize($warning, 0.6);
        color: darken($yellow-dark, 20%);
    }

    &.error, &.danger {
        background: transparentize($danger, 0.8);
        color: darken($danger, 20%);
    }
}