//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _colours.scss
// $Purpose: Defines all of the basic colour properties for the project
//

// Colours: Primary
$green-lightest:	#D8FFF2;
$green:				#11B37D;
$green-dark:		#0D825B;
$green-darkest:		darken($green-dark, 10%);
$yellow-green:		#8b9c20;
$yellow-lightest:	#FEF9F2;
$yellow-light:		#FDF3E5;
$yellow:			#F5B356;
$yellow-dark:		#CF7E0C;
$red:				#B3113C;
$fuscia:			#B31166;
$mulberry-white:	#F7F6F7;
$mulberry-lightest:	#DDA9D2;
$mulberry-light:	#A26094;
$mulberry:			#78356A;
$mulberry-dark:		#531E52;
$mulberry-darkest:	#2E063A;
$purple-light:		#6C62A5;
$purple:			#3F357A;
$purple-dark:		#241E45;
$blue:				#006C95;

// Colours: Neutrals
$black:				#000000;
$charcoal:			#292929;
$grey-darkest:		#474747;
$grey-dark:			#6A6A6A;
$grey-medium:		#A2A2A2;
$grey:				#C0C0C0;
$grey-light:		#D2D2D2;
$grey-lightest:		#E7E7E7;
$grey-ultralight:	#F3F3F3;
$white-off:			#F9F9F9;
$white:				#FFFFFF;

// Alert Colours and Borders
$error:				$red;
$error-border:		transparentize($red, 0.5);
$info:				$purple-light;
$info-border:		transparentize($purple, 0.5);
$warning:			$yellow;
$warning-border:	transparentize($yellow, 0.5);
$success:			$green;
$success-border:	transparentize($green, 0.5);

// Brand Colours
$brand-primary:		$mulberry;
$brand-secondary:	$purple;
$sc-blue:           #005596;

// Borders
$border-dark:		$grey;
$border-light:		$grey-lightest;
$border-width:		1px;

// Shadows
$shadow-text: 0px 3px 6px transparentize($black, 0.2);
$shadow-default: 0px 0px 3px transparentize($black, 0.8);
$shadow-navbar: 0px 3px 6px transparentize($black, 0.94);
$shadow-dropdown: 0px 3px 6px transparentize($black, 0.84);

// Overrides: Bootstrap
$primary:			$brand-primary;
$secondary:			$brand-secondary;
$success:			$green-dark;
$info:				$purple-light;
$warning:			$yellow;
$danger:			$red;

$body-color:				$black;
$link-color:				$mulberry;

// form input
$input-placeholder-color:   $grey;
$input-border-color:        $grey-light;

// Range Slider
$form-range-thumb-bg:			$charcoal;
$form-range-thumb-box-shadow:	0 .1rem 2rem rgba($black, 0.5);

// Accordions
$accordion-icon-color:				$mulberry;
$accordion-button-active-bg: 		$yellow-light;
$accordion-border-color:			$border-light;

// Header
$gradient-nav-dropdown: $white linear-gradient(170deg, $white 30%, $grey-ultralight 100%) 0% 0% no-repeat padding-box;
$gradient-dark-mulberry: transparent linear-gradient(142deg, $mulberry-darkest 0%, $mulberry 100%) 0% 0% no-repeat padding-box;
$gradient-dark-to-light-mulberry: transparent linear-gradient(142deg, $mulberry 0%, $mulberry-darkest 100%) 0% 0% no-repeat padding-box;
$gradient-excellent: $green linear-gradient(150deg, $green 0%, $green-dark 100%) 0% 0% no-repeat padding-box;
$gradient-good: $yellow-green linear-gradient(150deg, $green 0%, $yellow-green 80%) 0% 0% no-repeat padding-box;
$gradient-getting-by: $yellow linear-gradient(150deg, lighten($yellow, 5%) 0%, darken($yellow, 15%) 100%) 0% 0% no-repeat padding-box;
$gradient-not-good: #d7724c linear-gradient(150deg, lighten(#d7724c, 10%) 0%, darken(#d7724c, 10%) 100%) 0% 0% no-repeat padding-box;
$gradient-in-crisis: $red linear-gradient(150deg, lighten($red, 10%) 0%, $red 100%) 0% 0% no-repeat padding-box;
$gradient-banner-alert: linear-gradient(90deg, transparentize(darken($mulberry-white, 0.4), 0.6) 0%, transparentize($mulberry-lightest, 0.6) 100%) 0% 0% no-repeat padding-box;