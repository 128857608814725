//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _settings.scss
// $Purpose: Defines all of the basic stylistic properties for the project
//

// Fonts
$font-source-sans: 'Source Sans 3', sans-serif;
$font-foco: 'foco', sans-serif;
$font-awesome:  var(--fa-style-family, "Font Awesome 6 Free");

$font-standard: $font-source-sans;
$font-accent: $font-foco;
$font-icons: $font-awesome;

// Spacing
$space:			18px;
$space-xs:		5px;        		// 5px
$space-sm:		calc($space/2);		// 9px
$space-md:		$space*1.33;    	// 20px
$space-lg:		$space*1.77;	    // 32px
$space-xl:		$space*2.44;	    // 48px
$space-xxl:		$space*2.88;	    // 64px
$space-xxxl:	$space*4;		    // 80px

// Font Sizes
$font-size-std:          1.125rem;			// 18px
$font-size-xxs:      $font-size-std * 0.66;		// 12px
$font-size-xs:       $font-size-std * 0.77;		// 14px
$font-size-sm:       $font-size-std * 0.88;		// 16px
$font-size-md:       $font-size-std * 1.11;		// 20px
$font-size-lg:       $font-size-std * 1.22;		// 22px
$font-size-xl:       $font-size-std * 1.33;		// 24px
$font-size-xxl:      $font-size-std * 2.33;		// 42px
$font-size-xxxl:     $font-size-std * 2.88;		// 52px

// Widths and Heights
$device-xs:				340px;
$nav-height-desktop:	80px;
$nav-height-mobile:		70px;
$breadcrumb-height:		42.38px;
$footer-height-lg:		247.48px;
$footer-height-xxl:		228.52px;
$preview-pill:          calc($space*2);


// Containers
$container-xs:			500px;
$container-sm:			738px;
$container-header-xxl:	1600px;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 960px,
//   xxl: 960px
// );

// CDN
$url-cdn-common: 'https://homewood-cdn.s3.ca-central-1.amazonaws.com';
$url-cdn: '#{$url-cdn-common}/client';
$url-cdn-images: '#{$url-cdn}/images';
$url-cdn-logo: '#{$url-cdn-common}/images/logo';

// Fonts
$font-size-base:			$font-size-std;
$font-family-base:			$font-standard;

// Borders
$border-radius:				8px;							// 8px
$border-radius-xs:			calc($border-radius/2);			// 4px
$border-radius-md:			calc($border-radius/1.333333);	// 6px
$border-radius-lg:			18px;							// 18px
$border-radius-xl:			calc($border-radius*3.75);		// 30px

// Modals
$modal-content-border-radius:	$border-radius-xl;
$modal-backdrop-opacity:	0.7;
$modal-header-padding: 		$space-lg $space-lg calc($space*0.6666666667);
$modal-inner-padding:		$space-lg;

// Buttons
$btn-border-radius-lg: $border-radius;
$input-btn-line-height: 1.2;

// Bootstrap Overrides
$navbar-toggler-focus-width: 	calc($space-xs/2);

// form inputs
$input-border-radius:       $border-radius;
$form-range-thumb-width:  	1.5rem;