//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _header.scss
// $Purpose: Styles relating to the header
//

#header {
	font-size: $font-size-sm;
	border-bottom: 1px solid $border-light;
	box-shadow: $shadow-navbar;
	position: relative;
	z-index: 10;
	background-color: $white;

	.container-fluid {
		height: $nav-height-mobile;
	}

	& > nav {
		padding: 0;
	}

	.col-logo {
		height: 100%;
		max-width: calc($nav-height-mobile - $space-xs);
		margin-right: $space-xs;
	}

	.navbar-brand {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: $space-xs 0;
		height: 100%;

		.logo {
			& > * {
				object-fit: contain;
				// width: auto!important;
				max-height: 100%;
			}

			.logo-mobile {
				height:  calc($nav-height-mobile - $space-xs);
			}
		}
	}

	.navbar-site-title {
		display: inline-block;
		color: $grey-darkest;
		font-family: $font-standard;
		font-weight: bold;
		font-size: $font-size-md;
		padding: 0 $space-sm 0 0;
	}

	.navbar-toggle {
		justify-self: flex-start;
		// margin-left: $space-xs;
		padding-left: $space-sm;
		padding-right: $space-sm;

		.icon {
			font-size: $font-size-std;
			//top: 1px;
			margin-right: $space-xs;
		}

		&:not(.collapsed) {
			color: $red;

			.icon {
				&::before {
					content: "\f00d"!important;
				}
			}
		}
	}

	.col-nav {
		height: 100%;
	}

	.col-nav-primary {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: static;
	}

	.col-nav-secondary {
		flex: 0 0 auto;
	}

	.btn {
		.icon {
			font-size: $font-size-xs;
		}
	}

	.btn-nav-item {
		font-size: inherit;

		&:hover,
		&:focus,
		&.show {
			background-color: $mulberry-lightest;
			border-color: $mulberry-lightest;
			color: $mulberry-darkest;
		}
	}

	@include media-breakpoint-up(md){
		.container-fluid {
			height: $nav-height-desktop;
			padding-left: calc($space + 2px);
			padding-right: calc($space + 2px);
		}

		.col-nav-primary {
			display: flex;
			flex-wrap: nowrap;
			position: relative;
		}

		.col-logo {
			margin-right: 0;
		// 	max-width: 200px;
		}

		.navbar-site-title {
			font-size: $font-size-md;
			padding: 0 $space 0 $space-xs;
		}

		.navbar-nav {
			position: relative;
		}

		.nav-item {
			position: relative;
		}
	}

	@include media-breakpoint-up(lg){
		.navbar-site-title {
			font-size: calc($font-size-xl * 1.2);
			line-height: 1.2;
			padding-right: $space;
		}

		.navbar-toggle {
			display: none;
		}
	}

	@include media-breakpoint-up(xxl){
		.col-logo {
			margin-right: $space-md;
			max-width: 200px;
		}

		.container-fluid {
			//max-width: $container-header-xxl;
		}
	}

	@include media-breakpoint-up(md){
		&.header-public {
			.col-logo {
				margin-right: $space-md;
				max-width: 200px;
			}
		}
	}
}

#navigation-primary {
	background: $gradient-nav-dropdown;
	box-shadow: $shadow-dropdown;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;

	.nav-item {
		border-bottom: 1px solid $border-light;
		position: relative;

		&:last-child {
			border-bottom: none;
		}

		& > a {
			display: inline-block;
			color: $body-color;
			padding: $space-sm $space-md;

			&:after {
				content: " ";
				display: block;
				width: 100%;
				height: 2px;
				background: $mulberry-dark;
				opacity: 0;
				transition-duration: 0.2s;
			}

			&:hover,
			&:focus {
				color: $mulberry-dark;
				&:after {
					opacity: 1;
				}
			}
		}

		.dropdown-toggle {
			i {
				font-size: 0.8em;
				margin-left: $space-xs;
			}

			&:after {
				display: none;
			}

			.text {
				position: relative;
				&:after {
					content: " ";
					display: block;
					width: 100%;
					height: 2px;
					background: $mulberry-dark;
					opacity: 0;
					transition-duration: 0.2s;
					position: absolute;
				}
			}

			&:hover,
			&:focus {
				.text:after {
					opacity: 1;
				}
			}
		}

		&.active {
			background: $gradient-nav-dropdown;

			a {
				color: $mulberry-dark;
				&:after {
					opacity: 1;
				}
			}

			.dropdown-toggle {
				.text:after {
					opacity: 1;
				}
			}
		}
	}

	// Single Nav Items
	.length-1 {
		.nav-item {
			.dropdown-toggle {
				.text {
					&:after {
						opacity: 0;
					}
					&:hover:after {
						opacity: 1;
					}
				}
			}

			&.active {
				background: none;
			}
		}
	}

	.dropdown-menu:not(.dropdown-menu-rounded) {
		background: $gradient-nav-dropdown;
		font-size: inherit;
		max-height: 50vh;
		overflow: auto;

		li {
			border-bottom: 1px solid $border-light;
			font-size: inherit;
			position: relative;

			&:last-child {
				border-bottom: none;
			}

			&.active {
				a {
					color: $mulberry;
					text-decoration: underline;

					&:before {
						width: 7px;
						// height: 100%;
						background: $gradient-dark-mulberry;
					}
				}
			}
		}

		a {
			font-size: inherit;
			display: block;
			padding: $space-sm $space;
			transition-duration: 0.2s;

			&:before {
				content: " ";
				display: block;
				background: transparent;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 0px;
				// height: 0px;
				pointer-events: none;
				transition-duration: 0.2s;
			}

			&:hover,
			&:focus {
				color: $mulberry;
				text-decoration: underline;

				&:before {
					width: 7px;
					// height: 100%;
					background: $gradient-dark-mulberry;
				}
			}

			&:active {
				color: $white!important;
			}
		}
	}

	@include media-breakpoint-down(lg){
		.dropdown-menu:not(.dropdopdown-menu-rounded) {
			position: relative;
			background: $grey-ultralight;
			border-radius: 0;
			border-width: 1px 0px 1px;
			border-color: $grey-ultralight;
			box-shadow: none;
			padding: 0 $space;
		}

		.dropdown-item {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 90vw;
		}
	}

	@include media-breakpoint-up(lg){
		height: 100%;
		background: transparent;
		box-shadow: none;
		position: relative;
		top: initial;
		left: initial;
		right: initial;

		.navbar-nav {
			// background: #c0f;
			height: 100%;
		}

		.nav-item {
			border-bottom: none;
			display: flex;
			align-items: center;

			& > a {
				display: inline-block;
				color: $body-color;
				padding: $space-sm $space-sm $space-sm;
			}
		}

		.dropdown-menu:not(.dropdopdown-menu-rounded) {
			margin-top: 0;
			border: 1px solid $border-light;
			border-radius: 0;
			box-shadow: $shadow-dropdown;
			padding: 0;
		}
	}

	@include media-breakpoint-up(xl){
		.nav-item {
			& > a {
				padding-left: calc($space-sm*1.25);
				padding-right: calc($space-sm*1.25);
			}
		}
	}
}

#navigation-secondary {
	.navbar-nav {
		// background: #f0c;
		flex-direction: row;
		justify-content: flex-end;
		height: 100%;
	}

	.nav-item {
		// background: #0cf;
		display: flex;
		align-items: center;
	}

	.dropdown-menu-rounded {
		background: $gradient-nav-dropdown;
		margin-top: $space-sm;
		position: absolute;
		top: 100%;
		left: 50%;
		right: auto;
		transform: translateX(-50%);
	}

	.btn-nav-item {
		margin-right: $space-xs;
	}

	.btn-nav-search,
	.btn-language {
		i {
			margin-right: 0;
		}

		.text {
			display: none;
		}
	}

	.btn-language {
		display: none;
		padding-left: $space-sm;
		padding-right: $space-sm;

		&.always-show {
			display: block;

			i {
				margin-right: $space-xs;
			}

			.text {
				display: inline-block;
			}
		}
	}

	.btn-register {
		margin-left: $space-xs;
	}

	.dropdown-search {
		.input-group .btn i {
			top: -1px;
		}
	}

	@include media-breakpoint-up(sm){
		.navbar-nav {
			position: relative;
		}

		.dropdown-menu-rounded {
			transform: none;
		}

		.dropdown-account {
			position: absolute;
			left: auto;
			right: 0;
		}

		.dropdown-search,
		.dropdown-lets-talk,
		.dropdown-site-select {
			left: auto!important;
			right: 0;
		}
	}

	@include media-breakpoint-up(md){
		.navbar-nav {
			flex-direction: row;
		}

		.btn-nav-item {
			margin-right: $space-sm;
		}

		.btn-nav-lets-talk,
		.btn-nav-site-select {
			display: none;
		}

		.btn-nav-search,
		.btn-language {
			margin-right: $space-xs;
		}

		.dropdown-lets-talk,
		.dropdown-site-select {
			display: none;
		}
	}

	@include media-breakpoint-up(lg){
		.btn-nav-search,
		.btn-language {
			i {
				margin-right: $space-xs;
			}
			.text {
				display: inline-block;
			}
		}

		.btn-language {
			display: block;
		}
	}
}

.dropdown-menu-rounded {
	box-shadow: $shadow-dropdown;
	border-color: $border-light;
	border-radius: $border-radius-lg;
	padding: calc($space-md + $space-xs) $space-md $space-md;
	width: calc(100% - $space-md);

	.btn-dropdown-close {
		@include button-variant(
						$grey-ultralight,
						$grey-ultralight,
						$grey-darkest,

						$mulberry-lightest,
						$mulberry-lightest,
						$mulberry-darkest,

						$mulberry-lightest,
						$mulberry-lightest,
						$mulberry-darkest,

						$grey-ultralight,
						$grey-ultralight,
						$grey-medium
		);
		padding: 2px 8px;
		position:absolute;
		top: $space;
		right: $space;

		i {
			font-size: $font-size-md!important;
			position: relative;
			top: 2px;
		}
	}

	.dropdown-header {
		color: $body-color;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		line-height: 1.3;
		padding: 0;

		p {
			margin-bottom: 0;
		}

		.title {
			color: $body-color;
			font-size: $font-size-md;
			font-weight: 600;
		}

		.subtitle {
			color: $grey-darkest;
		}

		.email {
			display:block;
			overflow: hidden;
			white-space:nowrap;
			text-overflow: ellipsis;
			max-width: calc(92vw - 64px - calc($space-md * 2));
			min-width: 220px;

			@include media-breakpoint-up(sm) {
				max-width: 245px;
			}
		}
	}

	.dropdown-content {
		margin-top: $space-sm;
	}

	@include media-breakpoint-up(sm){
		min-width: 360px;
	}
}

.dropdown-account {
	.dropdown-header {
		margin-bottom: $space-md;

		.profile {
			margin-right: $space-sm;
		}
	}

	.dropdown-content {
		position: relative;

		& > div {
			margin-bottom: $space-md;
		}
	}

	.dropdown-group {
		.section-label {
			font-size: $font-size-sm;
			color: $grey-darkest;
			margin-bottom: $space-sm;
		}

		.section-group {
			background: $white;
			box-shadow: $shadow-navbar;
			border-radius: $border-radius-md;
			border: 1px solid $grey-ultralight;
			overflow: hidden;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li {
			border-bottom: 1px solid $grey-ultralight;
			position: relative;

			&:last-child {
				border-bottom: none;
			}
		}

		.dropdown-item {
			color: $primary;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: $space-sm $space;

			&:before {
				content: " ";
				display: block;
				background: transparent;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 0px;
				// height: 0px;
				pointer-events: none;
				transition-duration: 0.2s;
			}

			&.active {
				background-color: $white;
				&:active,
				&:focus {
					color: $mulberry!important
				}
			}

			&:hover,
			&:focus,
			&.active {
				color: $mulberry;

				span { text-decoration: underline; }

				&:before {
					width: 7px;
					// height: 100%;
					background: $gradient-dark-mulberry;
				}
			}

			&:active {
				color: $white!important;
			}
		}

		p.dropdown-item {
			margin-bottom: 0;
		}
	}

	.dropdown-footer {
		text-align: center;
		font-size: $font-size-xxs;

		a {
			display: inline-block;
			color: $grey-dark;

			&:after {
				content: "\2022";
				display: inline-block;
				margin: 0 $space-xs;
			}

			&:last-child {
				&:after {
					content: ""
				}
			}
		}
	}

	.dropdown-group-sign-out {
		// TODO:DAA20240724 - Temporarily commenting out absolute positioning for proper rendering of signout confirm container. Uncomment once all navigation items have been re addded.
		//position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		margin-bottom: 0!important;

		.section-group {
			display: flex;
			flex-direction: column;
			justify-content: center;
			// align-items: center;
			flex-wrap: nowrap;
			height: 100%;
			text-align: center;
			padding: $space-lg $space;
			// TODO:DAA20240724 - Temporarily adding margin-bottom to signout confirm container. remove once all navigation items have been re added and position absolute is restored above.
			margin-bottom: $space;
		}

		i {
			font-size: calc($font-size-xl * 1.2);
			color: $primary;
			margin-bottom: $space-md;
		}

		.description {
			font-size: $font-size-sm;
		}

		.btn {
			display: block;
			width: 100%;
		}

		a:not(.btn){
			display: block;
		}
	}

	@include media-breakpoint-up(lg){
		.dropdown-group.language {
			display: none;
		}
	}
}

.profile {
	$size: 48px;
	width: $size;
	height: $size;
	flex: 0 0 $size;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $grey-ultralight;
	border-radius: $size;
	border: 2px solid transparent;
	color: $grey-darkest;
	font-size: $font-size-lg;
	line-height: $size;

	&.dark {
		$size: 64px;
		width: $size;
		height: $size;
		flex: 0 0 $size;
		color: $white;
		font-size: calc($font-size-xl * 1.2);
		line-height: $size;
		background: $gradient-dark-mulberry;
	}

	&.btn {
		border: inherit;
		&:hover,
		&:focus,
		&.show,
		&.active {
			border-color: transparentize($mulberry-lightest, 0.5);
			background: $mulberry-lightest;
			color: $mulberry-dark;
		}
	}

	@include media-breakpoint-up(md) {
		$size: 56px;
		width: $size;
		height: $size;
		flex: 0 0 $size;

		&.dark {
			$size: 64px;
			width: $size;
			height: $size;
			line-height: $size;
			flex: 0 0 $size;
		}
	}
}

.profile-info {
	flex: 1 1 auto;
}

.lets-talk {
	display: flex;
	align-items: center;
	line-height: 1.1;

	i {
		font-size: $font-size-md;
		color: $primary;
	}

	p {
		margin-left: $space-sm;
		margin-bottom: 0;
	}

	span {
		display: block;
		font-size: $font-size-xs;
	}

	a {
		font-size: $font-size-std;
	}

	&:not(.large){
		display: none;
		margin-right: $space-sm;
	}

	&.large {
		line-height: 1.2;

		i {
			font-size: $font-size-xl;
		}

		p{
			margin-left: $space;
		}

		span {
			font-size: $font-size-std;
		}

		a {
			font-size: $font-size-md;
		}
	}

	@include media-breakpoint-up(md){
		&:not(.large){
			display: flex;
			margin-right: $space-sm;
		}
	}
}

.site-select {
	display: flex;
	align-items: center;
	line-height: 1.1;

	i {
		font-size: $font-size-md;
		color: $primary;
	}

	p {
		margin-left: $space-sm;
		margin-bottom: 0;
	}

	label {
		display: block;
		font-size: $font-size-xs;
		//font-weight: 400;
		margin: 0 0 2px;
		text-align: right;
	}

	&:not(.large){
		display: none;
		margin-right: $space-sm;
	}

	&.large {
		width: 80%;
		line-height: 1.2;

		i {
			font-size: $font-size-xl;
		}

		p{
			width: 100%;
			margin-left: $space;
		}

		label {
			font-size: $font-size-std;
		}
	}

	@include media-breakpoint-up(md){
		&:not(.large){
			display: flex;
			flex-wrap: wrap;
			margin-right: $space-sm;
			max-width: 140px;

			select {
				font-size: $font-size-sm;
			}
		}
	}

	@include media-breakpoint-up(lg){
		&:not(.large){
			max-width: 120px;
		}
	}

	@include media-breakpoint-up(xl){
		&:not(.large){
			flex-wrap: nowrap;
			margin-right: $space-sm;
			max-width: none;

			label {
				margin-right: $space-sm;
				min-width: 85px;
			}
		}
	}
}

.container-report-nav {
	border-bottom: 1px solid $border-light;
	padding: $space-xs 0;
	position: relative;

	.dropdown-menu {
		box-shadow: $shadow-dropdown;
		max-height: 40vh;
		overflow: auto;

		li {
			border-bottom: 1px solid $border-light;
		}
	}

	.dropdown-item {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	@include media-breakpoint-down(lg){
		.dropdown-menu {
			top: 100%!important;
			left: $space-sm!important;
			right: $space-sm!important;
			transform: none!important;
		}
	}

	@include media-breakpoint-up(lg){
		.dropdown-menu {
			max-width: 500px;
		}
	}
}

// Message Toast
.message-toast {

	padding: $space-xs;
	position: absolute;
	top: $space-xs;
	right: $space;

	.inner {
		display: flex;
		align-items: center;
		color: $charcoal;
		text-decoration: none;
	}

	.container-icon {
		position: relative;
		padding-right: $space-xs;
	}

	.container-text {
		text-decoration: underline;
		display: none;
	}

	.icon {
		display: block;
		color: $mulberry-darkest;
		font-size: $font-size-md;
	}

	.dot {
		display: block;
		background: $red;
		color: $white;
		font-size: 11px;
		font-weight: 500;
		position: absolute;
		top: 50%;
		right: -2px;
		transform: translateY(-50%);
		width: 13px;
		height: 13px;
		border-radius: 999px;
		text-align: center;
		line-height: 1.2;
	}

	.ring {
		animation: ring 2s 10 none ease-in-out;
		transform-origin: 50% 0px;
	}

	@media (min-width: 380px) {
		padding: $space-xs $space-sm;

		.icon {
			font-size: $font-size-lg;
		}
	}

	@include media-breakpoint-up (lg) {
		background: $gradient-nav-dropdown;
		border-radius: $border-radius;
		border: 1px solid $border-light;
		box-shadow: $shadow-dropdown;
		padding: $space-sm $space;
		position: absolute;
		top: 111%;
		left: 50%;
		right: auto;
		transform: translateX(-50%);

		&:before {
			content: ' ';
			display: block;
			position: absolute;
			top: -8px;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-left: 11px solid transparent;
			border-right: 11px solid transparent;
			border-bottom: 8px solid $border-light
		}

		&:after {
			content: ' ';
			display: block;
			position: absolute;
			top: -7px;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 8px solid $white;
		}

		.container-text {
			display: block;
			min-width: 140px;
			text-align: center;
		}

		.container-icon {
			padding-right: $space-sm;
		}

		.dot {
			right: 3px;
		}
	}

	@keyframes ring {
		0% {
			transform: rotate(0deg);
		}
		10% {
			transform: rotate(-14deg);
		}
		30% {
			transform: rotate(14deg);
		}
		35% {
			transform: rotate(-4deg);
		}
		45% {
			transform: rotate(4deg);
		}
		50% {
			transform: rotate(0deg);
		}
	}
}

//======================================================================//
//==================== <<<<< Breadcrumbs  >>>>> ========================//
//======================================================================//

.section-breadcrumbs {
	font-size: $font-size-xs;
	color: $grey-dark;

	.container {
		@include media-breakpoint-up(xxl) {
			max-width: $container-header-xxl;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0 $space-xs;
	}

	li {
		display: inline-block;
	}

	a {
		display: inline-block;
		padding: $space-sm 0;
		color: $grey-darkest;
		text-decoration: underline;

		&:hover {
			color: $mulberry-light
		}
	}

	.icon-chevron {
		color: $grey-light;
		font-size: 0.75em;
		margin: 0 1.2em;
		opacity: 0.9;
	}

	.has-icon {
		.title {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		.has-icon {
			.title {
				display: inline;
			}

			.icon {
				// display: none;
				margin-right: $space-sm;
			}
		}

		a {
			padding: calc($space-sm * 1.2) 0;
		}
	}

	.page-dsg-dark & {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1;
		color: $grey-light;

		.icon-chevron {
			opacity: 0.7;
		}

		a {
			color: $yellow-lightest;

			&:hover {
				color: $mulberry-lightest
			}
		}
	}
}

// Header logo preload for print
.page-homeweb-resource,
.page-homeweb-booking-success {
	#header {
		.col-logo {
			.logo {
				&:before {
					content: '';
					opacity: 0;
					visibility: hidden;
					width: 0px;
					height: 0px;
					background-image: url('#{$url-cdn-common}/images/logos/homewood-health-140-logo-no-tag-colour.png');
				}
			}
		}
	}
}

// french language logo update for print
:lang(fr) {
	.page-homeweb-resource,
	.page-homeweb-booking-success {
		#header {
			.col-logo {
				.logo {
					&:before {
						background-image: url('#{$url-cdn-common}/images/logos/homewood-health-140-logo-no-tag-colour-fr.png');
					}
				}
			}
		}
	}
}