//
// (C) Copyright 2024 by Homewood Health Inc.
//
// $File: _print.scss
// $Purpose: Styles relating print media
//
@media print {
	* {
		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
		color-adjust: exact !important;                 /* Firefox 48 – 96 */    
		print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
	}

	// french language logo update for print
	:lang(fr) {
		.page-homeweb-resource,
		.page-homeweb-booking-success {
			#header {
				.col-logo {
					.logo {
						&:before {
							background-image: url('#{$url-cdn-common}/images/logos/homewood-health-140-logo-no-tag-colour-fr.png');

						}
					}
				}
			}
		}
	}

	.page-homeweb-resource,
	.page-homeweb-booking-success {
		$font-size-base: 16px;
		width: 210mm; // A4 Paper width
		height: 100%;

		@page {
			size: A4;
			margin: 0;
		}

		.container,
		.container-fluid {
			width:  210mm !important;
			max-width:unset !important;
			padding-left: 0!important;
			padding-right: 0!important;
		}

		.row {
			margin: 0!important;
			padding: 0!important;
		}

		#section-global-nav,
		#header .col-nav-primary,
		#navigation-secondary .nav-item-search,
		#navigation-secondary .lang-toggle,
		#navigation-secondary .nav-profile,
		.btn-nav-lets-talk,
		.sidebar,
		.resource-footer,
		footer,
		.footer,
		#cxone-guide-container {
			display: none;
		}

		#header {
			box-shadow: unset;

			.col-logo {
				max-width: unset;
				.logo {
					$logo-height: 45px;
					position: relative;
					padding-left: 180px;
					height: $logo-height;

					&:before {
						-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
						color-adjust: exact !important;                 /* Firefox 48 – 96 */
						print-color-adjust: exact !important;
						position: absolute;
						left: 0;
						content: '';
						width: 180px;
						height: $logo-height;
						background-image: url('#{$url-cdn-common}/images/logos/homewood-health-140-logo-no-tag-colour.png');
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						opacity: 1;
						visibility: visible;
					}

					.logo-desktop {
						display: block !important;
						transform: scale(0.70);
					}

					.logo-mobile {
						display: none!important;
					}
				}
			}
		}

		.lets-talk {
			display: block!important;

			i {
				display: none!important;
			}
		}

		.container-page-dynamic {
			.banner {
				&.banner-hero {
					display: block;
					height: unset;
					min-height: unset;

					&.banner-hero-resource {
						h1 {
							font-size:38px!important;
							line-height:1.21!important;
						}
					}

					.banner-background {
						position: relative;
						display: block;
						height: 200px;
						width:  210mm; // A4 Paper width;
						-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
						color-adjust: exact !important;                 /* Firefox 48 – 96 */
						print-color-adjust: exact !important;
					}

					.banner-inner {
						padding-left: 0;

						.container {
							padding: 0;
						}
					}

					.banner-content {
						@include make-col(12);
						background-color: unset;
						padding: $space 0 0;
						color: $body-color;
					}
				}
			}

			.container-article {
				margin-top: 0;
				padding: 0;

				.resource-summary {
					font-size: $font-size-lg;
					margin-bottom: $space;
				}
			}

			.item {
				@include make-col(12);
			}

			article {
				@include make-col(12);
				padding: 0;

				p {
					font-size: $font-size-base!important;
				}

				h1, .h1 {
					font-size:$font-size-base * 2.5!important;
				}
				h2, .h2 {
					font-size:$font-size-base * 2!important;
				}
				h3, .h3 {
					font-size:$font-size-base * 1.75!important;
				}
				h4, .h4 {
					font-size:$font-size-base * 1.5!important;
				}
				h5, .h5 {
					font-size:$font-size-base * 1.25!important;
				}

				p + h1,
				p + h2,
				p + h3,
				p + h4,
				p + h5,
				p + h6,
				ol + h1,
				ol + h2,
				ol + h3,
				ol + h4,
				ol + h5,
				ol + h6,
				ul + h1,
				ul + h2,
				ul + h3,
				ul + h4,
				ul + h5,
				ul + h6{
					&:not(.resource-title) {
						margin-top: $space-lg;
					}
				}

				.container {
					margin: 0;
					padding: 0;
				}
			}
		}
	}

	.page-homeweb-booking-success {
		background-color: $white !important;
		.accordion-collapse {
			display: block!important;
		}

		.dsg-inner {
			max-width: 100%!important;
		}

		.col-lg-10,
		.col-lg-8 {
			@include make-col(12);
		}

		.item-provider-info.card {
			.wrapper {
				box-shadow: unset;
			}
		}

		.item-in-person-address-option.card,
		.item-booking-v2 {
			.item-inner {
				border: 1px solid $border-color;
				box-shadow: unset;
			}
		}

		.item-in-person-address-option {
			.item-media {
				.icon-container {
					box-shadow: unset;
				}
			}
		}

		.header-actions,
		.btn-print {
			display: none;
		}
	}
}

