//
//  (C) Copyright 2023 by Homewood Health Inc.
//
//  $File: _reset.scss
//  $Purpose: Reset typical browser styles
//

html,
body,
ul,
ol {
	margin: 0;
	padding: 0;
}

picture {
	display: block;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

//======================================================================//
//========================= <<<<< VueJS >>>>> ==========================//
//======================================================================//

[v-cloak] {
	display: none!important;
}

.alert-fade-enter-active,
.alert-fade-leave-active {
	transition: all 0.2s ease;
}

.alert-fade-enter-from,
.alert-fade-leave-to {
	opacity: 0;
}

.alert-fade-enter-to,
.alert-fade-leave-from {
	opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
	opacity: 1;
}

//======================================================================//
//========================= <<<<< Icons >>>>> ==========================//
//======================================================================//

i.icon {
	display: inline-block;
	font-style: normal;
}

picture {
	display: block;
	* {
		object-fit: cover;
			width: 100%;
			height: auto;
	}
}