//
//  (C) Copyright 2023 by Homewood Health Inc.
//
//  $File: style.scss
//  $Purpose: Imports all the necessary .scss files for the project
//

@import "reset";
@import "settings";
@import "colours";
@import "colours-dark";

// Third Party
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all";
@import "~@vueform/multiselect/themes/default";
@import "~quill/dist/quill.snow.css";
@import '../editor/snippets/content.css';

// Project Styles
@import "mixins";
@import "typography";
@import "layout";
@import "pages";
@import "alerts";
@import "elements";
@import "forms";
@import "header";
@import "footer";
@import "collections";
@import "managers";
@import "media-library";
@import "messages";

// Publisher Content Styles
@import "published-content";

// Print Specific
@import "print";

// Ring Central Style Overrides
@import "ring-central";

// Themes styles
// TODO
