//
// (C) Copyright 2023 by Homewood Health Inc.
//
// $File: _footer.scss
// $Purpose: Styles relating to footer elements
//


// Change footer nav alignment, spacing depending on the breakpoint
@mixin footer-styles($breakpoint) {
	@include media-breakpoint-up($breakpoint) {
		padding: $space-lg 0;

		nav {
			flex: 0 0 100%;

			ul {
				display: flex;
				justify-content: flex-end;
				flex-wrap: wrap;
				text-align: left;
			}

			li {
				flex: 0 0 auto;
				border-right: 2px solid $grey-darkest;
				padding-right: $space;
				margin-right: $space;

				&:last-child {
					border-right: none;
					padding-right: 0;
					margin-right: 0;
				}
			}
		}
	}
}

footer {
	background: $charcoal;
	color: $white;
	padding: $space-lg 0;
	font-size: $font-size-sm;
	font-weight: 300;

	a {
		color: $white;

		&:hover {
			color: $yellow-light
		}
	}

	.container-copyright {
		border-top: 1px solid $grey-darkest;
		margin-top: $space;
		padding-top: $space;
		font-size: $font-size-sm;
	}

	.col-about {
		text-align: justify;
		p {
			margin-bottom: $space-sm;
			line-height: 1.2;
		}
	}

	nav {
		font-weight: 400;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	&.compact {
		padding: $space 0;
		.container-copyright {
			border-top: 0;
			margin-top: 0;
			padding-top: 0;
		}
	}

	@include media-breakpoint-between(sm, md){
		.container-copyright {
			font-size: 0.92em;
		}
	}

	@include media-breakpoint-down(sm){
		.container-copyright {
			font-size: $font-size-sm;
		}

		&.compact {
			.container-copyright {
				text-align: center;
			}
		}
	}

	@include media-breakpoint-up(lg){
		.col-about {
			padding-right: $space-md;

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.col-nav {
			display: flex;
			justify-content: flex-end;
			flex-wrap: wrap;
		}

		.trademark {
			flex: 0 0 100%;
			text-align: right;
			margin-bottom: 0;
		}

		nav {
			ul {
				text-align: right;
			}
		}
	}

	&.footer-en {
		@include footer-styles(xl);
	}

	&.footer-fr {
		@include footer-styles(xxl);
	}

    @include media-breakpoint-up(xxl){
        nav {
            ul {
              display: flex;
              justify-content: flex-end;
              flex-wrap: nowrap;
            }
        }
    }
}

// Manulife Org Specific Footer
.footer {
	&:has(.row-manulife){
		padding-bottom: 0;
	}
}
.row-manulife {
	margin-top: $space;
	padding-top: $space;
	padding-bottom: $space;
	background-color: #01a758;

	p {
		font-size: $font-size-xs;
		margin-bottom: 0;
		text-align: center;
	}
}

//======================================================================//
//============== <<<<< HOMEWEB RESOURCE DETAIL FOOTER >>>>> ============//
//======================================================================//
.resource-footer {
	background-color: $grey-ultralight;
	margin-top: $space-xxl;
	padding-top: $space-xxxl;
	padding-bottom: calc($space-xxxl + $space-sm);

	.title,
	.sub-title {
		display: block;
		text-align: center;
	}

	.h3 {
		display: block;
		clear: both;
	}

	.h5 {
		font-weight: 300;
	}

	.category-link {
		color: $mulberry-light;

		&:hover {
			color: $link-hover-color;
		}
	}
}